import React from "react"
import { connect } from "react-redux"
import ZoomSelector from "./ZoomSelector"
import { constrain, showContextMenu, actionSetHeading } from "../globalState"
import store from "../../store"
import * as mapsdk from "../../common/mapsdk"
import Icon from "../common/icon/Icon"
import { submitPositionTappedEvent } from "../../extModules/events"

import "./zoom-selector.pcss"
import { getMobileOperatingSystem, isSafari } from "../../common/utilities"
import { stopFollowing, startFollowing } from "../../common/bluedot"

function zoomChange(zoomAmount)
{
	mapsdk.displayZoom(constrain(mapsdk.getZoom() + zoomAmount, 0, 100))
    if(getMobileOperatingSystem() === "iOS" || store.getState().bluedotSim.isBlueDotSimActive)
       stopFollowing()
}

function zoomNow(zoom)
{
	mapsdk.displayZoom(zoom, 0)
    if(getMobileOperatingSystem() === "iOS" || store.getState().bluedotSim.isBlueDotSimActive)
		stopFollowing()
}

function resetHeading()
{
	// mapsdk.displayHeading(0)
	store.dispatch(actionSetHeading(0))
}

const setFollow = follow => () => {
	submitPositionTappedEvent()
	if(follow)
		startFollowing()
	else
		stopFollowing()
}

const renderFollowIconButton = (isFollowing, navigationMode) => (
    <Icon name="reposition" className={`${(isFollowing ? "bg-active" : "bg-secondary") + (navigationMode ? "move-icon-up" : "")}`} onClick={ setFollow(!isFollowing) }/>
)

const renderCompass = (heading, resetHeadingFn, mobileFlag) => (
    <Icon name={"control-icon-compass" + (mobileFlag ? "Mobile" : "") } onClick={ resetHeadingFn } style={{ transform: "rotate(" + (0 - heading) + "deg)" }} />
)

let ZoomSelectorContainer = ({ dispatch, zoom, heading, isMobileWidth, isFollowing, bluedotType, navigationMode }) => {
    if(isMobileWidth) {
		// On mobile we don't actually have a zoomSelectorContainer - (bad design here) - we just
		// have a couple icons that are associated with the zoom container on desktop, so we render them
		// here.
        return (
            <div className="zoom-related-mobile-icons">
                { bluedotType
						? renderFollowIconButton(isFollowing, navigationMode)
						: null }
                { renderCompass(heading, resetHeading, true) }
            </div>
        )
    }
    else
    {
        return (
            <div className="zoom-selectorContainer bg-primary-container">
				<Icon name="control-icon-submenu" className="text-primary-container" onClick={ () => dispatch(showContextMenu("desktopContextMenu", "-zoombarTrigger")) }/>
                <ZoomSelector
                    value={ zoom }
                    zoomInputChanged={ newValue => zoomNow(newValue) }
                    zoomIn={ () => zoomChange(10) }
                    zoomOut={ () => zoomChange(-10) }
                />
                { renderCompass(heading, resetHeading) }
                { bluedotType
						? renderFollowIconButton(isFollowing, navigationMode)
						: null }

            </div>
        )
    }
}

const mapStateToProps = state => {

	if(!state.bluedot)
		console.log("state.bluedot is undefined!!")

    return {
        zoom: mapsdk.getZoom(),
        heading: state.global.heading,
        isMobileWidth: state.global.isMobileWidth,
        isFollowing: state.bluedot.isFollowing,
        bluedotType: state.bluedot.bluedotType,
		navigationMode: typeof state.search.isNavigationOpen === "undefined" ? false : state.search.isNavigationOpen
    }
}

ZoomSelectorContainer = connect(mapStateToProps)(ZoomSelectorContainer)

export default ZoomSelectorContainer
