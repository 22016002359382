import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { log as glog} from "../components/globalState"
import intervalPlural from "i18next-intervalplural-postprocessor"
import { $$ } from "./domTools"

const log = glog.sublog("i18n")

export const getCurrentLanguage = () => i18n.language

// A postprocessor plugin for i18next that replaces all words with dashes - this helps identify untranslated strings
const dashEmAll = {
	name: "dashEmAll",
	type: "postProcessor",
	process: function(value, key, options, translator) {
		return value.replace(/\w/g, "-")
	}
}

export function init(translations, langOverride, debugMode)
{
	log.info("i18n.init: ", translations, langOverride)
	i18n
		.use(intervalPlural)
		.use(LanguageDetector)
		.use(dashEmAll)
		.init({
				resources: translations,
				fallbackLng: "en-US",
				// have a common namespace used around the full app
				ns: ["common", "banner", "common", "directions", "failure", "flights", "legal", "navigation", "poi", "search", "security", "time", "tooltip"], // Note: setting app-specific values like this shoud disqualify this module from being in extModules - we should inject this information in
				defaultNS: "common",
				lng: langOverride,
				interpolation: {
						escapeValue: true
				}/*,
				postProcess: "dashEmAll"*/
			})

	if(debugMode)
	{
		i18n.on("missingKey", function(lngs, namespace, key, res) {
			if(i18n.language !== "en-US")
				console.error("i18nNext: key not found. Language: " + i18n.language + ", Key: " + key + ", namespace: " + namespace + ", lngs: ", lngs)
			})

		window.i18n = i18n
	}

	window.i18n = i18n
	return i18n
}

const getAttrTrans = (node, lang) => node.getAttribute("data-i18n-" + lang)

export function attrBasedI18n(node)
{
	$$(".i18na")
		.forEach(i18nNode => {
			const newText = getAttrTrans(i18nNode, getCurrentLanguage().toLowerCase())
			if(newText !== null)
				i18nNode.innerText = newText
		})
}

export const setLanguage = (lng, callback) => i18n.changeLanguage(lng, callback)
export const t = (key, interpolation) => i18n.t(key, interpolation)