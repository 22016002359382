import React from "react"
import Icon from "../../../common/icon/Icon"
import { cond } from "../../../../common/utilities"
import Input from "../../../common/input/Input"

import "./poi-input.pcss"

/*
	Properties:

		text: The current value of the input field - this initializes the field and should update with each keystroke
		label: The label text indicating the field's purposed
		isConfirmed: Indicates the field has been confirmed - i.e. the user has chosen a POI. This effects the visual
				display of this field (sets some variant classes)
		onChange: event handler for changes - should set text property to value
		placeholder: placeholder for the input field
		isActive: indicates the field is the "currently active" field - meaning clicks on the map or suggestion list
				will target this field with the result (true if isFocus and no POI assigned here yet)
		reset: Handler for the reset button (x to the right of the input field)
		onFocus: Handler for focus events on the input field
		id: sets the ID attribute for the corresponding input element
		isMobileWidth: set to true to activate narrow-layyout hooks

*/

class PoiInput extends React.Component {

	render() {

			const {text, label, isConfirmed, onChange, placeholder, isActive, reset, onFocus, onClick, id, isMobileWidth, iconName} = this.props

			return (
				<div className={"poi-input" + (isConfirmed ? " -confirmed text-accent2" : (isActive ? " -active text-accent2" : "")) }>
					<Icon name={ iconName} />
					<label>
						{ !isMobileWidth ? <div className="label">{label}</div> : null }
						<Input
							inputClassName={ `placeholderCurrentColor ${(isConfirmed || isActive ? "text-alt border-accent2" : "text-muted border-muted")}` }
							placeholder={ placeholder }
							onChange={ onChange }
							type="text"
							ref={ node => { if(isActive && node && node.focus) node.focus() } }
							value={ text ? text : "" } /* init to space to ensure "controlled" input */
							onFocus={ onFocus }
							onClick={ onClick }
							autoFocus={ isActive }
							id={ id }
							showSubmit={ false }
						/>
					</label>
					{
						cond(isConfirmed,
							<Icon className="reset text-accent2" name="global-icon-close" onClick={ reset }/>,
							cond(isActive,
								cond(!text || text.length === 0,
									<Icon
											className={
													cond(isConfirmed,
														"border-muted ",
														cond(isActive, "border-accent2", "border-muted")
													)
												}
											name="dir-icon-search"
										/>,
									<Icon
											className={
												cond(isActive, "border-accent2", "border-muted") + cond(!isMobileWidth, " extraTopPadding", "")
													}
											name="global-icon-close"
											onClick={ reset }
										/>
									)
								)
						)
					}
				</div>
			)
	}
}

export default PoiInput
