import { combineReducers } from "redux"
import { routerReducer as routing} from 'react-router-redux'

import {reducer as bluedotSim} from "./bluedotSim/BlueDotSimulator"
import {reducer as flightStatus} from "./flightStatus/FlightStatusContainer"
import {reducer as bluedot} from "../common/bluedot"
import {reducer as global} from "./globalState"
import {reducer as levelSelector} from "./levelSelector/LevelSelectorContainer"
import {reducer as navigation} from "./search/navigation/NavigationDialog"
import {reducer as onsite} from "./onsiteAdmin/OnsiteAdmin"
import {reducer as poi} from "./search/poi/poiState"
import {reducer as search} from "./search/SearchContainer"

const ACTION_RESET = "ACTION_RESET"

const appReducer = combineReducers({
    bluedotSim,
	flightStatus,
	bluedot,
	global,
	levelSelector,
	navigation,
	onsite,
	poi,
	routing,
	search
})

export const rootReducer = (state, action) => {

	if(action.type === ACTION_RESET)
		state = undefined

	return appReducer(state, action)
}

export const resetApplication = () => {
	return {type: ACTION_RESET}
}

export default rootReducer