import React from "react"
import { connect } from "react-redux"

import store from "../../store"
import { renderInfoDialog } from "../../extModules/domTools"
import { submitSearchIconTappedEvent, submitSearchCancelTappedEvent, submitSearchPOIOtherSelectedEvent, submitSearchPOINearbySelectedEvent, submitSearchKeywordSelectedEvent, submitSearchCategorySelectedEvent } from "../../extModules/events"
import { getConfig } from "../../index"

import aboutJSW from "../../aboutJSW"

import {
    dStateSearchCurrentBuildingOrAll,
    dStateSuggestedSearches,
    setStateFromPOI,
    dStateOtherBuildingsLocations,
    dStateArea,
    showContextMenu,
    getCurrentPhysicalLocation,
    log as glog,
    actionSetContactSheet
} from "../globalState"
import * as mapsdk from "../../common/mapsdk"

import { finishSimulation } from "../../common/bluedot"
import { cond, getMobileOperatingSystem, isSafari, each } from "../../common/utilities"
import { startFlightStatus, setFlightSearchTerm, setFlightStatus } from "../flightStatus/FlightStatusContainer"

/* Subcomponents */
import SearchEntry from "./searchEntry/SearchEntry"
import LandingList from "./landingList/LandingList"
import SuggestedSearches from "./suggestedSearches/SuggestedSearches"
import SuggestedLocations from "./suggestedLocations/SuggestedLocations"
import SearchCategories from "./searchCategories/SearchCategories"
import Message from "./message/Message"
import { setPoi1Focus } from "./navigation/NavigationDialog"
import { actionSetIsOpenBySearch } from "../levelSelector/LevelSelectorContainer"
import MinimizeButton from "../common/minimizeButton/MinimizeButton"

import "./search-container.pcss"

import MobileSearchHeader from "./MobileSearchHeader"

import { actionSetBlueDotSimActive } from "../bluedotSim/BlueDotSimulator"

import {t} from "../../extModules/i18n"
import { open as onsiteAdminOpen } from "../onsiteAdmin/OnsiteAdmin"
import { infoProviders } from "../../common/infoProviders"
import { getLangSpecificConfigParm } from "../.."

const log = glog.sublog("SearchContainer")

const ACTION_SET_SEARCH_TERM = "search/setSearchTerm"
const ACTION_WAKE_UP = "search/wakeUp"
const ACTION_SHOW_NAVIGATION_DIALOG = "search/showNavigationDialog"
const ACTION_SET_SEARCH_TERM_CONFIRMED = "search/setSearchTermConfirmed"
const ACTION_SET_SEARCH_CONTAINER_IS_OPEN = "search/setSearchContainerIsOpen"
const ACTION_SET_POI_ID_LIST = "search/setPoiIdList"
const ACTION_SET_SHOULD_SHOW_MOBILE_HEADER = "searchEntry/setShouldShowMobileHeader"

export function reducer(state = {}, action)
{
	if(action.type === ACTION_SET_SEARCH_TERM)
		return Object.assign({}, state, { term: action.term, termConfirmed: action.isConfirmed, poiIdList: null })

	if(action.type === ACTION_SET_POI_ID_LIST)
		return Object.assign({}, state, { poiIdList: action.poiIdList, term: "" })

	if(action.type === ACTION_WAKE_UP)
		return Object.assign({}, state)

	if(action.type === ACTION_SHOW_NAVIGATION_DIALOG)
		return Object.assign({}, state, { isNavigationOpen: action.isOpen })

	if(action.type === ACTION_SET_SEARCH_TERM_CONFIRMED)
	return Object.assign({}, state, { termConfirmed: action.termConfirmed })

	if(action.type === ACTION_SET_SEARCH_CONTAINER_IS_OPEN)
		return Object.assign({}, state, { containerIsOpen: action.isOpen })

	if(action.type === ACTION_SET_SHOULD_SHOW_MOBILE_HEADER)
		return Object.assign({}, state, { shouldShowMobileHeader: action.shouldShowMobileHeader })

	return state
}

// Define our actions
export const actionSetSearchTerm = (term, isConfirmed) => { return {
		type: ACTION_SET_SEARCH_TERM,
		term: term,
		isConfirmed: isConfirmed
	}}

const actionSetSearchContainerIsOpen = isOpen => { return {
	type: ACTION_SET_SEARCH_CONTAINER_IS_OPEN,
	isOpen: isOpen
}}

/*const setSearchTermConfirmed = (termConfirmed) => {
	return {
		type: ACTION_SET_SEARCH_TERM_CONFIRMED,
		termConfirmed: termConfirmed
	}
}*/

export const showNavigationDialogAC = (isOpen) => ({
		type: ACTION_SHOW_NAVIGATION_DIALOG,
		isOpen: isOpen
	})

const actionSetshouldShowMobileHeader = shouldShowMobileHeader => { return {
		type: ACTION_SET_SHOULD_SHOW_MOBILE_HEADER,
		shouldShowMobileHeader: shouldShowMobileHeader
	}}

// This self-dispatches!
export const wakeUp = (delay = 0) => {
		setTimeout(() => store.dispatch({
		type: ACTION_WAKE_UP
	}), delay)}

const shouldShowSearchCategories = (hasFocus, term) => hasFocus && !term
const hasSearchSuggestions = searches => searches && searches !== "loading" && searches.length > 0
const searchTermNotFound = (term, searchSuggestions, searchResults) => term && (!searchSuggestions || searchSuggestions.length === 0) && !(searchResults && searchResults.length)

const searchTermMatched = (term, searches) => searches && term && searches.indexOf(term) >= 0
const locationsNotFound = (term, searches, locations) => searchTermMatched(term, searches) && !locations.length
const setSearchContainerIsOpen = containerIsOpen => store.dispatch(actionSetSearchContainerIsOpen(containerIsOpen))

// Call this to replace the search term with a new value and
// act as though the user has typed the term and  clicked the
// suggestion.
// Note: typing and hitting return ALSO confirms a term - but does not go through this function
export function setNewConfirmedSearchTerm(term, currentBuildingOnly)
{
	log.info("SearchContainer.setNewConfirmedSearch", term)
	if(term === "flight-status")
    {
		// hijack the search and instead show flight-status
        store.dispatch(setFlightSearchTerm(""))
        store.dispatch(setFlightStatus(true))
		startFlightStatus()
		return
    }

	store.dispatch(actionSetSearchTerm(term, true))
	setSearchContainerIsOpen(true)
	repositionMapToSearchResults()
}

function clickLandingListItem(e)
{
	let category = e.currentTarget.dataset.search
	submitSearchIconTappedEvent(category)
	setNewConfirmedSearchTerm(category)
}

function nearbyPOIClicked(e)
{
	let id = e.currentTarget.dataset.id
	submitSearchPOINearbySelectedEvent(id)
	setStateFromPOI(id)
}

function otherPOIClicked(e)
{
	let id = e.currentTarget.dataset.id
	submitSearchPOIOtherSelectedEvent(id)
	setStateFromPOI(id)
}

export function setShowDirections(isOpen)
{
	store.dispatch(showNavigationDialogAC(isOpen))
	setPoi1Focus(false)
}

export const getActionSetPoiIdList = (poiIdList) => ({ type: ACTION_SET_POI_ID_LIST, poiIdList })

// Displays the search entry field by hiding the mobile header and setting the search container to "open"
function setShowSearchEntry()
{
	store.dispatch(actionSetshouldShowMobileHeader(false))
	store.dispatch(setSearchContainerIsOpen(true))
}

function closeSearch()
{
    finishSimulation()
	submitSearchCancelTappedEvent()
	store.dispatch(actionSetshouldShowMobileHeader(true))
	store.dispatch(actionSetSearchTerm())
	store.dispatch(setSearchContainerIsOpen(false))
}

function setShowSubmenu()
{
	store.dispatch(showContextMenu("mobileMenuBarContextMenu", "-mobileSubmenu"))
}

function suggestedKeywordClicked(e)
{
	log.info("SearchContainer.suggestedKeywordClicked", e.currentTarget.dataset.suggestion)
	const term = e.currentTarget.dataset.suggestion
	if(term) {
		submitSearchKeywordSelectedEvent(term)
		setNewConfirmedSearchTerm(term)
	}
	log.info("SearchContainer.suggestedKeywordClicked Done")
}

function suggestedCategoryClicked(e)
{
	const term = e.currentTarget.dataset.suggestion
	if(term) {
		submitSearchCategorySelectedEvent(term)
		setNewConfirmedSearchTerm(term)
	}
}

export function resetSearch()
{
    finishSimulation()
	submitSearchCancelTappedEvent()

	store.dispatch(actionSetIsOpenBySearch(false))
	store.dispatch(actionSetSearchTerm(""))

    if(store.getState().bluedotSim.isBlueDotSimActive === true)
        store.dispatch(actionSetBlueDotSimActive(false))
}

// This is for checking for any "special" searches, such as "about:"
function checkSpecialHooks(term)
{
    finishSimulation()
    if(store.getState().bluedotSim.isBlueDotSimActive === true)
		store.dispatch(actionSetBlueDotSimActive(false))

	if(term === "kiosk:" && getConfig().kioskMode)
	{
		onsiteAdminOpen()
		store.dispatch(actionSetSearchTerm(""))
		return
	}

	if(term === "cs:")
	{
		store.dispatch(actionSetContactSheet(true))
		store.dispatch(actionSetSearchTerm(""))
		return
	}

	if(term === "about:")
	{
		store.dispatch(actionSetSearchTerm(""))
		document.activeElement.blur()
		renderInfoDialog("about-dialog -wide", "About", "Version " + aboutJSW.version, infoProviders)
	}
    else if(term.toLowerCase() === "bluedotsim:")
    {
        store.dispatch(actionSetBlueDotSimActive(true))
        if(store.getState().global.isMobileWidth)
            closeSearch()
	}
	else if(term.toLowerCase() === "tracking:")
	{
		alert("Launching Email Client") // eslint-disable-line
		const url = `mailto:glenn@bluejava.com?subject=${ encodeURIComponent("bluedot tracking") }&body=${ encodeURIComponent(JSON.stringify(window.trackingReadingsStack)) }`
		document.location.href = url
	}
}

function repositionMapToSearchResults()
{
	return dStateSearchCurrentBuildingOrAll().promise
		.then(poiIdList => {
				log.debug("SearchContainer: dStateSearchCurrentBuildingOrAll returned", poiIdList)
				if(poiIdList && poiIdList.length)
				{
					store.dispatch(actionSetIsOpenBySearch(true))
					const location = getCurrentPhysicalLocation(),
						point = location && location.floorId === mapsdk.getCurrentFloorId() ? location.latLng : undefined

					mapsdk.panZoomMapToIncludePois(poiIdList, point)
				}
			})
}

// This function is used to update the search term (generally via a user typing on the search entry field)
// but it is not "confirmed" - i.e. we still show suggestions and do not display markers, etc.
function updateSearchTerm(searchTerm, confirmedFlag)
{
	store.dispatch(actionSetSearchTerm(searchTerm, confirmedFlag))
    setSearchContainerIsOpen(true)
	checkSpecialHooks(searchTerm)
}

function getAreaDescriptor()
{
		const areaDState = dStateArea().onDerived(wakeUp)
		if(!areaDState.isCurrent)
			return null

		if(!areaDState.value) // null value indicates we are not contained within any area
			return null

		const [ areaGroup, area ] = areaDState.value

		return { groupName: areaGroup.name, areaName: area.name, areaTitle: area.title }
}

function showResultsMapMobile()
{
	repositionMapToSearchResults()
	setSearchContainerIsOpen(false)
}

const logoClicked = () => {
	if(getConfig().logoURL)
		window.location.href = getConfig().logoURL
}

export function getAreaDescription()
{
	const areaDescriptor = getAreaDescriptor()
	return areaDescriptor ?
			`${areaDescriptor.groupName} - ${areaDescriptor.areaName} - ${areaDescriptor.areaTitle}` :
			null
}

function showPoiIdListResults(poiIdList, suggestedLocations, otherBuildingLocations, venueId)
{
	return [
			// Conditionally show SuggestedLocations for Nearby
				<SuggestedLocations
					suggestedLocations={ suggestedLocations }
					onClick={ nearbyPOIClicked }
					key="nearby"
					title={t("search:SUGGESTED LOCATIONS")}/>,
				<SuggestedLocations
					suggestedLocations={ otherBuildingLocations }
					onClick={ otherPOIClicked }
					key="other"
					title={t("search:OTHER LOCATIONS AT VENUE", {venueId})} />
	]
}

const showMobileSearchHeader = () => {

		return (
				<MobileSearchHeader
					setShowSubmenu={ setShowSubmenu }
					setShowDirections={ setShowDirections }
					logoClicked={ logoClicked }
					setShowSearch={ setShowSearchEntry }
					areaDescription={ getAreaDescription() }
					suppressLogoFlag={ getConfig().suppressMobileLogo }
				/>
			)
	}

class SearchContainer extends React.Component {

	render() {

			let {hasFocus, term, suggestedSearches, suggestedLocations, otherBuildingLocations, venueId, searchEntryPlaceHolder, containerIsOpen, isMobileWidth, shouldShowMobileHeader, poiIdList} = this.props

			if(isMobileWidth && !containerIsOpen)
				return showMobileSearchHeader()

				return (
					<div className={ "search-container bg-primary" + (isMobileWidth ? " -mobileFull" : " -floatingContainer -mainDialog") }>
							<SearchEntry
								hasFocus={ hasFocus }
								term={ term }
								wakeUp={ wakeUp }
								resetSearch={ resetSearch }
								setSearchTerm={ updateSearchTerm }
								setShowSearch={ setShowSearchEntry }
								setShowSubmenu={ setShowSubmenu }
								setShowDirections={ setShowDirections }
								searchEntryPlaceHolder={searchEntryPlaceHolder}
								isMobileWidth={ isMobileWidth }
								closeSearch={closeSearch}
								backFn={ () => {

										if(isMobileWidth)
										{
											if(!containerIsOpen)
												setShowSearchEntry()
											else
												closeSearch()
										}
										else
										{
                                            finishSimulation()
											setSearchContainerIsOpen(true) // un-minimize
											resetSearch()
										}

									} }
							/>
							{ containerIsOpen ?
								poiIdList
									? showPoiIdListResults(poiIdList, suggestedLocations, otherBuildingLocations, venueId)
									: (
										<div
												className={
														"scrolling-container" +
														(isMobileWidth ? " -mobile" : "") +
														(isMobileWidth && !shouldShowMobileHeader ? " bg-primary scroll-hidden" : "") +
														(!isMobileWidth ? " bg-primary" : "") +
														(term ? " -hasTerm" : "")
													}
												style={ isMobileWidth && (term || hasFocus) ? {height: "calc(100vh - 50px - 42px)"} : null }>
											{
												// Conditionally show the Landing List (now defined in config.js)
												cond(
														(isMobileWidth && !shouldShowMobileHeader && !term) || // mobile case
														(!isMobileWidth && !term && !hasFocus), // non mobile case
														<LandingList onClick={ clickLandingListItem } isMobileWidth={isMobileWidth} />)
											}
											{
												// Conditionally show Search Categories (defined in config.js)
												cond(
														shouldShowSearchCategories(hasFocus, term),
														<SearchCategories
															onClick={ suggestedCategoryClicked }/>)
											}
											{
												// Conditionally show Suggested Searches
												cond(
														hasSearchSuggestions(suggestedSearches) &&
															!store.getState().search.termConfirmed,
														<SuggestedSearches
																onClick={ suggestedKeywordClicked }
																suggestedSearches={ suggestedSearches } />
														)
											}
											{
												// Conditionally show SuggestedLocations for Nearby
												term && suggestedLocations.length /*&& !searchTermNotFound(term, suggestedSearches)*/
													? <SuggestedLocations
																suggestedLocations={ suggestedLocations }
																onClick={ nearbyPOIClicked }
																title={t("search:SUGGESTED LOCATIONS")}
														/>
													: null
											}
											{
												// Conditionally show SuggestedLocations for "Other Places"
												cond(
													term &&
														!searchTermNotFound(term, suggestedSearches),
													<SuggestedLocations
															suggestedLocations={ otherBuildingLocations }
															onClick={ otherPOIClicked }
															title={t("search:OTHER LOCATIONS AT VENUE", {venueId})} />
												)
											}
											{
												// If no search results found (and there is a term), display the not found message
												cond(
													searchTermNotFound(term, suggestedSearches, suggestedLocations),
													<Message
															icon="search-icon-nomatches"
															title={t("search:Sorry, no matches found")}
															advice={t("search:Try searching a different phrase")}
														/>
													)
											}
											{
												cond(
													locationsNotFound(term, suggestedSearches, suggestedLocations) &&
														locationsNotFound(term, suggestedSearches, otherBuildingLocations),
													<Message
															icon="search-icon-nomatches"
															title={t("search:Sorry, no locations here")}
															advice={t("search:Try searching a different building or floor")}
														/>
													)
											}
									</div>) : // End of scrolling-container
								<div/> // if container is not open, we show a div anyway..(do we need this?)
							}
							{
								// conditionally show the "Show Results on Map" message
								cond(
										term &&
											!searchTermNotFound(term, suggestedSearches) &&
											isMobileWidth,
										<div
												className=" show-results-map-button button bg-accent  text-primary-button "
												onClick={ showResultsMapMobile }
											>{t("search:Show Results on Map")}</div>
								)
							}
						<MinimizeButton isOpen={containerIsOpen} onOpen={setSearchContainerIsOpen}/>
					</div>
				)
			} // end of render()

		shouldComponentUpdate(nextProps, nextState)
		{
			// await our asyncronous devaluations before bothering to update
			return dStateArea().isCurrent
		}
	}

function defineLocations(locations)
{
	if(locations.isCurrent) {
		locations = locations.value
		if(locations && locations.length && locations.length === 0)
			locations = "empty"
	}
	else
	{
		if(locations.lastValue && locations.lastValue.length > 0)
		{
			locations = locations.lastValue
			if(locations && locations !== null && locations.length && locations.length === 0)
				locations = "empty"
		}
		else
			locations = "loading"
	}
	return locations
}

// Returns the first securityCheckpoint found (or null if none found)
function getFirstSecurityLocation(locations)
{
	return each(locations, function(loc) {
			if(loc.details && loc.details.additionalAttributes && loc.details.additionalAttributes.isSecurityCheckpoint)
				return loc
			else
				return undefined
		})
}

window.dStateSearchCurrentBuildingOrAll = dStateSearchCurrentBuildingOrAll
window.dStateOtherBuildingsLocations = dStateOtherBuildingsLocations

const mapStateToProps = state => {

	let suggestedLocations = dStateSearchCurrentBuildingOrAll().onDerived(wakeUp)
	let otherBuildingLocations = dStateOtherBuildingsLocations().onDerived(wakeUp)
	let venueId = mapsdk.getVenueId()
	suggestedLocations = defineLocations(suggestedLocations)
	otherBuildingLocations = defineLocations(otherBuildingLocations)

	const secPoi = getFirstSecurityLocation(suggestedLocations) || getFirstSecurityLocation(otherBuildingLocations)
	if(secPoi && secPoi.details && secPoi.details.time === undefined)
		setTimeout(wakeUp, 1000)
	else
		if(Array.isArray(suggestedLocations))
			setTimeout(wakeUp, 1000 * 30)
	const secTimes = Array.isArray(suggestedLocations)
		? suggestedLocations
			.filter(poi => poi.details && poi.details.additionalAttributes && poi.details.additionalAttributes.isSecurityCheckpoint)
			.map(poi => poi.details.time)
		: null

	return {
		hasFocus: document.activeElement && document.activeElement === document.querySelector(".search-entry input"),
		term: state.search.term,
		poiIdList: state.search.poiIdList,
		suggestedLocations,
		otherBuildingLocations,
		venueId,
		secTimes: secTimes,
		suggestedSearches: dStateSuggestedSearches().onDerived(wakeUp).value || "loading",
		searchEntryPlaceHolder: getLangSpecificConfigParm("searchEntryPlaceHolder", t("search:Search…")),
		containerIsOpen: typeof state.search.containerIsOpen === "undefined" ? !state.global.isMobileWidth : state.search.containerIsOpen,
		isMobileWidth: state.global.isMobileWidth,
		shouldShowMobileHeader: state.search.shouldShowMobileHeader
	}}

SearchContainer = connect(mapStateToProps)(SearchContainer)

export default SearchContainer
