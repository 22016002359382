import React from "react"
import propTypes from "prop-types"

import "./loadIndicator.pcss"

export default class LoadIndicator extends React.Component {

	render() {
		return (
			<div className={`loadingIndicatorWrapper bg-faded text-accent ${this.props.className ? this.props.className : null}`}>
				<div className="spinner border-accent"/>
				{ this.props.message
					? <div className="textMessage bg-faded">{ this.props.message }</div>
					: null }
			</div>
		)
	}

}

LoadIndicator.propTypes = {
	message: propTypes.string
}