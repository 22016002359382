import React from "react"
import { log as glog} from "../globalState"
import { postData } from "../../extModules/domTools"

const log = glog.sublog("ErrorBoundary")

// const ERROR_REPORTING_SERVICE_URL = "http://httpbin.org/post"
const ERROR_REPORTING_SERVICE_URL = ""

class ErrorBoundary extends React.Component
{
	constructor(props)
	{
		super(props)
		this.state = { hasError: false }
	}

	componentDidCatch(error, info)
	{
		this.setState({ hasError: true })
		log.error(error, info)
		error = error || {} // not sure if this is necessary.. want to be as defensive as possible here since errors here may get lost
		if(ERROR_REPORTING_SERVICE_URL)
			postData(ERROR_REPORTING_SERVICE_URL, {
				error: error.message ? error.message.toString() : null,
				stack: error.stack ? error.stack.toString() : null,
				info
			})
	}

	render()
	{
		if(this.state.hasError && this.props.displayMessage)
			return (
				<div style={ { padding: "2em" } }>
					<h1>Something went wrong.</h1>
					<p>
						This error has been logged and a message has been transmitted to IT management. We will make our best
						effort to remedy this as soon as possible.
					</p>
					<p>
						We apologize for the inconvenience.
					</p>
				</div>
			)

		if(this.state.hasError)
			return null // no displayMessage requested

		return this.props.children
	}
}

export default ErrorBoundary