import React from "react"
import { connect } from "react-redux"

import PropTypes from "prop-types"
import * as mapsdk from "../../common/mapsdk"
import { replace } from "react-router-redux"
import { ad, dynaload } from "../../extModules/domTools"
import { t, getCurrentLanguage } from "../../extModules/i18n"
import Zousan from "../../extModules/zousan-plus"
import { debounce, delay, whenTrue } from "../../common/utilities"
import LoadIndicator from "../common/loadIndicator/LoadIndicator"
import { contextMenuRepo } from "../contextMenu/ContextMenu"
import { resetLocationProvider, setLocationProvider, makeTestScriptLocationProvider, stopFollowing } from "../../common/bluedot"

import {headingChange, actionSetHeading, setLevel, setZoom, setPosition, setStateFromPOI, dStateSearch, constrain, events, setOverlay, log as glog } from "../globalState"

import { dStateSuggestedLocations as dStateNavigationSearchLocations, dStateNavData, setPoi2, setPoi1 } from "../search/navigation/NavigationDialog"

import { showNavigationDialogAC } from "../search/SearchContainer"

import { setFlightStatus, setFlightDetailStatus } from "../flightStatus/FlightStatusContainer"
import { clearMarker } from "../flightStatus/FlightDetailView"
import { cleanPOIMarker } from "../search/poi/poiDetails/PoiDetails"

import store from "../../store"
import devaluate from "../../extModules/devaluate"

import keyTracker from "../../extModules/keyTracker"

import { getConfig, getVSConfigParm } from "../../index"

import "./mapContainer.pcss"
import { setNaviPoiId } from "../search/navigation/navigationEntry/NavigationEntry"
import LanguageSelector, { whenDone } from "../overlay/LanguageSelector"
import { registerOverlay } from "../overlay/OverlayMgr"
import { resetApplication } from "../reducers"
import Icon, { getSVGForName, svgToDataURI } from "../common/icon/Icon"
import { drawSuperMarkerDeclaritive, hideMarkerDeclaritive } from "../../common/mapUtilities"
// import { addMessageListener } from "../../common/apiServer"

const log = glog.sublog("MapContainer")
const isShiftHeld = keyTracker(16),
	currentYear = new Date().getFullYear()

const firstPositionBroadcast = new Zousan()

function doWheel(dispatch, amount, x, y)
{
	if(isShiftHeld())
		dispatch(headingChange(amount / 10))
	else
		mapsdk.displayZoom(constrain(mapsdk.getZoom() + (amount / -10), 0, 100), 0, x, y)
}

function initMapSDK(props)
{
	let venueId = props.vid || props.venueId
	let accountId = props.accountId
	let assetsBase = props.assetsBase
	let assetsFormatVersion = props.assetsFormatVersion

	// allow venueID override by simply specifying it in url - e.g. for LAX:  <URL>?LAX
	const s = window.location.search
	if(s.length > 1 && s.length < 20 && !s.includes("=")) // if thre is no assignment and the length is approp. consider it a venue
		venueId = s.substring(1)

	window.mapsdk = mapsdk
	const config = getConfig()

	if(config.poiId)
		props.options.poiId = config.poiId

	return mapsdk.initMapSDK(venueId, {accountId, assetsBase, assetsFormatVersion}, props.options)
		.then(() => {
				if(config.navFrom)
				{
					store.dispatch(showNavigationDialogAC(true))
				}
				if(config.poiId)
				{
					const initPoiId = parseInt(config.poiId, 10)
					const kioskHeading = props.heading
					if(config.kioskMode && kioskHeading) // if we are in kiosk mode and the kiosk has a rotation set - rotate the map to it as well
						mapsdk.displayHeading(kioskHeading, 0)
					setStateFromPOI(initPoiId)
					// store.dispatch(setPosition(position))
					if(config.showNav)
					{
						store.dispatch(setPoi2(initPoiId))
						const poiId1 = parseInt(config.showNav, 10)
						if(poiId1 > 0)
							store.dispatch(setPoi1(poiId1))
						store.dispatch(showNavigationDialogAC(true))
					}
					mapsdk.getCurrentPosition().then(pos => store.dispatch(setPosition(pos)))
					setZoom(mapsdk.getZoom())
				}
				else
					mapsdk.getCurrentPosition()
						.then(position => store.dispatch(setPosition(position)))

			const vid = mapsdk.getVenueId()

			const initialZoom = getVSConfigParm("initialZoom", vid)
			if(initialZoom)
				mapsdk.displayZoom(initialZoom)

			initContextMenus()

			// I'm concerned about the following code...  see https://app.asana.com/0/453301433574647/579083441232335
			const initialPosition = getVSConfigParm("initialPosition", vid)
			if(initialPosition)
				mapsdk.displayPosition(initialPosition)
		})
	// .then(addMessageListener)
}

function initContextMenus()
{
	const resetMapMenuItem = { displayName: t("map:Reset map"), iconName: "control-icon-reset", handler: () => {
			stopFollowing()
			store.dispatch(resetApplication())
			mapsdk.displayDefaultView() // consider if this should actually call displayDefaultMapView on globalState module
			// store.dispatch(actionUnfollow())
		}}

	const selectLanguageMenuItem = { displayName: t("map:Select Language"), iconName: "control-icon-language", handler: () => {
			store.dispatch(setOverlay("language"))
		}}

	registerOverlay("language", { component: LanguageSelector, doneFn: whenDone, title: "Select Language"})

	contextMenuRepo.mobileMenuBarContextMenu = {
		menuItems: [ resetMapMenuItem ],
		footer: (
				<div className="copyMenuFooter bg-ghosted text-muted">
					Map Data &copy; {currentYear} LocusLabs, Inc.
				</div>
			)
	}

	contextMenuRepo.desktopContextMenu = {
		menuItems: [ resetMapMenuItem ]
	}

	let config = getConfig(),
		availableLanguages = config.availableLanguages || ["en=US"]

	// If there is more than 1 supported language, provide a selector dialog
	if(availableLanguages.length > 1 && !getConfig().kioskMode)
	{
		contextMenuRepo.mobileMenuBarContextMenu.menuItems.push(selectLanguageMenuItem)
		contextMenuRepo.desktopContextMenu.menuItems.push(selectLanguageMenuItem)
	}

	const template = config.template
	if(template && template.header && template.header.links)
		template.header.links.forEach(link => contextMenuRepo.mobileMenuBarContextMenu.menuItems.push({
				displayName: link.title,
				iconName: link.icon,
				handler: () => {
						if(link.newPage)
							window.open(link.url, "_blank")
						else
							document.location.href = link.url
					}
			}))

	const termsURL = config.terms
	if(termsURL)
	{
		const termsOfServiceMenuItem = { displayName: t("legal:Terms of Service"), iconName: "control-language-icon", handler: () => window.open(termsURL) }
		contextMenuRepo.mobileMenuBarContextMenu.menuItems.push(termsOfServiceMenuItem)
		contextMenuRepo.desktopContextMenu.menuItems.push(termsOfServiceMenuItem)
	}
}

function addMapSDKListeners()
{
	// when a POI is clicked on the map - set it through global action
	mapsdk.onPOIClick(poiId => {
	const directionsInputShown = store.getState().search.isNavigationOpen && !store.getState().navigation.navigationOnGoing
	if(directionsInputShown)
			setNaviPoiId(poiId)
		else
			{
                if(poiId !== store.getState().global.poiId) {
                    store.dispatch(setFlightStatus(false))
                    store.dispatch(setFlightDetailStatus(false))
                    clearMarker()
                    cleanPOIMarker()
                    setStateFromPOI(poiId)
                    store.dispatch(showNavigationDialogAC(false))
                }
			}
	})
	mapsdk.addHeadingListener(heading => store.dispatch(actionSetHeading(heading)))
	mapsdk.addZoomListener(zoom => setZoom(zoom))
	// mapsdk.addPositionListener(position => store.dispatch(setPosition(position)))
	mapsdk.addPositionListener(positionBroadcast)
	mapsdk.addLevelChangeListener(levelId => {store.dispatch(setLevel(levelId))})
}

function positionBroadcast(position)
{
	store.dispatch(setPosition(position))
	firstPositionBroadcast.resolve(position)
}

function updateMap(state)
{
	displayMarkers()
		.then(() => {
			mapsdk.displayHeading(state.global.heading)
			// if(!state.navigation.poiId1 || !state.navigation.poiId2 || (!state.search.isNavigationOpen && !state.navigation.navigationOnGoing))
			// 	mapsdk.hideNavigation()
		})

	dStateNavData().promise.then(navData => {
			if(navData)
				mapsdk.displayNavigationSegment(store.getState().navigation.segIndex)
		})
}

/**
 * Provides an array of poiIds that represent the current primary search OR
 * the navigation search.
 */
const dStateSearchIdList = devaluate(
	(poiDetailsList, navDetailsList, navigationMode) => {
			let detailsList = navigationMode ? navDetailsList : poiDetailsList
			if(!detailsList || !detailsList.length)
				return null
			return detailsList.map(d => d.poiId)
		},
	() => [
			dStateSearch,
			dStateNavigationSearchLocations, /* only relevant during GetDirection sessions */
			store.getState().search.isNavigationOpen
		]
)

// displays the POI markers if necessary - otherwise hides them
function displayMarkers()
{
	return dStateSearchIdList().promise
		.then(poiIdList => {
			if(store.getState().global.poiId)
				mapsdk.displayPOIMarkers([store.getState().global.poiId])
			else
				if(poiIdList && poiIdList.length && (store.getState().search.termConfirmed || store.getState().search.poiIdList))
					mapsdk.displayPOIMarkers(poiIdList)
				else
					mapsdk.hidePOIMarkers()
		})
}

function preserveUrlState() {
	// FIXME: Hacking is a thing that things can thing.
	let venueId = (mapsdk.getVenueId() === "lhrmobile") ? "lhr" : mapsdk.getVenueId()
	// As the map is updated, update the state of the redux's store.routing and reflect those changes as browser navigation.
	let urlState = `?vid=${venueId}&s=${store.getEncodedTransferableState()}`
	store.dispatch(replace(urlState))
	// store.dispatch(replace(urlState, store.getState()))
}

const preserveUrlStateDebounced = debounce(preserveUrlState, 500)

// TODO: Put this behind a DEBUG flag
let posTracker = [ ], // for debug only
	scriptMode = false
function keyUp(e)
{
	if(getConfig().debug && getConfig().debug.geoPos)
	{
		if(e.keyCode === 80) // "p" key
		{
			if(e.shiftKey)
				ad(document.body, { tag: "textarea", text: "[" + posTracker.map(pa => "[" + pa.toString() + "]").join(", ") + "]" })
			else
				mapsdk.getCurrentPosition()
					.then(pa => {
							posTracker.push(pa)
							log.debug("Added step " + posTracker.length + " to position tracker")
						})
		}

		// if(e.keyCode === 83) // "s" key = script mode
		// {
		// 	scriptMode = !scriptMode
		// 	log.info("setting Script Mode to " + scriptMode)
		// 	if(scriptMode)
		// 	{
		// 		const getData = window.debugPosData ?
		// 				Zousan.resolve(1) :
		// 				dynaload("debugPosData.js")
		// 		getData.then(() => { setLocationProvider(makeTestScriptLocationProvider(window.debugPosData)) })
		// 	}
		// 	else
		// 	{
		// 		stopFollowing()
		// 		resetLocationProvider()
		// 	}
		// }
	}
}

function maybeRenderYouAreHere(kioskLocation, newKioskLocation, adminStep)
{
  const config = getConfig()
  const currentFloorOrd = mapsdk.getOrdinal()

	if(adminStep !== 2 && config.kioskMode)
		if(newKioskLocation && newKioskLocation.ordinal === currentFloorOrd)
			return renderYouAreHere(newKioskLocation, config.youAreHereMarkerColor)
		else
			if(kioskLocation && kioskLocation.ordinal === currentFloorOrd)
				return renderYouAreHere(kioskLocation, config.youAreHereMarkerColor)

	hideMarkerDeclaritive("youAreHere")
	return null
}

function renderYouAreHere(kioskLocation, color)
{
	mapsdk.determineIsPointWithinVenue(kioskLocation.position)
		.then(whenTrue(() => {
				let svg = getSVGForName("you-are-here-" + getCurrentLanguage())
				if(color)
					svg = svg.replace(/#00adee/g, color)
				const imageURL = svgToDataURI(svg)
				const iconOb = { url: imageURL, anchor: { x: 34, y: 88 }, size: { x: 68, y: 92 } }
				drawSuperMarkerDeclaritive("youAreHere", iconOb, kioskLocation.position, kioskLocation.ordinal)
			}
		))

	return null // should return null as this will be rendered
}

function maybeRenderYouWereHere(youWereHereLocation)
{
	const config = getConfig()
	if(!config.kioskMode && youWereHereLocation)
	{
		// let svg = getSVGForName("you-were-here-" + getCurrentLanguage())
		let svg = getSVGForName("you-were-here-solid")
		if(config.youAreHereMarkerColor)
			svg = svg.replace(/#00adee/g, config.youAreHereMarkerColor)
		const imageURL = svgToDataURI(svg)
		const iconOb = { url: imageURL, anchor: { x: 34, y: 88 }, size: { x: 68, y: 92 } }
		drawSuperMarkerDeclaritive("youWereHere", iconOb, youWereHereLocation.position, youWereHereLocation.ordinal)
	}
	else
		hideMarkerDeclaritive("youWereHere")
}

class MapContainer extends React.Component
{
	constructor(props) {
		super(props)
		addMapSDKListeners() // do this before initialization to ensure it happens ASAP (it will wait)
		initMapSDK(props)
	}

	componentWillMount()
	{
		this.context.store.subscribe(this.update.bind(this))

		mapsdk.getBuildingData().then(removeLoadIndicator)

		function removeLoadIndicator() {
			const mapContainer = document.querySelector(".map-container")
			const loadIndicator = document.querySelector(".map-container > .loadingIndicatorWrapper")
			mapContainer.removeChild(loadIndicator)
		}
	}

	// When state changes, we call the mapsdk to make the appropriate updates to the map component
	update()
	{
		const state = this.context.store.getState()
		updateMap(state)
		if(this.props.fullpage)
			preserveUrlStateDebounced() // Why is this in MapContainer?
	}

	render() {

		let { kioskLocation, newKioskLocation, adminStep, youWereHereLocation } = this.props

			return (
					<div className="map-container">
						<div
							id="map"
							onWheel={ e => { e.preventDefault(); doWheel(this.context.store.dispatch, e.deltaY, e.nativeEvent.offsetX, e.nativeEvent.offsetY) }}
							onKeyUp={ e => keyUp(e) }
							tabIndex="0"
							onClick={ e => { mapsdk.cssOffsetToCoord(e.clientX, e.clientY).then(latlng => events.fire("click", { lat: latlng[0], lng: latlng[1] }))}}/>
						<LoadIndicator/>
						<div className="map-logo">
							<Icon name="mapLogo" />
							<span>Map Data &copy; { currentYear } LocusLabs</span>
						</div>
						{ maybeRenderYouAreHere(kioskLocation, newKioskLocation, adminStep) }
						{ maybeRenderYouWereHere(youWereHereLocation) }
					</div>
				)
		}
}

MapContainer.contextTypes = {
		store: PropTypes.object
	}

const mapStateToProps = state => ({
			kioskLocation: state.onsite.kioskLocation,
			newKioskLocation: state.onsite.newKioskLocation,
			kioskHeading: state.onsite.kioskHeading,
			adminStep: state.onsite.adminStep,
			youWereHereLocation: state.global.youWereHere
		})

MapContainer = connect(mapStateToProps)(MapContainer)

export default MapContainer
