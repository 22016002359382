import React from "react"
import { connect } from "react-redux"
import { LoginStep } from "./LoginStep"
import store from "../../store"
import "./onsite-admin.pcss"
import Icon from "../common/icon/Icon"
import { log as glog, events, positionMapToLocation, setOverlay, notify } from "../globalState"
import { Directions } from "./Directions"
import { DialogEntryField } from "./DialogEntryField"
import * as mapsdk from "../../common/mapsdk"
import { getConfig } from "../.."
import { whenTrue } from "../../common/utilities"
import { registerOverlay } from "../overlay/OverlayMgr"
import { clearOverlay } from "../overlay/OverlayContainer"
import { getRedState, defineRedState } from "../../extModules/redState"

const ACTION_WAKE_UP = "onsiteAdmin/wakeUp"

const ACTION_DELETE_KIOSK = "onsiteAdmin/deleteKiosk"
const ACTION_DEFINE_KIOSK_ALL = "onsiteAdmin/defineKioskAll"
const ACTION_CLEAR_NEW_KIOSK = "onsite/clearNewKiosk"

const MAX_STEPS = 6 // number of steps in the admin wizard

const log = glog.sublog("OnsiteAdmin")

function loadLocalStorage(name, defValue) // a location is { position: [lat,lng], ordinal}
{
	const value = localStorage.getItem(name)
	try
	{
		if(value)
			return JSON.parse(value)
	}
	catch(e)
	{
		log.error("Bad JSON value in local storage named " + name + ": " + value)
	}
	return defValue
}

export function reducer(state = {
		adminStep: 0,
		kioskLocation: loadLocalStorage("kioskLocation"),
		kioskName: loadLocalStorage("kioskName"),
		kioskHeading: loadLocalStorage("kioskHeading"),
		kioskZoom: loadLocalStorage("kioskZoom")
	}, action)
{
	const redState = getRedState(action.type)
	if(redState)
		return redState.reduce(state, action)

	if(action.type === ACTION_DELETE_KIOSK)
		return Object.assign({}, state, { kioskLocation: undefined, kioskName: "", kioskHeading: undefined, kioskZoom: undefined })

	if(action.type === ACTION_DEFINE_KIOSK_ALL)
	{
		return Object.assign({}, state, { kioskLocation: action.kioskLocation, kioskName: action.kioskName, kioskHeading: action.kioskHeading, kioskZoom: action.kioskZoom })
	}

	if(action.type === ACTION_CLEAR_NEW_KIOSK)
	{
		const { newKioskLocation, newKioskHeading, newKioskZoom, ...newState } = state
		return newState
	}

	if(action.type === ACTION_WAKE_UP)
		return Object.assign({}, state)

	return state
}

const redStateKioskName = defineRedState("onsite", "kioskName")
const redStateNewKioskLocation = defineRedState("onsite", "newKioskLocation")
const redStateNewKioskHeading = defineRedState("onsite", "newKioskHeading")
const redStateNewKioskZoom = defineRedState("onsite", "newKioskZoom")

const redStateAdminStep = defineRedState("onsite", "adminStep", {
		actions: {
			open: () => redStateAdminStep.set(1),
			restart: () => redStateAdminStep.set(2),
			next: state => redStateAdminStep.set(state.adminStep + 1),
			previous: state => redStateAdminStep.set(state.adminStep - 1),
			final: () => redStateAdminStep.set(MAX_STEPS - 1),
			close: () => redStateAdminStep.set(0)
		}
	})

const actionDeleteKiosk = () => ({ type: ACTION_DELETE_KIOSK })
const actionDefineKioskAll = (kioskLocation, kioskName, kioskHeading, kioskZoom) =>
	({ type: ACTION_DEFINE_KIOSK_ALL, kioskLocation, kioskName, kioskHeading, kioskZoom })

export const open = () => {
	store.dispatch(redStateAdminStep.open())
}

export function defineKioskAll(kioskLocation, kioskName, kioskHeading, kioskZoom)
{
	store.dispatch(actionDefineKioskAll(kioskLocation, kioskName, kioskHeading, kioskZoom))
}

function passwordSubmit(pw)
{
	if(pw !== getConfig().onsiteAdminPassword)
		store.dispatch(redStateAdminStep.close())
	else
		if(store.getState().onsite.kioskLocation && store.getState().onsite.kioskLocation.position)
			store.dispatch(redStateAdminStep.final())
		else // entering admin with NO kiosk defined - so go
			store.dispatch(redStateAdminStep.next(store.getState().onsite))
}

const renderKioskLocationDirections = (kioskLocation) => (
	<Directions
		title="Set Kiosk Location"
		body="Pan and/or pinch to zoom the map or use rotation buttons in the bottom right to set the desired kiosk location. This will determine where the 'You Are Here' pins set on this kiosk. Tap the pin to confirm when you are done."
			/>
)

const renderConfirmButton = () => (
	<div
		className="button bg-active-button text-active-button"
		onClick={ () => store.dispatch(redStateAdminStep.next(store.getState().onsite)) }>
		<Icon name="dir-icon-verified" />
		Click Here to Confirm
	</div>
)

const eventHandler = (name, ob) =>
{
	let state = store.getState(),
		adminStep = state.onsite.adminStep

	log.info("OnsiteAdmin.eventHandler: ", name, adminStep, ob)

	if(name === "position" && adminStep === 2)
	{
		store.dispatch(redStateNewKioskLocation.set({
				position: ob.point,
				ordinal: mapsdk.getOrdinal()
			}))
	}

	if(name === "heading" && adminStep === 3)
		store.dispatch(redStateNewKioskHeading.set(ob.heading))

	if(name === "zoom" && adminStep === 3)
		store.dispatch(redStateNewKioskZoom.set(ob.value))
}

const renderKioskOrientationDirections = () => {

	// auto-event the current zoom/position as that is what people will expect
	const global = store.getState().global
	triggerEvent("zoom", { value: global.zoom })
	triggerEvent("heading", { heading: global.heading })

	return <Directions
		title="Set Default Map Orientation and Zoom"
		body="Pan, zoom, and rotate the map to the desired default view. You can rotate the map with two fingers if you are using a touchscreen or hold down Shift + Scroll Wheel (or trackpad) if you are on a personal computer. Tap the pin to confirm when you are done."
		additional={ renderConfirmButton() }
			/>
}

function triggerEvent(name, ob)
{
	setTimeout(eventHandler, 0, name, ob)
}

function clearNewKiosk()
{
	return store.dispatch({ type: ACTION_CLEAR_NEW_KIOSK })
}

function saveName(kioskName)
{
	const state = store.getState()

	localStorageSet("kioskName", kioskName)
	localStorageSet("kioskLocation", state.onsite.newKioskLocation)
	localStorageSet("kioskHeading", state.onsite.newKioskHeading)
	localStorageSet("kioskZoom", state.onsite.newKioskZoom)

	store.dispatch(redStateKioskName.set(kioskName))
	store.dispatch(redStateAdminStep.next(store.getState().onsite))
	defineKioskAll(state.onsite.newKioskLocation, kioskName, state.onsite.newKioskHeading, state.onsite.newKioskZoom)

	notify("Kiosk Successfully Configured", "positive", "dir-icon-verified")
}

class KioskNameEntry extends React.PureComponent {
	constructor(props) {
		super(props)
		const enteredName = props.kioskName || ""
		this.state = {enteredName}
	}

	render() {
		return (<div>
			<DialogEntryField
				placeholder="Enter-unique-kiosk-name"
				value={ this.state.enteredName }
				onSubmit={ () => saveName(this.state.enteredName) }
				autoFocus={ true }
				onChange= { value => this.setState({enteredName: value}) } />
			<div
				className="button bg-active-button text-active-button"
				onClick={ () => saveName(this.state.enteredName) }>
				<Icon name="dir-icon-verified" />
				Save
			</div>
		</div>)
	}
}

const confirmDeleteDialog = () => (
		<div className="muchPadding">
			<div className="dialogQuestion">Are you sure you want to delete the kiosk?</div>
			<p className="text-muted">This will remove all settings</p>
			<div className="buttons">
				<div className="leftSide">
					<div className="button border-primary" onClick={ clearOverlay }>Cancel</div>
				</div>
				<div className="rightSide">
					<div className="button -destructive" onClick={ deleteKiosk }>Delete Kiosk</div>
				</div>
			</div>
		</div>
	)

registerOverlay("onsiteConfirmDelete", { component: confirmDeleteDialog, doneFn: null, noDone: true })

function askDeleteKiosk()
{
	store.dispatch(setOverlay("onsiteConfirmDelete"))
}

function deleteKiosk()
{
	store.dispatch(actionDeleteKiosk())
	localStorageRemove("kioskLocation")
	localStorageRemove("kioskHeading")
	localStorageRemove("kioskZoom")
	localStorageRemove("kioskName")
	clearOverlay()
	clearNewKiosk()
}

const confirmExitAdminDialog = () => (
	<div className="muchPadding">
		<div className="dialogQuestion">Are you sure you want to exit admin mode?</div>
		<div className="buttons">
			<div className="leftSide">
				<div className="button border-primary" onClick={ clearOverlay }>Cancel</div>
			</div>
			<div className="rightSide">
				<div className="button bg-accent text-primary-button" onClick={ exitAdmin }>Exit Admin Mode</div>
			</div>
		</div>
	</div>
)

registerOverlay("onsiteConfirmExit", { component: confirmExitAdminDialog, doneFn: null, noDone: true })

function showConfirmExitAdmin()
{
	store.dispatch(setOverlay("onsiteConfirmExit"))
}

const renderKioskNameDirections = kioskName => (
	<Directions
		title="Set Kiosk Name"
		body={ [
				"Enter a unique name for this kiosk. The Kiosk Name is used for analytics to track usage from each individual kiosk.",
				"NOTE: The kiosk name cannot use spaces; use dashes or underscores instead. Max characters = 32" ] }
		additional={ <KioskNameEntry kioskName={kioskName} /> }
	/>
)

const renderExitButton = () => (
	<div
			className="exit-button button bg-active-button text-active-button"
			onClick={ showConfirmExitAdmin }>
			<Icon name="global-icon-back" />
			<div>Exit Admin Mode</div>
	</div>
)

const renderBackButton = () => (
	<div
			className="back-button button bg-secondary-button text-secondary-button"
			onClick={ () => store.dispatch(redStateAdminStep.previous(store.getState().onsite)) }>
			<Icon name="global-icon-back" />
			<div>Back</div>
	</div>
)

const renderEditDeleteButtons = restartAdmin => (
	<div className="editDeleteButtons">
		<div
			className="edit-button button bg-secondary-button text-secondary-button"
			onClick={ restartAdmin }>
			<div>Edit Kiosk</div>
		</div>
		<div
			className="delete-button button bg-red-button text-secondary-button"
			onClick={ askDeleteKiosk }>
			<div>Delete Kiosk</div>
		</div>
	</div>
)

const renderCreateKiosk = restartAdmin => (
	<div className="editDeleteButtons">
		<div
			className="create-button button bg-secondary-button text-secondary-button"
			onClick={ restartAdmin }>
			<div>Create Kiosk</div>
		</div>
	</div>
)

const renderPinTapToConfirm = () => (
	<div className="kioskPin" onClick={ () => store.dispatch(redStateAdminStep.next(store.getState().onsite)) }>
		<Icon name="tap-to-confirm" />
	</div>
)

function localStorageSet(name, value)
{
	localStorage.setItem(name, JSON.stringify(value))
}

function localStorageRemove(name)
{
	localStorage.removeItem(name)
}

function initializeAdmin(kioskLocation, kioskHeading, kioskZoom, adminStep)
{
	log.info("onsiteAdmin.initialize", kioskLocation, kioskHeading, kioskZoom, adminStep)

	if(kioskLocation) // if kiosk was already located, reposition the map to it
		mapsdk.determineIsPointWithinVenue(kioskLocation.position)
			.then(whenTrue(
					() => positionMapToLocation(kioskLocation, kioskHeading, kioskZoom),
					() => deleteKiosk()))

	// register event handler and watch for position, heading and zoom events
	events.observe(eventHandler)
}

function exitAdmin()
{
	events.detach(eventHandler)
	store.dispatch(redStateAdminStep.close())
	clearOverlay()
	clearNewKiosk()
}

class OnsiteAdmin extends React.Component {

	componentDidMount()
	{
		let {kioskLocation, kioskHeading, kioskZoom, adminStep} = this.props

		log.info("OnsiteAdmin.componentDidMount")

		initializeAdmin(kioskLocation, kioskHeading, kioskZoom, adminStep)
	}

	render() {

			let {adminStep, kioskLocation, kioskName} = this.props // properties
			let { restartAdmin} = this.props // bound actions

			/*
				Steps
				1 : Login
				2 : Set Kiosk Position
				3 : Set Kiosk heading / Zoom
				4 : Set Kiosk Name
				5 : Button Panel (edit / delete / exit)
			*/

			const kioskLocationDefined = kioskLocation && kioskLocation.position

			return (
					<div className="onsite-admin">
						{ adminStep === 1 || adminStep === 2 || adminStep === 5 ? renderExitButton() : renderBackButton() }
						{ adminStep === 1 ? <LoginStep onSubmit={ passwordSubmit }/> : null }
						{ adminStep === 2 ? renderKioskLocationDirections(kioskLocation) : null }
						{ adminStep === 3 ? renderKioskOrientationDirections() : null }
						{ adminStep === 4 ? renderKioskNameDirections(kioskName) : null }
						{ adminStep === 5 && kioskLocationDefined ? renderEditDeleteButtons(restartAdmin) : null }
						{ adminStep === 5 && !kioskLocationDefined ? renderCreateKiosk(restartAdmin) : null }
						{ adminStep === 2 ? renderPinTapToConfirm() : null }
					</div>
				)
			}
}

const mapStateToProps = state => {
	return {
		adminStep: state.onsite.adminStep,
		kioskLocation: state.onsite.kioskLocation,
		kioskHeading: state.onsite.kioskHeading,
		kioskZoom: state.onsite.kioskZoom,
		kioskName: state.onsite.kioskName
	}
}

const mapDispatchToProps = {
	restartAdmin: redStateAdminStep.restart
}

OnsiteAdmin = connect(mapStateToProps, mapDispatchToProps)(OnsiteAdmin)

export default OnsiteAdmin