import { getPath, isTruthyPath } from "../../common/utilities"

const FLIGHT_TIMES_DELTA_ALLOWANCE = 5 // allow 5 minutes variation to still be considered "on time"

class Flight {

    flightId = null
    isArrival = false;
    airportCityOrigin = "";
    airportCodeOrigin = "";
    airportCityDestination = "";
    airportCodeDestination = "";
    airlineName = "";
    airlineCode = "";
    flightNumber = "";
    statusFlight = "";
    departureDate = null
    arrivalDate = null
    departureGate = null
    arrivalGate = null
    estimatedGateDepartureDate = null
    estimatedGateArrivalDate = null
    poiId = null;
    terminalGate = null;
    levelGate = null;
    baggage = null;
    terminalInformation = null;
    utcOffsetHoursOrigin = 0;
    utcOffsetHoursDestination = 0;

    floorId = null;

    lastUpdate = new Date();

    constructor(flightInfo, flightType, airports, airlines, suggestedGates) {
        this.flightId = flightInfo.flightId;
        this.isArrival = flightType.toUpperCase() === "ARR"; //flight type can be DEP for departures or ARR for arrivals
        this.airportCodeOrigin = flightInfo.departureAirportFsCode;
        this.airportCityOrigin = this.getAirportCity(this.airportCodeOrigin, airports);
        this.airportCodeDestination = flightInfo.arrivalAirportFsCode;
        this.airportCityDestination = this.getAirportCity(this.airportCodeDestination, airports);

        this.airlineCode = flightInfo.carrierFsCode;
        this.airlineName = this.getAirlineName(this.airlineCode, airlines);
        this.flightNumber = flightInfo.flightNumber;

        this.statusFlight = this.getStatus(flightInfo);

        const airportOrigin = this.getAirportInformation(this.airportCodeOrigin, airports)
        const airportDestination = this.getAirportInformation(this.airportCodeDestination, airports)
        this.utcOffsetHoursOrigin = airportOrigin.utcOffsetHours;
        this.utcOffsetHoursDestination = airportDestination.utcOffsetHours;
        this.timeZoneOrigin = airportOrigin.timeZone;
        this.timeZoneDestination = airportDestination.timeZone;

        this.departureDate = flightInfo.departureDate.dateLocal ? new Date(flightInfo.departureDate.dateLocal) : null
		this.arrivalDate = flightInfo.arrivalDate.dateLocal ? new Date(flightInfo.arrivalDate.dateLocal) : null

        this.departureGate = getPath("airportResources.departureGate", flightInfo) || null
        this.arrivalGate = getPath("airportResources.arrivalGate", flightInfo) || null

		this.estimatedGateDepartureDate = isTruthyPath("operationalTimes.estimatedGateDeparture.dateLocal", flightInfo) ? new Date(flightInfo.operationalTimes.estimatedGateDeparture.dateLocal) : null
		this.estimatedGateArrivalDate = isTruthyPath("operationalTimes.estimatedGateArrival.dateLocal", flightInfo) ? new Date(flightInfo.operationalTimes.estimatedGateArrival.dateLocal) : null

        this.terminalInformation = this.isArrival ? flightInfo.airportResources && Boolean(flightInfo.airportResources.arrivalTerminal) ? flightInfo.airportResources.arrivalTerminal : null : flightInfo.airportResources && Boolean(flightInfo.airportResources.departureTerminal) ? flightInfo.airportResources.departureTerminal : null

		var targetGate =  this.isArrival ? this.arrivalGate.toLowerCase() : this.departureGate.toLowerCase()
		var gate = findGatePOI(suggestedGates, targetGate, this.terminalInformation)
		this.flightGate = gate

        this.poiId = ((!this.isArrival && this.departureGate) || (this.isArrival && this.arrivalGate)) && gate ? gate.poiId : null
        this.levelGate = ((!this.isArrival && this.departureGate) || (this.isArrival && this.arrivalGate)) && (gate && gate.details && gate.level) ? gate.details.level.name : null
        this.terminalGate = ((!this.isArrival && this.departureGate) || (this.isArrival && this.arrivalGate)) && gate ? gate.terminal : null

		if(this.poiId === null) {
			this.departureGate = null
			this.arrivalGate = null
		}

		// REMOVE THIS SECTION!
		// if(!this.departureDate)
		// {
		// 	this.departureDate = new Date(this.arrivalDate)
		// 	this.departureDate.setHours(this.departureDate.getHours() - 2) // make it a 2 hour flight
		// }

		// if(!this.arrivalDate)
		// {
		// 	this.arrivalDate = new Date(this.departureDate)
		// 	this.arrivalDate.setHours(this.arrivalDate.getHours() + 2) // make it a 2 hour flight
		// }
		// END OF SECTION

        this.floorId = (gate && gate.position) ? gate.position.floorId : null
        this.gatePositionMap = gate ? gate.position : null

		const updates = flightInfo.flightStatusUpdates
		this.flightInfo = flightInfo
		if(updates && updates.length && updates[updates.length - 1].updatedAt.dateUtc)
			this.lastUpdate = updates ? new Date(updates[updates.length - 1].updatedAt.dateUtc) : null

		this.baggage = flightInfo.airportResources && flightInfo.airportResources.baggage ? flightInfo.airportResources.baggage : "-"
		this.arrivalBagClaim = flightInfo.airportResources.arrivalBagClaim

		this.search = [
			this.airlineName,
			this.flightNumber,
			(this.isArrival ? this.airportCodeOrigin : this.airportCodeDestination),
			(this.isArrival ? this.airportCityOrigin : this.airportCityDestination),
			(this.isArrival ? this.arrivalGate : this.departureGate),
			this.airlineCode + this.flightNumber,
			this.airlineCode + " " + this.flightNumber]
			.filter(s => s) // throw out null values
			.map(s => s.toLowerCase())
			.join(",")
    }

    dateToUTC(date) {
        return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    }

    getIconName() {
        if( this.isCanceled() || ( (this.isArrival && this.isArrivalDelayed()) || (!this.isArrival && this.isDepartureDelayed())) )
            return 'poi_badge_gate_red_badge'
        else
            // if(this.hasArrived() || this.hasDeparted() || this.isUnknown())
            //     return 'poi_badge_gate_gray_badge'
            // else
                return 'poi_badge_gate'
    }

    getFlightDurationMinutes() {
        const timeStart = this.estimatedGateDepartureDate ? this.estimatedGateDepartureDate : this.departureDate
        const timeEnd = this.estimatedGateArrivalDate ? this.estimatedGateArrivalDate : this.arrivalDate

        return ((timeEnd - timeStart) / 1000 ) / 60
    }

	// for a 3 hour 15 minute flight, return "3h 15m"
    getFlightDurationFormatted() {
		const hours = Math.floor(this.getFlightDurationMinutes() / 60)
		const mins = this.getFlightDurationMinutes() % 60
        return (hours > 0 ? hours + "h" : "") + " " + (mins > 0 ? mins + "m" : "")
    }

    getAirportCode(isArrival) {
        return isArrival ? this.airportCodeDestination : this.airportCodeOrigin;
    }

    getAirportInformation(airportCode, airports) {
		try {
				return airports.find(airport => airport.iata.toLowerCase() === airportCode.toLowerCase());
		} catch(e) { console.error(`Trouble finding airport code ${airportCode} from airports ${airports}`); return null }
    }

    getAirportCity(airportCode, airports) {
        const airport = this.getAirportInformation(airportCode, airports)
        return airport ? airport.city : "";
    }

    getAirlineName(airlineCode, airlines) {
        const airline = airlines.find(airline => airline.iata === airlineCode || airline.fs === airlineCode || airline.icao === airlineCode);
        return airline ? airline.name : "";
    }

    hasDeparted() {
        return !this.isArrival && ( this.statusFlight === "A" || this.statusFlight === "L" || this.statusFlight === "R");
    }

    hasArrived() {
        return this.isArrival && ( this.statusFlight === "L" || this.statusFlight === "D");
    }

    getStatus(flightInfo) {
        return flightInfo.status;
    }

    isCanceled() {
        return this.statusFlight === "C";
    }

	isUnknown() {
		return this.statusFlight === "U";
	}

	getSortDate()
	{
		if(this.isFlightOnTime())
			return this.isArrival ? this.getEstimatedArrivalDate() : this.getEstimatedDepartureDate()
		return this.isArrival ? this.getScheduledArrivalDate() : this.getScheduledDepartureDate()
	}

    getDelayedDepartureMinutes() {
        const delayedTime = this.estimatedGateDepartureDate - this.departureDate
        return (delayedTime / 1000) / 60;
    }

    getDelayedArrivalMinutes() {
        const delayedTime = this.estimatedGateArrivalDate - this.arrivalDate
        return (delayedTime / 1000) / 60;
    }

    isDepartureDelayed() {
        return this.getDelayedDepartureMinutes() > FLIGHT_TIMES_DELTA_ALLOWANCE;
    }

	getEarlyDepartureMinutes() {
		const delayedTime = this.departureDate - this.estimatedGateDepartureDate
		return (delayedTime / 1000) / 60;
	}

	isDepartureEarly() {
		return this.getEarlyDepartureMinutes() > FLIGHT_TIMES_DELTA_ALLOWANCE;
	}

    isArrivalDelayed() {
        return this.getDelayedArrivalMinutes() > FLIGHT_TIMES_DELTA_ALLOWANCE;
    }

	getEarlyArrivalMinutes() {
		const delayedTime = this.arrivalDate - this.estimatedGateArrivalDate
		return (delayedTime / 1000) / 60;
	}

	isArrivalEarly() {
		return this.getEarlyArrivalMinutes() > 5;
	}

    timeFromDate(date) {
        if(!this.getHours(date) || !this.getMinutes(date))
            return "-"
        else
            return this.getHours(date).toString() + ":" + this.getMinutes(date).toString();
    }

    getHours(date) {
        const hours = date.getHours() < 13 ? date.getHours() : date.getHours() - 12;
		// return hours < 10 ? "0" + hours : hours;
		return hours
    }

    getMinutes(date) {
        return date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    }

    getAMPM(date) {
        if(!date.getHours())
            return "-"
        else
            return date.getHours() < 12 ? "AM" : "PM";
    }

	getArrivalDate()
	{
		return !this.isArrivalDelayed() && this.estimatedGateArrivalDate
			? this.estimatedGateArrivalDate
			: this.arrivalDate
	}

	isFlightOnTime()
	{
		return this.isArrival
			? !this.isArrivalDelayed() && !this.isArrivalEarly()
			: !this.isDepartureDelayed() && !this.isDepartureEarly()
	}

	// Originally scheduled arrival datetime
	getScheduledArrivalDate()
	{
		return this.arrivalDate
	}

	getScheduledDepartureDate()
	{
		return this.departureDate
	}

	// Current predicted arrival datetime
	getEstimatedArrivalDate()
	{
		return this.estimatedGateArrivalDate ? this.estimatedGateArrivalDate : this.arrivalDate
	}

	getEstimatedDepartureDate()
	{
		return this.estimatedGateDepartureDate ? this.estimatedGateDepartureDate : this.departureDate
	}

	getScheduledArrivalTimeFormatted()
	{
		return this.getTimeFormatted(this.getScheduledArrivalDate())
	}

	getEstimatedArrivalTimeFormatted()
	{
		return this.getTimeFormatted(this.getEstimatedArrivalDate())
	}

	getScheduledDepartureTimeFormatted()
	{
		return this.getTimeFormatted(this.getScheduledDepartureDate())
	}

	getEstimatedDepartureTimeFormatted()
	{
		return this.getTimeFormatted(this.getEstimatedDepartureDate())
	}

    getArrivalTimeFormatted() {
		return this.getTimeFormatted(this.getArrivalDate())
	}

	getTimeFormatted(date)
	{
		if(date)
			return this.timeFromDate(date) + " " + this.getAMPM(date)
		else
			return "--"
	}

    getDelayedArrivalTimeFormatted() {
		return this.getTimeFormatted(this.estimatedGateArrivalDate)
    }

	getDepartureDate() {
		return !this.isDepartureDelayed() && this.estimatedGateDepartureDate
			? this.estimatedGateDepartureDate
			: this.departureDate
	}

    getDepartureTimeFormatted() {
		const dep = this.getDepartureDate()
		if(!dep)
			return "--"
        return this.timeFromDate(dep) + " " + this.getAMPM(dep)
	}
}

function findGatePOI(gateSearchResults, gateNum, terminalInformation)
{
	var gates = gateSearchResults.filter(gate => gate.name.toLowerCase().includes( gateNum.toLowerCase() ))

        // ACA 7247
        var filteredGates = gates.filter(gate => {

            var parts = gate.name.split(' ') // ["Gates","1"]
            var num   = gate.name

            if(parts.length > 1)
                num = parts[1]

            return num.toLowerCase() === gateNum.toLowerCase()
        })

        if(!filteredGates.length)
            filteredGates = gates

        var gate = filteredGates[0]

        if(filteredGates.length > 0 && terminalInformation) {

            gate = filteredGates.find(gate => {
                // console.log('ALPHA gate: ', gate)
                return gate.terminal.toLowerCase().includes(terminalInformation.toLowerCase())
            })

        } else {
            gate = filteredGates.find(gate => {
                // console.log('BETA gate: ', gate)
                return gate.name.match(`(?:^|\\D)${gateNum}{${gateNum.length}}(?:$|\\D)`)
            }) || filteredGates[0]
        }

		return gate
}

export default Flight;