/*
	Used for the "global" reducer, selector and actions against a state called "global" which is referenced by more than 1 top-level component
*/

import * as mapsdk from "../common/mapsdk"
import {countSameElements} from "../common/utilities"
import devaluate from "../extModules/devaluate"
import store from "../store"
import observable from "../extModules/observable"
import logdown from "logdown"

import { getConfig } from ".."
import { stopFollowing } from "../common/bluedot"

// 400 pixels or less is condidered "mobile". Note, we only are concerned with the screen real-estate here. Other aspects of being "mobile",
// such as a touch interface, phone capabilities, geo location, etc. are feature detected independently
const MAX_MOBILE_WIDTH = 900

// IE11 doesn't support console.debug and console.info oddly
window.console.debug = window.console.debug || window.console.log
window.console.info = window.console.info || window.console.log
window.console.trace = window.console.trace || window.console.log
window.console.error = window.console.error || window.console.log
window.console.warn = window.console.warn || window.console.log

export const log = logdown("js-widgets")
log.state.isEnabled = process.env.NODE_ENV === "development"
log.sublog = function(subfix) { const nlog = logdown(this.opts.prefix + "." + subfix); nlog.state.isEnabled = this.state.isEnabled; return nlog }
window.log = log.sublog("window")

/* Our zoom sets a level from 0 to 100, indicating min to max.
	This can be translated by the xpose component in any way
	it sees fit */
const ACTION_SET_ZOOM = "zoomSelector/setZoom"
const ACTION_SET_HEADING = "global/setHeading"
const ACTION_HEADING_CHANGE = "global/headingChange"
const ACTION_SET_POSITION = "global/setPosition"
const ACTION_SET_NOTIFICATION = "global/notification"
const ACTION_SET_IS_MOBILE_WIDTH = "global/isMobileWidth"
const ACTION_SET_LEVEL = "levelSelector/setLevel"
const ACTION_SET_BUILDING = "levelSelector/setBuilding"
const ACTION_SET_ORDINAL = "levelSelector/setOrdinal"
const ACTION_SET_ACTIVE_CONTEXT_MENU = "global/setContextMenuID"
const ACTION_WAKE_UP = "global/wakeUp"
const ACTION_CLEAR_OVERLAY = "global/clearOverlay"
const ACTION_SET_OVERLAY = "global/setOverlay"
const ACTION_SET_YOU_WERE_HERE = "global/setYouWereHere"
const ACTION_SET_POI = "global/setPOI"
const ACTION_CLOSE_POI = "global/closePOI"
const ACTION_SET_CONTACT_SHEET = "global/setContactSheet"

export const isMobileWidth = node => {
	const width = node ? node.getBoundingClientRect().width : window.innerWidth
	return Boolean(width <= MAX_MOBILE_WIDTH)
}

export const constrain = (value, min, max) => Math.min(Math.max(value, min), max)

export const events = observable()

export function reducer(state = { heading: 0, position: [ 0, 0], isMobileWidth: isMobileWidth() }, action)
{
	if(action.type === ACTION_SET_POI)
		return Object.assign({}, state, { poiId: action.id })

	if(action.type === ACTION_CLOSE_POI)
		return Object.assign({}, state, { poiId: undefined })

	if(action.type === ACTION_WAKE_UP)
            return Object.assign({}, state)

	if(action.type === ACTION_SET_ZOOM)
		return Object.assign({}, state, { zoom: constrain(action.zoom, 0, 100) })

	if(action.type === ACTION_SET_HEADING) // convert heading to equivalent 0-360 range
		return Object.assign({}, state, { heading: action.heading < 0 ? action.heading % 360 + 360 : action.heading % 360 })

	if(action.type === ACTION_SET_POSITION)
		return Object.assign({}, state, { position: action.position })

	if(action.type === ACTION_HEADING_CHANGE)
		return Object.assign({}, state, { heading: (state.heading + action.amount + 360) % 360 })

	if(action.type === ACTION_SET_LEVEL)
		return Object.assign({}, state, { levelId: action.levelId })

	if(action.type === ACTION_SET_BUILDING)
		return Object.assign({}, state, { buildingId: action.buildingId })

	if(action.type === ACTION_SET_ORDINAL)
		return Object.assign({}, state, { ordinal: action.ordinal })

	if(action.type === ACTION_SET_NOTIFICATION)
		return Object.assign({}, state, {
				notification: action.notification
			})

	if(action.type === ACTION_SET_OVERLAY)
		return Object.assign({}, state, { overlayName: action.overlayName})

	if(action.type === ACTION_CLEAR_OVERLAY)
	{
		let { overlayName, ...newState } = state // use destructuring to remove the overlayName value from state
		return newState
	}

	if(action.type === ACTION_SET_YOU_WERE_HERE)
		return Object.assign({}, state, { youWereHere: action.youWereHere})

	if(action.type === ACTION_SET_IS_MOBILE_WIDTH)
		return Object.assign({}, state, { isMobileWidth: action.isMobileWidth })

	if(action.type === ACTION_SET_ACTIVE_CONTEXT_MENU)
		return Object.assign({}, state, { activeContextMenuID: action.activeContextMenuID, contextMenuClass: action.contextMenuClass })

	if(action.type === ACTION_SET_CONTACT_SHEET)
		return Object.assign({}, state, { contactSheet: action.contactSheet })

	return state
}

// This should only be called via the addZoomListener handler in MapContainer
export const setZoom = zoom => {
	setTimeout(() => store.dispatch({
		type: ACTION_SET_ZOOM,
		zoom: zoom
	}), 0)}

export const setIsMobileWidth = (store, isMobileWidth) => {
		store.dispatch({ type: ACTION_SET_IS_MOBILE_WIDTH, isMobileWidth: isMobileWidth })
	}

export const actionSetHeading = heading => { return {
	type: ACTION_SET_HEADING,
	heading: heading
}}

export const setOverlay = overlayName => ({
		type: ACTION_SET_OVERLAY,
		overlayName
	})

export const setClearOverlay = () => ({
		type: ACTION_CLEAR_OVERLAY
	})

export const headingChange = amount => { return {
	type: ACTION_HEADING_CHANGE,
	amount: amount
}}

// Set with a 2 element array [ latitude, longitude ]
export const setPosition = position => ({
		type: ACTION_SET_POSITION,
		position
	})

export const actionSetContactSheet = contactSheet => ({
	type: ACTION_SET_CONTACT_SHEET,
	contactSheet
})

export const setOrdinal = ordinal => { return {
	type: ACTION_SET_ORDINAL,
	ordinal: ordinal
}}

/**
 * Set a You-Were-Here marker at the location
 * @param {object} youWereHere Location to place a you-were-here marker
 * @param {[lat,lng]} youWereHere.position
 * @param {integer} youWereHere.ordinal
 */
export const actionSetYouWereHere = youWereHere => ({
	type: ACTION_SET_YOU_WERE_HERE,
	youWereHere
})

// This self-dispatches!
export const wakeUp = (delay = 0) => {
        setTimeout(() => store.dispatch({
        type: ACTION_WAKE_UP
    }), delay)}

export const clearNotification = () => {
	store.dispatch({
		type: ACTION_SET_NOTIFICATION,
		notification: null
	})
}

export const showContextMenu = (activeContextMenuID, contextMenuClass) => ({
		type: ACTION_SET_ACTIVE_CONTEXT_MENU,
		activeContextMenuID,
		contextMenuClass
})

export const notify = (msg, nClass, icon, manualClearFlag) => {

	store.dispatch({
		type: ACTION_SET_NOTIFICATION,
		notification: { msg, nClass, icon}
	})

	if(!manualClearFlag)
		setTimeout(() => {
			const notification = store.getState().global.notification
			if(notification && msg === notification.msg && nClass === notification.nClass && icon === notification.icon)
					clearNotification()
		}, 5000)

}
window.notify = notify

export const notifyPositive = (msg, manualClearFlag) =>
	notify(msg, "positive", "dir-icon-verified", manualClearFlag)
export const notifyAlert = (msg, manualClearFlag) =>
	notify(msg, "alert", "search-icon-nomatches", "manualClearFlag")

export const dStateBuildingData = devaluate(
		(venueId) => mapsdk.getBuildingData(),
		() => [mapsdk.getVenueId()]
	)

export const dStateAreaStruct = devaluate(
		(venueId) => mapsdk.getAreaStruct(),
		() => [mapsdk.getVenueId()]
	)

export const dStateSearch = devaluate(
	(term, termConfirmed, poiIdList) => {
			if(term)
				term = term.trim()
			log.debug("globalState.dStateSearch searching for ", term, termConfirmed, poiIdList)
			return poiIdList ? mapsdk.idListSearch(poiIdList) :
					mapsdk.proximitySearch(term, true, termConfirmed)
		},
	() => [
			store.getState().search.term,
			store.getState().search.termConfirmed,
			store.getState().search.poiIdList
		]
)

export const dStateArea = devaluate(
		(venueId, areaStruct, position, radius, ordinal) => mapsdk.determineAreaInView(areaStruct, position, radius, ordinal),
		() => [
				mapsdk.getVenueId(),
				dStateAreaStruct,
				store.getState().global.position,
				mapsdk.getRadius,
				mapsdk.getOrdinal()
			]
	)

export const dStateBuildingsWithSearchMatches = devaluate(
	list => {
			if(!list)
				return null
			let buildingList = list.map(poi => poi.details.level.building.id)
			let levelsList = list.map(poi => poi.details.level.id)
			return {buildings: countSameElements(buildingList), levels: countSameElements(levelsList)}
		},
	() => [dStateSearch]
)
export const dStateCurrentBuilding = devaluate(
	areaTuple => areaTuple ? areaTuple[0] : null,
	() => [dStateArea]
)

window.dStateCurrentBuilding = dStateCurrentBuilding

/**
 * Returns the current search results for the currently selected building
 * If there is no currently selected building, all results are returned
 */
export const dStateSearchCurrentBuildingOrAll = devaluate(
			(list, currentBuliding) => {
					if(list && currentBuliding)
						return list.filter(d => poiInBuilding(d, currentBuliding))
					return list
				},
			() => [
					dStateSearch,
					dStateCurrentBuilding
				]
		)

/**
 * Returns the point { latLng: [lat,lng], ordinal } of the current user's "known" physical location.
 * If the user's location is not known, undefined is returned.
 * By "known", we mean either via bluedot or a kiosk or a kiosk-handoff
*/
export function getCurrentPhysicalLocation()
{
	const info = getPhysicalInfo()
	return info ? info.location : undefined
}

window.getPhysicalInfo = getPhysicalInfo
let lastPhysicalInfo = null // cache this object so it doesn't cause redraws
const getPI = (point, ord) => {
		if(!lastPhysicalInfo || lastPhysicalInfo.location.position !== point || lastPhysicalInfo.location.ordinal !== ord)
			lastPhysicalInfo = { location: { position: point, ordinal: ord }, zoom: 25, heading: 0 }
		return lastPhysicalInfo
	}

export function getPhysicalInfo()
{
	const state = store.getState()

	// 1. bluedot
	const isBluedot = Boolean(state.bluedot.bluedotType) && state.bluedot.lastLocation
	if(isBluedot)
		return getPI(state.bluedot.lastLocation.point, state.bluedot.lastLocation.ord)

	// 2. kiosk mode gets the kioskLocation regardless...
	const onsite = store.getState().onsite
	if(getConfig().kioskMode)
	{
		if(onsite.kioskLocation && onsite.kioskLocation.position)
			return { location: onsite.kioskLocation, zoom: onsite.kioskZoom, heading: onsite.kioskHeading }
		else
			return undefined
	}

	// if we pass through above if, we are NOT kiosk mode...

	// 3. kiosk handoff
	if(store.getState().global.youWereHere)
		return { location: store.getState().global.youWereHere }

	return undefined
}

/**
 * Display the default map view - generally the venue center and zoomed out quite a bit, unless
 * a physical location is known in which case that is used (kiosks, fids, etc)
 * @param {integer} time time (ms) for animation duration
 * @param {booleanig} mapCenterFlag ignore physical location, even when known, use map center
 */
export function displayDefaultMapView(time, mapCenterFlag)
{
	const info = getPhysicalInfo()
	if(info && !mapCenterFlag)
		positionMapToLocation(info.location, info.heading, info.zoom, time)
	else
	{
		store.dispatch(actionSetHeading(0)) // doesn't happen in mapsdk.displayDefaultView anymore as it requires our state to change
		mapsdk.displayDefaultView(time)
	}
}

/**
 * Returns the current search results for the currently selected building
 * If there is no currently selected building, no results are returned
 * This must be opposite of dStateSearchCurrentBuildingOrAll
 */
export const dStateOtherBuildingsLocations = devaluate(
	(list, currentBuliding) => {
					if(list && currentBuliding)
						return list.filter(d => !poiInBuilding(d, currentBuliding))

					return null // if no list is returned, or if no building is current, then there are no "other locations"
		},
	() => [
		dStateSearch,
		dStateCurrentBuilding
	]
)

function poiInBuilding(poi, building)
{
	if(!poi.details.level.building)
		return true

	if(!building.id)
		throw Error("Building ID was null: ", building)

	return poi.details.level.building.id === building.id
}

export const dStateSuggestedSearches = devaluate(
	term => mapsdk.autocomplete(term ? term.trim() : term),
	() => [store.getState().search.term ]
)

export const dStateZoom = devaluate(
	(zoom) => zoom,
	() => [ mapsdk.getZoom() ]
)

const setPoi = id => { return {
		type: ACTION_SET_POI,
		id: id
	}}

export const setLevel = levelId => {
	// if(geoLocation.getLastKnownLocation())
	// 	geoLocation.bluedot(geoLocation.getLastKnownLocation())
	return {
		type: ACTION_SET_LEVEL,
		levelId: levelId
	}}

export const setBuilding = buildingId => { return {
		type: ACTION_SET_BUILDING,
		buildingId: buildingId
	}}

export const closePOI = () => ({
		type: ACTION_CLOSE_POI
	})

// Sets the primary Poi-id state along with level, zoom, and position
export function setStateFromPOI(id)
{
    if(id !== store.getState().global.poiId) {
        store.dispatch(setPoi(id))
		mapsdk.selectPOI(id)
		stopFollowing()
        events.fire("selectPOI", { id })
    }
}

export function positionMapToLocation(location, heading, zoom, time)
{
	const pos = location.position,
		ordinal = location.ordinal

	let ordSwitch = ordinal !== undefined
		? mapsdk.displayOrdinal(ordinal)
		: Promise.resolve(true)

	ordSwitch.then(() => {

			// if(pos && Array.isArray(pos) && mapsdk.isPointWithinVenue(pos))
			// 	mapsdk.displayPosition(pos, time)
			if(heading !== undefined && Number.isFinite(heading))
				store.dispatch(actionSetHeading(heading))
			// if(zoom !== undefined && Number.isFinite(zoom) && zoom >= 0 && zoom <= 100)
			// 	mapsdk.displayZoom(zoom, time)

			mapsdk.displayPositionZoomHeading(
					pos,
					zoom,
					heading,
					time
				)
		})
}