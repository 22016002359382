function fire()
{
	var ob = this._observers

	if(!ob)
		return undefined

	for(var x = 0; x < ob.length; x++)
		try { ob[x].apply(null, arguments) }
		catch(err) { } // eslint-disable-line

	return this
}

function observe(cb)
{
	if(!this._observers)
		this._observers = []

	this._observers.push(cb)

	return {
			detach: detach.bind(this, cb),
			ob: this
		}
}

function detach(cb)
{
	rm(this._observers, cb)
	return this
}

// Remove element from array at index specified and return the removed element.
// Source array will then have item removed.
function rmAt(ar, index)
{
	return ar.splice(index, 1)[0] // oh yah, thats intuitive!
}

// Removes the value from the array if it exists. The array is then returned
function rm(ar, value)
{
	var i = 0
	do
	{
		i = ar.indexOf(value, i)
		if(i >= 0)
			rmAt(ar, i)
	} while(i >= 0)
	return ar
}

function filter(fn)
{
	var o2 = create()

	this.observe(function() {
			var pass = fn.apply(null, arguments)
			if(pass)
				o2.fire.apply(o2, arguments)
		})

	return o2
}

// When first argument denotes an event name, this is a convenience
// method to filter by that name
function filterByName(eventName)
{
	return filter.call(this, function(name) {
				return eventName === name
			})
}

// Filters on equivilence of first argument (often the event name) and observes
// the result - calling the callback function when triggered
function on(value, cb)
{
	return filterByName.call(this, value).observe(cb)
}

// only fire when the first argument matches v
function onValue(v)
{
	return this.filter(function(x) { return x === v })
}

function map(fn)
{
	var o2 = create(), args = arguments

	this.observe(function() {
			if(typeof fn === "function")
				return o2.fire(fn.apply(null, arguments))
			else
				return o2.fire.apply(o2, args)
		})

	return o2
}

var observable = {
		detach: detach,
		fire: fire,
		map: map,
		observe: observe,
		on: on,
		onValue: onValue
	}

// extend `to` argument with properties from `from`
// [short version]
function extend(to, from)
{
	for(var prop in from)
			to[prop] = from[prop]

	return to
}

function create(ob)
{
	if(ob)
		return extend(ob, observable)

	return Object.create(observable)
}

export default create