import Zousan from "../extModules/zousan-plus"

// Similar to Array.forEach - but  allows you to return a value within the inner function
// which STOPS the iteration and returns that value to the caller.
export function each(a, fn, caller)
{
	for(var prop in a)
		if(a.hasOwnProperty && a.hasOwnProperty(prop))
		{
			var ret = fn.call(caller, a[prop], prop)

			if(ret !== undefined)
				return ret
		}
	return undefined
}

// random integer from x to y (x is inclusive, y is non-inclusive. i.e. rand(1,5) will be 1, 2, 3 or 4 )
export const rand = (x, y) => Math.floor(Math.random() * (y - x)) + x

// polyfill for Array.find (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find)
export const arrayFind = (array, fn, thisArg) => each(array, item => fn(item), thisArg)

// Object.values shim - browser support is spotty
export function values(o) { let v = []; for(var p in o) v.push(o[p]); return v }

// FP style if/else. Call whenTrue with true/false fn paths and get a function that you can call with true or false
export const whenTrue = (fn, fnElse) => bool => {
	if(bool)
		return fn()
	else
		if(fnElse)
			return fnElse()
		else
			return undefined
	}

/**
* NOTE: Copied from Fujisawa project by Glenn Crownover on 4/28/2017
* Same as the YUI's Y.throttle, except this one ensures that the
* last call to fn is not swallowed.  i.e. if this is used to respond to
* a window resize event, it will ensure that the final resize call (which
* will indicate the "resting size" of the window) will be acted upon.
* i.e.  If x = 100ms, n = event, and this is called with 500ms
*  xx1x2x3xx4xxx
*  xx1xxxx3xxxx4
* Note: That last 4 call will not happen if you indicate noTailCall = true
*/
export function throttle(fn, ms, noTailCall)
{
	ms = ms ? ms : 150

	if(ms === -1) // no throttling
		return function() {fn.apply(null, arguments)}

	let lastCall = (new Date()).getTime()
	let lastArguments = null
	let lastcallHandle = null

	return function() {
		let now = (new Date()).getTime()
		if(now - lastCall > ms) // no call within timeframe, so call it directly
		{
			lastCall = now
			fn.apply(null, arguments)
		}
		else  // we are being throttled out!
		{
			lastArguments = arguments
			if(!lastcallHandle && !noTailCall)
			{
				lastcallHandle = setTimeout(function() {
						fn.apply(null, lastArguments)
						lastcallHandle = null
						lastCall = new Date().getTime()
					}, ms - (now - lastCall))
			}
		}
	}
}

export const delay = ms => ret => new Zousan(y => setTimeout(y, ms, ret))

//  Similar to the throttle function proxy, except ONLY the final
// event within an "event grouping" is fired.  Any events that occur
// within the delayed time of each other are considered part of an event group.
// Each event re-times the group finish - i.e. if events occur within the time continuously, this will never fire.
// For Example, use for text field key listeners to notify when the user has "paused" (for lookups)
// i.e.  If x = 100ms, n = event, and this is called with 500ms
//  xx1x2 x3xx4 xxxxx
//  xxxxx xxxxx xxxx4
export function debounce(fn, ms)
{
	ms = ms ? ms : 150

	var lastArguments = null,
		lastContext = null,
		cbHandle = null

	function doCallback()
	{
		fn.apply(lastContext, lastArguments)
	}

	return function() {

			lastArguments = arguments
			lastContext = this // eslint-disable-line

			// when a new event group hits, swallow the event and schedule a maybe in full ms time
			if(cbHandle)
				clearTimeout(cbHandle)
			cbHandle = setTimeout(doCallback, ms)
		}
}

// an "identity" function wrapper - useful for replacing throttle or debounce
// when wanting all functions to pass through
export function passthrough(fn)
{
	return function() {
		fn.apply(this, arguments)
	}
}

// a better ternary alternative IMHO. If exp is truthy v1 is returned, else v2 is returned.
// if the value to return is a function, it is passed the exp, evaluated and its return value is returned
// Note: v2 is optional - if not specified and exp is falsy, undefined is returned
export const cond = (exp, v1, v2) => exp ? typeof v1 === "function" ? v1(exp) : v1 : typeof v2 === "function" ? v2(exp) : v2
export const scond = (exp, v1, v2) => cond(exp, v1, v2 || "")
export const identity = x => x

// countSameElements(["hello", "world", 1, 2, 3, 1, 2, 3]) = { '1': 2, '2': 2, '3': 2, hello: 1, world: 1 }
export function countSameElements(list) {
		let counts = {}
		list.forEach(function(x) { counts[x] = (counts[x] || 0) + 1 })
		return counts
	}

export function getMobileOperatingSystem()
{
	var userAgent = navigator.userAgent || navigator.vendor || window.opera
	if(userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i))
		return "iOS"
	else if(userAgent.match(/Android/i))
		return "Android"
	else
		return "unknown"
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export function extend(to, from)
{
	for(var prop in from)
			to[prop] = from[prop]

	return to
}

/* returns a function proxy on the passed function which only runs it once
	and preserves the return value to return on any subsequent runs */
export function onceOnly(fn)
{
	let runFlag = false, retValue
	return function() {
			if(runFlag)
				return retValue
			runFlag = true
			retValue = fn.apply(this, arguments)
			return retValue
		}
}

export function listEquals(list1, list2)
{
	if(list1 === list2) return true
	if(!list1)
		return !list2
	if(!list2)
		return !list1
	if(list1.length !== list2.length) return false

	// simple shallow compare
	return list1.reduce((p, c, i) => c !== list2[i] ? false : p, true)
}

export function onWakeEvent(fn)
{
	const SLEEP_TIME = 2000
	let lastwake = Date.now()
	const wake = () => {
			if(Date.now() - lastwake > SLEEP_TIME * 2)
				fn()
			lastwake = Date.now()
			setTimeout(wake, SLEEP_TIME)
		}
		setTimeout(wake, SLEEP_TIME)
	}

/**
 *  Given a rect (as defined by [ <min x,y> , <max x,y> ]) and a point ( [x,y]), return true
 * if the rect contains the point.
 * NOTE: when used for lng/lat
 */
export function rectContains(rect, point)
{
	const [ ul, lr ] = rect
	return point[0] >= ul[0] && point[0] <= lr[0] && point[1] >= ul[1] && point[1] <= lr[1]
}
export const round = d => n => Math.round(n * Math.pow(10, d)) / Math.pow(10, d)
export const round6 = round(6)

export function retryFetch(url, options) {
  var retries = 3;
  var retryDelay = 1000;

  if(options && options.retries) {
    retries = options.retries;
  }

  if(options && options.retryDelay) {
    retryDelay = options.retryDelay;
  }

  return new Promise(function(resolve, reject) {
    var wrappedFetch = function(n) {
      fetch(url, options)
        .then(function(response) {
          resolve(response);
        })
        .catch(function(error) {
          if(n > 0) {
            retry(n);
          } else {
            reject(error);
          }
        });
    };

    function retry(n) {
      setTimeout(function() {
          wrappedFetch(--n);
        }, retryDelay);
    }

    wrappedFetch(retries);
  });
}

export function parseQuery(search)
{
	var args = search.substring(1).split('&')
	var argsParsed = {}
	var i, arg, kvp, key, value

	for(i = 0; i < args.length; i++)
	{
		arg = args[i]
		if(-1 === arg.indexOf('=')) // eslint-disable-line yoda
			argsParsed[decodeURIComponent(arg).trim()] = true
		else
		{
			kvp = arg.split('=')
			key = decodeURIComponent(kvp[0]).trim()
			value = decodeURIComponent(kvp[1]).trim()
			argsParsed[key] = value
		}
	}

	return argsParsed
}

// Get a deeply nested property within an object:
// getPath("b.c", {a: { b: { c: 50 }}})
export function getPath(props, ob)
{
	// offer a curried version (yum!)
	if(ob === undefined)
		return ob => getPath(props, ob)

	for(const prop of props.split("."))
	{
		if(ob[prop] !== undefined)
			ob = ob[prop]
		else
			return undefined
	}
	return ob
}

export const hasDefPath = (ob, props) => getPath(ob, props) !== undefined
export const isTruthyPath = (ob, props) => Boolean(getPath(ob, props))

// pass in a function to be called and receive a function that when called
// with a truthy value executes the first function with that value. If a default
// is specified, that is used instead when value is not truthy.
const ifTruthy = fn => (x, def) => x ? fn(x) : def

