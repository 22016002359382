import React from "react"
import FlightStatusListElement from "./FlightStatusListElement"

const renderFlights = (flights) => {
	if(flights && flights.length > 0)
		return (
			<div className="flightsList flight-box">
				{ flights.map((f, i) => <FlightStatusListElement key={f.airlineCode + f.flightNumber + i} flight={f}/>) }
			</div>
		)
    return null
}

const FlightStatusList = ({flights}) =>
    <div className="suggestionsList">
		{ renderFlights(flights) }
	</div>

export default FlightStatusList