import React from "react"

import "./qrcode-dialog.pcss"
import LoadIndicator from "../../common/loadIndicator/LoadIndicator"
import QRCode from "../../../extModules/qrcode"
import { ad } from "../../../extModules/domTools"
import { log } from "../../globalState"
import { retryFetch } from "../../../common/utilities"

function getShortURL(url)
{
	return retryFetch("https://api-ssl.bitly.com/v3/shorten?access_token=a98cf56e9bd670e9a409772198883c129fa4407d&longUrl=" + encodeURIComponent(url))
			.then(response => response.json())
			.then(bitlyOb => bitlyOb.data.url)
}

function showQRCode(el)
{
	if(el)
	{
		let href = this.props.url + "&ho" // handoff indicator
		if(href.indexOf("localhost") >= 0)
		{
			log.warn("Running from *localhost* - bit.ly doesn't support. local URL is `" + href + "`")
			href = href.replace(/localhost:\d*\//, "maps.locuslabs.com/056940f4-2d45-4b69-b3ea-5594701a83e4/index.html")
		}
		// href = href.replace(/http:/, "https:")
		console.log("Displaying qrCode for URL ", href)

		getShortURL(href)
			.then(shortURL => {

					el.innerHTML = "" // clear loading indicator

					const div = document.createElement("div")

					const qrCode = new QRCode(div, { // eslint-disable-line no-unused-vars
							text: shortURL,
							width: 160,
							height: 160,
							colorDark: "#000000",
							colorLight: "#ffffff",
							correctLevel: QRCode.CorrectLevel.L
						})

					setTimeout(()=> {
							div.style.backgroundImage = "linear-gradient(180deg, #2C5C86 0%, #082843 100%), url(" + div.childNodes[1].src + ")"
							div.innerHTML = ""
							div.classList.add("qrcode")

							el.appendChild(div)

							// add the url to the div right below the qrCode (reserved for the URL)
							el.nextSibling.innerHTML = shortURL

						}, 1000)
				})
			.catch(e => {
					console.log("el: ", el)
          console.log("e: ", e)
          el.innerHTML = ""
					ad(el, { klass: "error", text: "An error has occurred. Please try again later."})
				})
		}
}

class QRCodeDialog extends React.Component {
	render() {
		return (
				<div className="qrcode-dialog bg-primary fg-primary">
					<p>
						Take this map with you on your mobile browser by scanning this code:
					</p>
					<div
							className="codeImage"
							ref={ showQRCode.bind(this) }
						>
						<LoadIndicator />
					</div>
					<p className="url"></p>
					<p className="secondaryText">
						On iOS, simply point your camera at the screen.
					</p>
				</div>
			)
	}
}

export default QRCodeDialog