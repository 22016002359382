/* global locuslabs */
import Zousan from "../extModules/zousan-plus"
import * as mapsdk from "./mapsdk"

import store from "../store"

import { log as glog } from "../components/globalState"
import { whenTrue, onceOnly, onWakeEvent } from "./utilities"
import { getRedState, defineRedState } from "../extModules/redState"
import { debugProp } from "../App";

const log = glog.sublog("bluedot")

let lastKnownLocation = null

export function reducer(state = { }, action)
{
	const redState = getRedState(action.type)
	if(redState)
		return redState.reduce(state, action)

	return state
}

const redStateBluedotType = defineRedState("bluedot", "bluedotType") // set only when bluedot active. Can be "blue" or "gray"
const redStateIsFollowing = defineRedState("bluedot", "isFollowing") // true when map is following bluedot
const redStateLastLocation = defineRedState("bluedot", "lastLocation")

/*
	First, define location providers - in future these may move to own modules.
	To play, a location provider must provide the following function props:
		getCoordinates : () -> Promise <Coords>
		follow: (fn, err) -> <handle>
		unfollow: (handle) -> null
*/

let providerFollowHandle = null,
	provider = null

export const setLocationProvider = (locationProvider) => {
	stopFollowing("locationProvider reset") // ensure we unfollow the previous provider
	provider = locationProvider
}

// Convert a <Coords> type object into a <Point> type (array of [lat,lng])
const coordsToPoint = coords => [ coords.latitude, coords.longitude ]

const webGeoLocationProvider = {
	name: "webGeoLocationProvider",
	// Attempts to determine the geolocation of the user. If/when successful, a Coordinates
	// object is returned : https://developer.mozilla.org/en-US/docs/Web/API/Coordinates
	// This function promisifies the geolocation interface.
	getCoordinates: () => {
			return new Zousan((resolve, reject) => {
					if(!("geolocation" in navigator))
					{
						reject(Error("gelocation API not found"))
					}
					navigator.geolocation.getCurrentPosition(pos =>
							{
								resolve(pos.coords)
							},
							er => { reject(er) },
							{ maximumAge: 0, enableHighAccuracy: true, timeout: 10000 }
						)
				})
		},
		follow: (fn, err) => { webGeoLocationProvider.watchId = navigator.geolocation.watchPosition(position => fn(position.coords), e => err(e), {enableHighAccuracy: true})},
		unfollow: () => {
				if(webGeoLocationProvider.watchId)
					navigator.geolocation.clearWatch(webGeoLocationProvider.watchId)
				webGeoLocationProvider.watchId = null
		}
}

// Default the provider to use the webGeoLocationProvider
provider = webGeoLocationProvider

function maybeRunTestScript()
{
	const USE_WFS_TEST_SCRIPT = debugProp("wfsBluedotTest") , TEST_ACCURACY = 10, TEST_SCRIPT_TIME = 5

	if(USE_WFS_TEST_SCRIPT)
	{
		log.info("WFS bluedot test script activating")
		setLocationProvider(makeTestScriptLocationProvider([
			{"latitude": 37.78437994520778, "longitude": -122.40635845602665,"accuracy": TEST_ACCURACY,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.784315676660576, "longitude": -122.4062801313564,"accuracy": TEST_ACCURACY * 1.3,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78429900016925, "longitude": -122.40633364515747,"accuracy": TEST_ACCURACY * 10,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78428908108908, "longitude": -122.40643473661206,"accuracy": TEST_ACCURACY * 12,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.784368550693635, "longitude": -122.40655482294387,"accuracy": TEST_ACCURACY * 5,"heading":0,"floorLevel":2,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78443414737002, "longitude": -122.4066380647192,"accuracy": TEST_ACCURACY * 5,"heading":0,"floorLevel":2,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.784506007517464, "longitude": -122.40654140521352,"accuracy": TEST_ACCURACY * 3,"heading":0,"floorLevel":2,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78446438893765, "longitude": -122.40643087080328,"accuracy": TEST_ACCURACY,"heading":0,"floorLevel":2,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78442619036475, "longitude": -122.40635233744814,"accuracy": TEST_ACCURACY,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.784388517232586, "longitude": -122.40637809716613,"accuracy": TEST_ACCURACY,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78432150515137, "longitude": -122.40627269359561,"accuracy": TEST_ACCURACY,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78430103402857, "longitude": -122.40634923238729,"accuracy": TEST_ACCURACY,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78428285127994, "longitude": -122.40636251761674,"accuracy": TEST_ACCURACY,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78428450230385, "longitude": -122.40643148828603,"accuracy": TEST_ACCURACY,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78436995345575, "longitude": -122.4065745214286,"accuracy": TEST_ACCURACY,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78450799998799, "longitude": -122.40654878043259,"accuracy": TEST_ACCURACY,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME},
			{"latitude": 37.78461352286303, "longitude": -122.40666537398673,"accuracy": TEST_ACCURACY,"heading":0,"floorLevel":3,"next": TEST_SCRIPT_TIME}]))
	}
	provider.follow(geoLocationCallback, e => err(e))
}

/**
 *
 * @param {Array Coords} data An array of coordinate objects (i.e. objects that contain at least latitude & longitude properties)
 */
export function makeTestScriptLocationProvider(data)
{
	let scriptIndex = 0,
		timerHandle = null,
		listener = null

	const next = () => {
			const datapoint = data[scriptIndex++]
			if(listener)
				listener(datapoint)
			if(scriptIndex < data.length)
				timerHandle = setTimeout(next, datapoint.next * 1000)
		}
	setTimeout(next, 1000) // start sending data in 1 second

	return {
		name: "scriptProvider",
		getCoordinates: () => data[scriptIndex],
		follow: (fn, err) => {
				listener = fn
			},
		unfollow: () => {listener = null }
	}
}

function emptyFn() { }// eslint-disable-line no-empty-function

// Provide a way for other modules to reset the provider to the webGeoLocationProvider
export const resetLocationProvider = () => { setLocationProvider(webGeoLocationProvider) }

// Provides a promise to return a coord object as soon as possible
export function getGeoLocation()
{
	return provider.getCoordinates()
}

// If the user interacts with the map, stop following
function stopFollowingOnMapInteraction()
{
	// mapsdk.addPanListener(stopFollowing)
	// mapsdk.addLevelChangeListener(stopFollowing)
	locuslabs.hammer.on('panstart', () => stopFollowing("hammer.panstart"))
	// mapsdk.addZoomListener(() => stopFollowing("zoom"))
	onWakeEvent(monitorLocation)
}

const addStopFollowingHooks = onceOnly(stopFollowingOnMapInteraction) // ensure we only do othis once
const maybeRunTestScript1 = onceOnly(maybeRunTestScript)

// begin tracking this user's location using the current location provider. This is not equivilent to
// "following" the user - this just tracks the user and possibly displays blue/gray dot.
export function monitorLocation()
{
	log.info("monitorLocation")
	provider.unfollow() // clear up any existing follow...

	provider.follow(geoLocationCallback, e => err(e))

	addStopFollowingHooks()

	maybeRunTestScript1()
}

export function simLocation(coords)
{
	log.info("simLocation", coords)
	geoLocationCallback(Object.assign({ floorLevel: 0 }, coords))
}

export function finishSimulation()
{
	log.info("finishSimulation!")
	stopFollowing("finishSimulation")
}

function err(e)
{
	log.error(e.getMessage)
}

export function stopFollowing(cause)
{
	store.dispatch(redStateIsFollowing.set(false))
	log.info("stopFollowing. Cause: " + cause)
}

export function startFollowing()
{
	log.info("startFollowing")
	monitorLocation() // wake us up - just in case...
	store.dispatch(redStateIsFollowing.set(true))
	if(lastKnownLocation)
	{
		zoomPanMapToBluedot(lastKnownLocation)
	}
}

// function setHaloRadius(accuracy)
// {
// 	if( accuracy < 8 && (diffAccuracy > 3 || previousAccuracy === 0)) { //high accuracy
// 		greyDotActive = false
// 		errorRadius = 8
// 		fillColor = 0x057CFF
// 		if(timerGreyDot) {
// 			clearTimeout(timerGreyDot)
// 			timerGreyDot = null
// 		}
// 	}
// 	else if(accuracy >= 8 && accuracy < 15 && diffAccuracy > 3) {
// 		greyDotActive = false
// 		errorRadius = 18
// 		fillColor = 0x057CFF
// 		if(timerGreyDot) {
// 			clearTimeout(timerGreyDot)
// 			timerGreyDot = null
// 		}
// 	}
// 	else if(accuracy >= 15 && accuracy < 30 && diffAccuracy > 3) {
// 		greyDotActive = false
// 		errorRadius = 33
// 		fillColor = 0x057CFF
// 		if(timerGreyDot) {
// 			clearTimeout(timerGreyDot)
// 			timerGreyDot = null
// 		}
// 	}
// 	else if(accuracy >= 25 && accuracy < 50 && diffAccuracy > 3) {
// 		greyDotActive = false
// 		errorRadius = 53
// 		fillColor = 0x057CFF
// 		if(timerGreyDot) {
// 			clearTimeout(timerGreyDot)
// 			timerGreyDot = null
// 		}
// 	} else {
// 			errorRadius = 1
// 			fillColor = 0x057CFF
// 			if(!timerGreyDot) {
// 				timerGreyDot = setTimeout(stop, 45000)
// 			}
// 	}
// 	previousAccuracy = accuracy
// }

const trackingReadingsStack = [ ]
window.trackingReadingsStack = trackingReadingsStack
function geoLocationCallback(coords)
{
	log.info("followListener: ", coords)
	const timestamp = Date.now()

	const accuracy = coords.accuracy,
//	const accuracy = 20,
		point = coordsToPoint(coords),
		clFloor = coords.floorLevel,
		heading = coords.heading

	trackingReadingsStack.push([timestamp, coords.latitude, coords.longitude, clFloor, accuracy, heading])
	if(trackingReadingsStack.length > 5000) // if array extends past 5000 enties,
		trackingReadingsStack.splice(0,100) // trim the first 100 entries

	mapsdk.getOrdForCLFloor(clFloor, point)
		.then(ord => {
				lastKnownLocation = { point, accuracy, heading, ord, timestamp }
				store.dispatch(redStateLastLocation.set(lastKnownLocation))
				updateBluedotDeclaritive(true, point, clFloor, heading, accuracy)
				if(store.getState().bluedot.isFollowing)
					zoomPanMapToBluedot(lastKnownLocation)

				if(accuracy === undefined)
					store.dispatch(redStateBluedotType.set(false)) // this turns the dot off.. careful!
				else
					if(accuracy < 15)
						store.dispatch(redStateBluedotType.set("blue"))
					else
						if(accuracy < 250)
							store.dispatch(redStateBluedotType.set("gray"))
						else
							store.dispatch(redStateBluedotType.set(false))

			})
		.catch(e => {  // if outside venue
				stopFollowing("geoLocationCallback catch on " + e.message)
				updateBluedotDeclaritive(false, point, clFloor, heading, accuracy) // this hides the dot..
				store.dispatch(redStateBluedotType.set(false))
			})
}

let bluedotOb = null // used in following function only
const BLUEDOT_PENDING = { }
function updateBluedotDeclaritive(shouldDisplay, point, clFloor, heading, errorRadius)
{
	log.log("updateBluedotDeclaritive", shouldDisplay, point, clFloor, heading, errorRadius)

	// if we are still awaiting the bluedotOb initialization, wait a bit and try again...
	if(bluedotOb && bluedotOb === BLUEDOT_PENDING)
	{
		setTimeout(updateBluedotDeclaritive, 200, shouldDisplay, point, clFloor, heading, errorRadius)
		return
	}

	// if we are already displaying a bluedot...
	if(bluedotOb)
	{
		if(!shouldDisplay)
			bluedotOb.hide()

		// simply update it
		bluedotOb.updatePos(point, clFloor, errorRadius, heading) // function(point, errorRadius, heading) {
	}
	else
	{
		if(shouldDisplay) // here we do not yet have a bluedot object and we need one - so define it
		{
			bluedotOb = BLUEDOT_PENDING
			mapsdk.displayBluedot(point, clFloor)
				.then(bdob => {
					bluedotOb = bdob
					// window.bluedotOb = bdob
					updateBluedotDeclaritive(shouldDisplay, point, clFloor, heading, errorRadius)
				})
		}
	}
}

async function zoomPanMapToBluedot(lastKnownLocation)
{
	mapsdk.displayZoom(85)
	mapsdk.displayPosition(lastKnownLocation.point)
	mapsdk.displayOrdinal(lastKnownLocation.ord)
}
