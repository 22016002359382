import React from "react"
import "./poi-images.pcss"
import { t } from "../../../../../extModules/i18n"

import LoadIndicator from "../../../../common/loadIndicator/LoadIndicator"

function getImage(details, imgLoaded)
{
	return (
		<div>
			<div className="poiImage"
				style={ details.image376URL ? { backgroundImage: `url("${details.image376URL}")` } : { }}
				/>
			<LoadIndicator/>
			<img
				alt=""
				src={ details.image376URL ? details.image376URL : null }
				onLoad={ imgLoaded }
				/>
		</div>
	)
}

const PoiImages = ({ details, imgLoaded }) => {
		return (
			<div className="poi-images">
				{ details.image ? getImage(details, imgLoaded) : <div className="notAvailable">{t("poi:No Image Available")}</div> }
			</div>
		)
	}

export default PoiImages
