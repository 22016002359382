import React from "react"
import { connect } from "react-redux"

import { ripple } from "../../extModules/material"
import { submitLevelsTappedEvent } from "../../extModules/events"
import { t } from "../../extModules/i18n"

import store from "../../store"
import { dStateAreaStruct, dStateArea, dStateBuildingsWithSearchMatches } from "../globalState"

import LevelSelector from "./LevelSelector"
import LevelSelectorTrigger from "./LevelSelectorTrigger"

import { newFloorsLoading } from "../../common/mapLoadingIndicator"

import "./level-selectorContainer.pcss"
import { getVSConfigParm, isSmallDesktop } from "../.."
import * as mapsdk from "../../common/mapsdk"
import { stopFollowing } from "../../common/bluedot";

const ACTION_SET_IS_OPEN = "levelSelector/setIsOpen"
const ACTION_SET_IS_OPEN_BY_SEARCH = "levelSelector/setIsOpenBySearch"
const ACTION_WAKE_UP = "levelSelector/wakeUp"

export function reducer(state = { isOpen: false }, action)
{
	if(action.type === ACTION_SET_IS_OPEN)
		// Note: if this is used to "hard close" the level selector, unset the isOpenBySearch as well (close any soft opens)
		return Object.assign({}, state, { isOpen: action.isOpen, isOpenBySearch: !action.isOpen ? false : state.isOpenBySearch })

	if(action.type === ACTION_SET_IS_OPEN_BY_SEARCH)
		return Object.assign({}, state, { isOpenBySearch: action.isOpenBySearch })

	if(action.type === ACTION_WAKE_UP)
		return Object.assign({}, state)

	return state
}

// Define our actions
export const actionSetIsOpen = isOpen => { return {
		type: ACTION_SET_IS_OPEN,
		isOpen: isOpen
	}}

export const actionSetIsOpenBySearch = isOpen => { return {
	type: ACTION_SET_IS_OPEN_BY_SEARCH,
	isOpenBySearch: isOpen
}}

function wakeUp()
{
	store.dispatch({type: ACTION_WAKE_UP})
}

function areaSelected(node)
{
	const ordinal = parseInt(node.dataset.ordinal, 10),
		positionString = node.dataset.position,
		radius = parseFloat(node.dataset.radius)

	if(positionString)
	{
		const position = positionString.split(",").map(parseFloat)
		mapsdk.displayPosition([position[0], position[1]])
	}

	//store.dispatch(setOrdinal(ordinal))
	newFloorsLoading(mapsdk.displayOrdinal(ordinal))

	if(radius)
	{
		// zoom to about 2 times the radius of the area
		mapsdk.displayZoom(mapsdk.getZoomFromRadius(radius))
	}
}

const close = () => store.dispatch(actionSetIsOpen(false))

class LevelSelectorContainer extends React.Component {
	render() {

		const { isOpen, dispatch, areaStruct, selectedAreaGroup, selectedArea, searchesMatchPerBuilding, isMobileWidth } = this.props

			return (
				<div className="level-selectorContainer">

							<LevelSelectorTrigger
								name={ selectedAreaGroup.name }
								title={ selectedArea ? selectedArea.name : null }
								subtitle={ selectedArea ? selectedArea.title : null }
								openListFn={ e => { submitLevelsTappedEvent(); dispatch(actionSetIsOpen(!isOpen)); ripple(e); stopFollowing() } }
								isOpen={ isOpen }
								isMobileWidth={ isMobileWidth }
								/>

						<LevelSelector
							areaStruct={ areaStruct }
							isOpen={ isOpen }
							selectAreaFn={ node => {
									areaSelected(node)
									if((isMobileWidth || isSmallDesktop()) && !node.classList.contains("areaGroup"))
										close() }
								}
							selectedAreaGroup={ selectedAreaGroup }
							selectedArea={ selectedArea }
							searchesMatchPerBuilding={searchesMatchPerBuilding}
							isMobileWidth={ isMobileWidth }
							closeFn={ close }
							/>
				</div>
			)
		}
	}

const mapStateToProps = state => {

		const areaStructDState = dStateAreaStruct()
				.onDerived(wakeUp)

		const areaDState = dStateArea()
				.onDerived(wakeUp)

		const matchesDstate = dStateBuildingsWithSearchMatches().onDerived(wakeUp)

		const areaStruct = areaStructDState.isCurrent ? areaStructDState.value : null

		// We consider the level selector "open" if it has either been manually opened, OR if it was "soft opened" via search
		// though for mobile width devices, we don't support soft opens
		const isOpen = state.levelSelector.isOpen || (state.levelSelector.isOpenBySearch && !state.global.isMobileWidth)
		const levelSelectorTriggerText = getVSConfigParm("levelSelectorTriggerText", mapsdk.getVenueId(), t("Select A Building"))

		return {
				selectedAreaGroup: areaDState.isCurrent && areaDState.value ? areaDState.value[0] : {name: levelSelectorTriggerText},
				selectedArea: areaDState.isCurrent && areaDState.value ? areaDState.value[1] : null,
				searchesMatchPerBuilding: matchesDstate.isCurrent && matchesDstate.value && !state.search.isNavigationOpen ? matchesDstate.value : null,
				isOpen: isOpen,
				areaStruct,
				isMobileWidth: state.global.isMobileWidth
			}
	}

LevelSelectorContainer = connect(mapStateToProps)(LevelSelectorContainer)

export default LevelSelectorContainer
