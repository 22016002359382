import React from "react"
import "./level-selector.pcss"
import Icon from "../common/icon/Icon"
import RoundLabel from "./roundLabel/RoundLabel"

function renderArea(group, area, selectAreaFn, isSelected, searchesMatchPerBuilding)
{
	return (
		<div
			className={`area text-primary list-item ${isSelected ? "-current" : ""}`}
			key={area.id}
			onClick={ e => selectAreaFn(e.currentTarget) }
			data-ordinal={ area.ord }
			>
			<div className="textLabel">
				<div className="name -titletext text-primary">{area.name}</div>
				<div className="title text-muted">{area.title}</div>
			</div>
			{ searchesMatchPerBuilding && searchesMatchPerBuilding.levels[area.id] ? <RoundLabel count={searchesMatchPerBuilding.levels[area.id]}/> : null }
		</div>
	)
}

// take a list of class strings, strip out any nulls and join using a space
const joinClasses = list => list.filter(x=>x).join(" ")

// TODO: Icon associated with Level Selector must be easily customizable
function renderAreaGroup(group, selectedAreaGroup, selectedArea, selectAreaFn, searchesMatchPerBuilding)
{
	return (
		<div
			className={ "areaGroupWrapper " + (selectedAreaGroup === group ? " -selected bg-alt" : "") }
			key={ group.id }
			>
			<div
				className={
					joinClasses(
						[
							"areaGroup",
							searchesMatchPerBuilding && searchesMatchPerBuilding.buildings[group.id] ? "pois-found" : null,
							selectedAreaGroup === group ? "bg-active-expandable-list-item text-active-expandable-list-item" : "bg-primary-expandable-list-item text-primary-expandable-list-item"
						])
					}
				onClick={e => selectAreaFn(e.currentTarget) }
				data-ordinal={ group.defaultOrdinal }
				data-position={ group.geometry.center.toString() }
				data-radius={ group.geometry.radius }
				>
				<div className="textLabel">
					<div className="name text-secondary"> {group.name} </div>
					<div className="desc text-muted"> { group.title } </div>
				</div>
				{searchesMatchPerBuilding && searchesMatchPerBuilding.buildings[group.id] ? <RoundLabel count={searchesMatchPerBuilding.buildings[group.id]}/> : null }
			</div>
			{ group.areas.map(area => renderArea(group, area, selectAreaFn, selectedArea === area, searchesMatchPerBuilding)) }
		</div>
	)
}

// area can be null - areaGroup at least contains { name }
const LevelSelector = ({isOpen, areaStruct, selectedAreaGroup, selectedArea, selectAreaFn, searchesMatchPerBuilding, isMobileWidth, closeFn }) => {

		let outerClasses = ["level-selector", "-floatingContainer", "-uparrow", "bg-primary"]

		if(isMobileWidth)
			outerClasses.push("-animateUp")
		else
			outerClasses.push("-animateDown")

		if(isOpen)
			outerClasses.push("-show")

		return (
				<div className={outerClasses.join(" ")}>
					{ isMobileWidth ? <div className="mask-mobile" onClick={ closeFn }/> : null }
					{ isMobileWidth ? <div className="closeMobile bg-primary" onClick={ closeFn }><Icon name="search-icon-show"/></div> : null }
					<div className="areaGroups">
						{ areaStruct ? areaStruct.map(group => renderAreaGroup(group, selectedAreaGroup, selectedArea, selectAreaFn, searchesMatchPerBuilding)) : null }
					</div>
				</div>
			)
		}

export default LevelSelector
