import Zousan from "zousan"

// vendor imports
import * as moment from "moment"
import "react-app-polyfill/ie11"

// Standard Imports
import icons from "./components/common/icon/icons"
import { addMessageListener } from "./common/apiServer"
import { renderError } from "./reactRoot"
import { $$, dynaload, dynaloadcss } from "./extModules/domTools"

import { initDebugKeyListener } from "./common/debugTools"
import { renderReactRoot } from "./reactRoot"
import { extend, parseQuery } from "./common/utilities"
import * as i18n from "./extModules/i18n"
import translations from "./translations.json"
import * as locuslabs from "locuslabs-js-sdk"
// import * as locuslabs from "./locuslabs-js-sdk"

// const translations = require("translations.json")

export const global = window	// Use this over window directly (or global properties directly)
export const isSmallDesktop = () => global.innerWidth <= 1112	/* 1112 is iPad Pro 10.5" */

let config = { }	// to be replaced by configuration

// Note: this is synchronous and if called too early may not contain the actual config
export const getConfig = () => config

// Supports both name-venueId and name only config settings
export function getVSConfigParm(name, venueId, defaultValue)
{
	let value = config[name + "-" + venueId]
	if(value === undefined)
		value = config[name]
	if(value === undefined)
		value = defaultValue
	return value
}

export function getConfigParm(name, defaultValue)
{
	const value = getConfig()[name]
	if(value !== undefined && value !== null)
		return value
	return defaultValue
}

export function getLangSpecificConfigParm(name, defaultValue)
{
	const lang = i18n.getCurrentLanguage()
	return getLangSpecificConfigParmWithFallbacks(name, lang, defaultValue)
}

const upToLast = search => str =>
	str.indexOf(search) >= 0
	?	str.substring(0, str.lastIndexOf(search))
	: ""

function getLangSpecificConfigParmWithFallbacks(name, lang, defaultValue)
{
	let value = getConfigParm(`${name}-${lang}`)
	if(value !== undefined && value !== null)
		return value
	if(lang.indexOf("-") > 0) // if this language has multiple subtags, strip off last subtag and try again
		return getLangSpecificConfigParmWithFallbacks(name, upToLast("-")(lang), defaultValue)
	return getConfigParm(name, defaultValue)
}
window.getLangSpecificConfigParm = getLangSpecificConfigParm

global.locuslabs = locuslabs // this happens inside locuslabs repository - but prob. shouldn't
global.locuslabs.setIcons(icons)

function initApp(rootElement)
{
		// use the i18n.setLanguage / i18n.getLanguage as the canonical language
		// setting for this project. We need this perculateLangChange for the non-canonical
		// modules that access langauge in non-canonical ways
		const perculateLangChange = lang => {
			moment.locale(lang)
			global.locuslabs.config.language = lang
			if(global.locuslabs.i18n)
				global.locuslabs.i18n.changeLanguage(lang)
		}

		// if "lang" is present in the URL, use it as a language override
		let lang = getConfigParm("lang") // use lang param in config, or as data-lang attribute on map div
		const overrideLang = parseQuery(window.location.search).lang
		if(overrideLang)
			lang = overrideLang
		if(lang)
			perculateLangChange(lang)
		i18n.init(translations, lang)
			.on("languageChanged", perculateLangChange)

		// translate any attr-based i18n (such as std. template header)
		$$(".i18n-section").forEach(i18n.attrBasedI18n)

		if(!global.locuslabs)
			throw Error("locuslabs SDK not found")

		if(!global.locuslabs.JSW_config)
			throw Error("JSW_config not defined.")

		config = global.locuslabs.JSW_config

		if(config.debug)
			initDebugKeyListener()

		if(!rootElement)
			throw Error("root element must be defined with id #root")

		renderReactRoot(rootElement, config)

		const locuslabsElement = document.querySelector(".LocusLabs")

		if(!locuslabsElement)
			throw Error("A problem occured during rendering. No .LocusLabs element found.")

		// If we aren't running in debug mode or locally, disable right-click within LocusLabs
		if(!(config.debug || window.location.hostname === "localhost"))
			locuslabsElement.oncontextmenu = () => false

		return config
}

export function loadConfig(configJsonURL)
{
	return fetch(configJsonURL)
		.then(response => response.json())
		.catch(err => {
				console.log(`Error loading config file from '${configJsonURL}': `, err)
				return err
			})
}

// Should probably refactor this out of index at some point
export function loadConfigOld(configURL)
{
	return dynaload(configURL)
		.then(() => {
				if(!global.locuslabs.JSW_config)
					throw Error("Unable to load " + configURL)
				return global.locuslabs.JSW_config
		})
		.catch(err => {
			console.log("Error loading config file", err)
			return err
		})
}

function renderMap(node)
{
	if(config.fullpage !== undefined)
	{
		document.body.classList.add("-LLfullpage")
		document.body.parentElement.style.height = "100%" // set html element to max height
	}
	else
	{
		if(config.height)
			node.style.height = config.height + "px"
		if(config.width)
			node.style.height = config.width + "px"
	}

	initApp(node)
}

const DEFAULT_CONFIG = { assetsBase: "https://assets.locuslabs.com/accounts/", assetsFormatVersion: "v4", platformSdk: "MOLv4" }
function scanForMapDivs()
{
	$$(".locusmaps").forEach(mapDiv => {

			const ds = mapDiv.dataset
			return Zousan.evaluate(
					// initialize the config with config.js if specified, else {}
					{ name: "defaultConfig", value: DEFAULT_CONFIG },

					// Now check if there is an accountUuid specified (attribute data-account-uuid) - in which case, load that config and custom.css
					{ name: "newConfig", deps: ["defaultConfig"], value: cfg => ds.accountUuid ?
								loadConfig("https://maps.locuslabs.com/" + ds.accountUuid + "/config.json")
									.then(cfg2 => { dynaloadcss("https://maps.locuslabs.com/" + ds.accountUuid + "/custom.css"); return extend(cfg, cfg2) }) :
								cfg
						},

					// Then, take the default config and extend/override with data-config file (if it exists)
					{ name: "newConfig2", deps: ["newConfig"], value: cfg => ds.config ?
								loadConfig(ds.config).then(cfg2 => extend(cfg, cfg2)) :
								cfg
						},

					// Finally, use the dataset attributes to extend/override all of the above using - notation in place of camelcase - so suggested-searches in place of suggestedSearches)
					{ name: "dataset", deps: [ "newConfig2" ], value: cfg => extend(cfg, ds)})
				.then(ob => {
					config = ob.newConfig
					global.locuslabs.JSW_config = config // eslint-disable-line
					if(config.icons)
						window.locuslabs.setIcons(config.icons)
					renderMap(mapDiv)
				})
				.catch(err => {
					console.error(err) // eslint-disable-line
					renderError(err, mapDiv)
				})
			})
}

scanForMapDivs()
addMessageListener()
