import Zousan from "zousan"

function create(calc, getDeps)
{
	const NONE = {}
	let memDeps = NONE, // initialize lastDeps to an identifyable start state
		memDevOb = undefined // track the current memoized devaluate object here

	// pure function which returns an array of resolved deps
	function resolveDeps(d)
	{
		d = d || []

		// If the dependencies are offered through a function, get them
		if(typeof d === "function")
			d = d()

		// examine each dependency and "resolve" it if necessary
		d = d.map(x => {

				// If any dep IS a function, call it and use return value
				if(x && typeof x === "function")
					x = x()

				// For async devaluate dependencies, resolve them to their corresponding promise
				// this will remain consistent for that devaluate as long as its dependencies are unchanged
				if(x && x.isAsync && x.promise)
					x = x.promise

				// Synchronous devaluates have no promise, but they have their value, so resolve to it
				if(x && x.isAsync === false && x.value)
					x = x.value

				return x
			})

		return d
	}

	// devaluate instances become entries to this function (this is returned on calls to devaluate(...))
	// We always return a "DevOb" (devaluate object) which is a native Object with these properties:
	// 	isAsync: <boolean>
	// 	isCurrent: <boolean>
	// 	value: <Any>
	//	lastValue: <Any> when not current, this holds the most recently known derived value (if any)
	// 	promise: <Promise> - resolves to the final derived value
	//	onDerived: (fn) - passed function is called when the value is determined
	//
	// () -> <DevOb>
	function get()
	{
		// First, resolve the dependencies - we need their values to pass into calc function
		let deps = resolveDeps(getDeps)

		// If there has been no changes to the dependencies since last call, we just return the memoized memDevOb
		const repeatDeps = memDeps !== NONE && arrayIsEqual(deps, memDeps)

		if(repeatDeps)
			return memDevOb

		// We can't use memoized value, so evaluate ourselves...
		const devOb = evaluateMe(deps)

		// If we evaluate to a current value, we become the new memoized value
		//if(devOb.isCurrent)
			memoize(deps, devOb)

		return devOb
	}

	function evaluateMe(deps)
	{
		// extract any promises from the dev list into an array and resolve them
		var depPromList = deps.filter(x => x && x.then && typeof x.then === "function")
		if(depPromList.length) // did we find any promises?
		{
			const valueProm = Zousan.all(deps)
					.then(resDeps => calc.apply(undefined, resDeps))

			return makeDevObAgainstPromise(
						deps,
						valueProm,
						memDevOb ? memDevOb.value : null
					)
		}

		// this gets executed only when there were no promises in the dependencies. It creates the
		// DevOb immediately and returns it
		return doCalc(deps)
	}

	// Called once all deps have been resolved and we are ready to calculate the derived value
	function doCalc(deps)
	{
		// Calculate the new value (while keeping existing value around for async return object)
		const newValue = calc.apply(undefined, deps)

		// if this is an async derived value (i.e. the return is a promise)
		if(newValue && newValue.then && typeof newValue.then === "function")
			return makeDevObAgainstPromise(deps, newValue, memDevOb ? memDevOb.value : null)

		// For non-asynchronous values...
		const devOb = { // create a syncrhonous DevOb
				isAsync: false,
				isCurrent: true,
				value: newValue
			}

		// for synchronous values we don't call the onDerived callback
		devOb.onDerived = (fn) => devOb
		return devOb
	}

	function memoize(deps, value)
	{
		memDeps = deps
		memDevOb = value
	}

	// deps can be specified either as a promise or a literal array
	function makeDevObAgainstPromise(deps, prom, lastValue)
	{
		const devOb = {
			isAsync: true,
			lastValue: lastValue,  // if we have a previously defined value, return that as "most recently known" state
			isCurrent: false // but set isCurrent to false to indicate it is not current
		}

		// When the async value resolves, replace the memoized object as long as the deps haven't changed
		devOb.promise = prom.then(v => {

				const newDevOb = Object.assign({}, devOb, {value: v, isCurrent: true,
					onDerived: () => newDevOb })

				if(arrayIsEqual(memDeps, deps))
					memoize(deps, newDevOb)

				return v
			})

		devOb.onDerived = (fn) => { devOb.promise.then(fn); return devOb }
		return devOb
	}

	return get
}

// does shallow compare of two arrays - assumes arrays are of same size
function arrayIsEqual(a1, a2)
{
	return a1.reduce((p, c, i) =>
		c !== a2[i] ? false : p
		, true)
}

export default create
