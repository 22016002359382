import React from "react"

import {t} from "../../../../extModules/i18n"

const ParkingStatus = ({details}) => (
	details.lotStatus.toLowerCase() === "closed"
		? <div className={"round-label bg-gray text-secondary _textOneLine"}>{t("common:Closed")}</div>
		: details.lotStatus.toLowerCase() === "open"
			? <div className={"round-label bg-green text-important _textOneLine"}>{t("poi:Open")}</div>
			: details.lotStatus.toLowerCase() === "full"
				? <div className={"round-label bg-red text-important _textOneLine"}>{t("poi:Full")}</div>
				: details.lotStatus.toLowerCase() === "near"
					? <div className={"round-label bg-orange text-important _textOneLine"}>{t("poi:Nearing Capacity")}</div>
					: null
)

export default ParkingStatus