// React stuff
import React from "react"
import { connect } from "react-redux"

import { ripple } from "../../../extModules/material"
import { submitPoiNavigationTappedEvent, submitPoiTagTappedEvent, submitPoiViewedEvent } from "../../../extModules/events"
import store from "../../../store"

// All our state management
import { setShowMapOverride, getDerivedPOIDetails } from "./poiState"
import { closePOI as setGlobalPOIUndefined } from "../../globalState"
// These are our subcomponents
import PoiTitle from "./poiTitle/PoiTitle"
import PoiDetails from "./poiDetails/PoiDetails"
import { showNavigationDialogAC, setNewConfirmedSearchTerm, wakeUp } from "../SearchContainer";
import { setPoi2, setPoi1Focus, closeNavigation } from "../navigation/NavigationDialog"
import Icon from "../../common/icon/Icon"

// Finally, grab our CSS for webpack
import "./poi-container.pcss"

function imgLoaded(e)
{
	let cover = e.target.previousElementSibling
	cover.style.opacity = 0
}

function getDirectionsClicked(e)
{
	if(store.getState().navigation.navigationOnGoing) closeNavigation()
	let currentPoiId = store.getState().global.poiId
	submitPoiNavigationTappedEvent(currentPoiId)
	ripple(e)
	store.dispatch(setPoi2(currentPoiId))

	store.dispatch(showNavigationDialogAC(true))

	setPoi1Focus(true)
	setTimeout(() => closePOI(), 100) // delay a bit to show effect..
}

function closePOI()
{
	if(store.getState().navigation.navigationOnGoing)
		store.dispatch(showNavigationDialogAC(true))
	store.dispatch(setGlobalPOIUndefined())
}

function displayTagClicked(e)
{
	let currentPoiId = store.getState().global.poiId
	submitPoiTagTappedEvent(currentPoiId)
	const searchTerm = e.currentTarget.dataset.displaytag
	ripple(e)
	setTimeout(() => {
		closePOI()
		setNewConfirmedSearchTerm(searchTerm)
	}, 100) // delay a bit to show effect..
}

const toggleActive = e => e.currentTarget.classList.toggle("-active")

const renderDetails = (dispatch, details, isMobileWidth) => {
		let currentPoiId = store.getState().global.poiId
		submitPoiViewedEvent(currentPoiId)
		return (
		<div className={ "poi-container bg-primary" + (isMobileWidth ? " -mobileFull" : " -floatingContainer -mainDialog -extraWide") }>
			<PoiTitle
				details={ details }
				closeFn={ closePOI }
				isMobileWidth={ isMobileWidth }
				/>
			<PoiDetails
				details={ details }
				imgLoaded={ imgLoaded }
				getDirectionsClicked={ getDirectionsClicked }
				displayTagClicked={ displayTagClicked }
				operatingHoursClicked={ toggleActive }
				/>
			{ isMobileWidth
				? (
					<div className="button -full bg-accent text-primary-button" onClick={ () => dispatch(setShowMapOverride(true)) }>
						<Icon name="view-location-on-map" />
						View Location On The Map
				</div> )
				: null }
		</div>
		)}

const showPoiDetailsShowMapReturnHeader = (dispatch, details ) => (
		<div className="poi-showmap-return-header bg-primary">
			<PoiTitle
				details={ details }
				closeFn={ () => dispatch(setShowMapOverride(false)) }
				isMobileWidth={ true }
				/>
		</div>
	)

let PoiContainer = ({ dispatch, details, isMobileWidth, isShowMapOverride }) => {
	if(!details) // wait until details resolve..
		return null

	if(isMobileWidth && isShowMapOverride)
		return showPoiDetailsShowMapReturnHeader(dispatch, details)

	return renderDetails(dispatch, details, isMobileWidth)
}
/**
 * Given the poi details record, return the poi icon suffix such that the badge icon may be derived from "poi-badge-" + getIconSuffix()
 * @param  {} details
 */
export function getIconSuffix(details)
{
	const iconString = details.icon ? details.icon : ""

	// This is a mess!  See https://app.asana.com/0/267763484084518/1108650737031780/f  for more details
	// Lets work towards removing this "logic".  :-}
	return iconString.includes("querywin-icon-") && String(iconString).includes("@2x.png")
		? iconString.replace(/querywin-icon-/,"").replace(/@2x\.png/,"")
		: iconString.includes("querywin-badge-") && String(iconString).includes("@2x.png")
			? iconString.replace(/querywin-badge-/,"").replace(/@2x\.png/,"")
			: details.category
}

const mapStateToProps = state => {

	const details = getDerivedPOIDetails()

	const isSec = details && details.additionalAttributes && details.additionalAttributes.isSecurityCheckpoint
	const secWaitTime = isSec ? details.time : null

	if(isSec)
	{
		if(!secWaitTime)
			setTimeout(wakeUp, 1000)
		else
			setTimeout(wakeUp, 1000 * 30)
	}

	return {
		details,
		imgLoaded,
		secWaitTime,
		getDirectionsClicked,
		isMobileWidth: state.global.isMobileWidth,
		isShowMapOverride: state.poi.showMapOverride
	}
}

PoiContainer = connect(mapStateToProps)(PoiContainer)

export default PoiContainer
