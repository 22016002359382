import React, { PureComponent } from "react"
import PropTypes from "prop-types"

import { log as glog } from "../../../../components/globalState"
import KeyboardButton from "./KeyboardButton"
import "./Keyboard.pcss"

const log = glog.sublog("onscreen-keyboard")

const BackspaceIcon = ({ viewBox = "0 0 24 24", width = 24, height = 24, fill }) =>
	<svg {...{ width, height, fill, viewBox }}>
		<path d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-3 12.59L17.59 17 14 13.41 10.41 17 9 15.59 12.59 12 9 8.41 10.41 7 14 10.59 17.59 7 19 8.41 15.41 12 19 15.59z" />
	</svg>

const ShiftIcon = ({ viewBox = "0 0 32 32", width = 24, height = 24, fill }) =>
	<svg {...{ width, height, fill, viewBox }}>
		<path d="M21 28h-10c-0.552 0-1-0.448-1-1v-11h-4c-0.404 0-0.769-0.244-0.924-0.617s-0.069-0.804 0.217-1.090l10-10c0.391-0.39 1.024-0.39 1.414 0l10 10c0.286 0.286 0.372 0.716 0.217 1.090s-0.519 0.617-0.924 0.617h-4v11c0 0.552-0.448 1-1 1zM12 26h8v-11c0-0.552 0.448-1 1-1h2.586l-7.586-7.586-7.586 7.586h2.586c0.552 0 1 0.448 1 1v11z" />
	</svg>

const SYMBOLS_LAYOUT = [
	["=", "+", "%", "*", "[", "]", "{", "}", "<", ">"],
	["@", ":", ";", "_", "-", "#", "(", ")", "/", "\\"],
	[".", ",", "?", "!", "'", "\"", "^"]
]

const LETTER_LAYOUT = [
	["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
	["a", "s", "d", "f", "g", "h", "j", "k", "l"],
	["z", "x", "c", "v", "b", "n", "m"]
]

export default class Keyboard extends PureComponent {
	static propTypes = {
		inputNode: PropTypes.any.isRequired,
		onClick: PropTypes.func,
		isFirstLetterUppercase: PropTypes.bool,
		uppercaseAfterSpace: PropTypes.bool,
		hideKeyboard: PropTypes.func,
		handleSubmit: PropTypes.func,
		opacity: PropTypes.number,
		dataset: PropTypes.any,
		keyboardClassName: PropTypes.any,
		showNumericRow: PropTypes.bool,
		showShift: PropTypes.bool,
		showSymbols: PropTypes.bool,
		showSpacebar: PropTypes.bool,
		showSubmit: PropTypes.bool
	};

	static defaultProps = {
		rightButtons: [],
		isFirstLetterUppercase: false,
		uppercaseAfterSpace: false,
		dataset: { type: "input" },
		opacity: 1,
		showNumericRow: true,
		showShift: true,
		showSymbols: true,
		showSpacebar: true,
		showSubmit: true
	};

	constructor(props) {
		super(props)
		this.state = {
			showSymbols: false,
			uppercase: this.isUppercase()
		}
	}

	getKeys = () => {
		const keysSet = this.state.showSymbols ? SYMBOLS_LAYOUT : LETTER_LAYOUT
		return this.state.uppercase ?
			keysSet.map(keyRow => keyRow.map(key => isFinite(key) ? key : key.toUpperCase()))
			: keysSet
	}

	getSymbolsKeyValue = () => {
		return !this.state.showSymbols ? ".?!&" : "Abc"
	}

	clearInput = () => {
		const { inputNode } = this.props

		inputNode.value = ""
		if(this.props.onClick) {
			this.props.onClick("")
		}

		setTimeout(() => {
			inputNode.focus()
		}, 0)
		inputNode.dispatchEvent(new CustomEvent("input"))
	}

	handleShiftClick = () => {
		this.setState({ uppercase: !this.state.uppercase })
	}

	handleSymbolsClick= () => {
		this.setState({ showSymbols: !this.state.showSymbols })
	}

	handleLetterButtonClick = (key) => {
		const { inputNode } = this.props
		const { value } = inputNode
		let selectionStart
		let selectionEnd
		try {
			selectionStart = inputNode.selectionStart
			selectionEnd = inputNode.selectionEnd
		} catch(e) {
			selectionStart = value.length
			selectionEnd = value.length
		}
		const nextValue = value.substring(0, selectionStart) + key + value.substring(selectionEnd)

		inputNode.value = nextValue
		if(this.props.onClick) {
			this.props.onClick(nextValue)
		}
		setTimeout(() => {
			inputNode.focus()
			try {
				const offset = !isFinite(key) ? key.length : 1
				inputNode.setSelectionRange(selectionStart + offset, selectionStart + offset)
			} catch(e) {
				log.error(e)
			}
		})
		this.setState({ uppercase: this.isUppercase() })
		inputNode.dispatchEvent(new CustomEvent("input"))
	}

	handleDragKeyClick = () => {
		const { inputNode } = this.props
		setTimeout(() => {
			inputNode.focus()
		}, 0)
	}

	isUppercase= () => {
		const { inputNode, isFirstLetterUppercase, uppercaseAfterSpace, dataset } = this.props
		return inputNode.type !== "password" &&
			dataset.type !== "email" &&
			((!inputNode.value.length && isFirstLetterUppercase) || (inputNode.value.length > 0 &&
			inputNode.value[inputNode.value.length - 1] === " " && uppercaseAfterSpace))
	}

	handleBackspaceClick = () => {
		const { inputNode } = this.props
		const { value } = inputNode
		let selectionStart
		let selectionEnd
		try {
			selectionStart = inputNode.selectionStart
			selectionEnd = inputNode.selectionEnd
		} catch(e) {
			selectionStart = 0
			selectionEnd = value.length
		}

		let nextValue
		let nextSelectionPosition
		if(selectionStart === selectionEnd) {
			nextValue = value.substring(0, selectionStart - 1) + value.substring(selectionEnd)
			nextSelectionPosition = selectionStart - 1
		} else {
			nextValue = value.substring(0, selectionStart) + value.substring(selectionEnd)
			nextSelectionPosition = selectionStart
		}
		nextSelectionPosition = (nextSelectionPosition > 0) ? nextSelectionPosition : 0

		inputNode.value = nextValue
		if(this.props.onClick) {
			this.props.onClick(nextValue)
		}
		setTimeout(() => {
			inputNode.focus()
			try {
				inputNode.setSelectionRange(nextSelectionPosition, nextSelectionPosition)
			} catch(e) {
				log.error(e)
			}
		}, 0)
		this.setState({ uppercase: this.isUppercase() })
		inputNode.dispatchEvent(new CustomEvent("input"))
	}

	getCharacterClassName = (letter) => {
		const char = `${letter}`
		return char.length > 1 ? "" : `keyboard-key-${char.charCodeAt(0)}`
	}

	render = () => {
		const { inputNode } = this.props
		const keys = this.getKeys()
		const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
		const symbolsKeyValue = this.getSymbolsKeyValue()

		return (
			<div
				className={`keyboard keyboard-wrapper ${typeof (this.props.keyboardClassName) !== "undefined" ? this.props.keyboardClassName : ""}`}
				style={{ opacity: `${this.props.opacity}` }}
			>
				{this.props.showNumericRow ?
				<div className="keyboard-row">
					{numbers.map(button =>
					<KeyboardButton
						value={button}
						onClick={this.handleLetterButtonClick}
						classes={`keyboard-numberButton ${this.getCharacterClassName(button)}`}
						key={button}
					/>,
					)}
					<KeyboardButton
						classes="backspace-button"
						value={<BackspaceIcon />}
						onClick={this.handleBackspaceClick}
					/>
				</div> : null}

				{keys.map((row, i) =>
				<div key={`r${i}`} className="keyboard-row">
					{keys.length === i + 1 && this.props.showShift &&
					<KeyboardButton
						classes="shift-symbols"
						value={<ShiftIcon />}
						onClick={this.handleShiftClick}
					/>
					}
					{row.map((button, ii) => {
					switch (button.toLowerCase()) {
						case "*bs":
						return (
							<KeyboardButton
								classes="backspace-button"
								value={<BackspaceIcon />}
								onClick={this.handleBackspaceClick}
								key={`b${ii}`}
							/>
						)

						case "*sh":
						return (
							<KeyboardButton
								classes="shift-symbols"
								value={<ShiftIcon />}
								onClick={this.handleShiftClick}
								key={`b${ii}`}
							/>
						)

						default:
						return (
							<KeyboardButton
								value={button}
								classes={this.getCharacterClassName(button)}
								onClick={this.handleLetterButtonClick}
								key={`b${ii}`}
							/>
						)
					}
					}
					)}

					{keys.length === i + 1 && this.props.showSymbols &&
					<KeyboardButton
						classes="shift-symbols"
						value={symbolsKeyValue}
						onClick={this.handleSymbolsClick}
					/>
					}
				</div>,
				)}

				<div className="keyboard-row">
				{inputNode.dataset.type === "email" ?
					<KeyboardButton
						value={"@"}
						onClick={this.handleLetterButtonClick}
					/>
					: null}
				{this.props.showSpacebar ?
					<KeyboardButton
						value={" "}
						classes="keyboard-space"
						onClick={this.handleLetterButtonClick}
					/>
					: null}
				{inputNode.dataset.type === "email" ?
					<KeyboardButton
						value={"."}
						onClick={this.handleLetterButtonClick}
					/>
					: null}
				{this.props.showSubmit ?
					<KeyboardButton
						value={String.fromCharCode("8629")}
						classes="keyboard-submit-button"
						onClick={this.props.handleSubmit}
					/>
				: null}
				</div>
			</div>
		)
	}
}
