import { $ } from "../extModules/domTools"

const toggleOutlines = () => $(".LocusLabs").classList.toggle("-designMode")

function doubleText(e)
{
	e.childNodes.forEach(e2 => {
			if(e2.nodeType === 1) // element type
				doubleText(e2)
			if(e2.nodeType === 3) // text type
				e2.textContent = e2.textContent + " " + e2.textContent
		})
}

export function initDebugKeyListener()
{
	document.addEventListener("keydown", e => {

			if(e.metaKey)
			{
				if(e.keyCode === 79) // meta-o
					toggleOutlines()
				else
					if(e.keyCode === 68) // meta-d
						doubleText(document.querySelector(".LocusLabs"))
					else
						return

				e.preventDefault()
			}

		})
}