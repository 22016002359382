import React from "react"
import Icon from "../../common/icon/Icon"
import "./message.pcss"

const Message = ({title, advice, icon}) =>
	<div className="message">
		<div className="sorryMsg">
			<Icon name={icon} />
			<div className="textLabel">
				<div className="title">{title}</div>
				<div className="desc text-muted">{advice}</div>
			</div>
		</div>
	</div>

export default Message
