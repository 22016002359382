import React from "react"
import store from "../../store"
import { connect } from "react-redux"

import { cond } from "../../common/utilities"

import FlightStatusEntry from "./FlightStatusEntry"
import FlightStatusList from "./FlightStatusList"
import "./flight-status-container.pcss"
import Flight from "./Flight"

import { getFlights } from "./FlightStatusClientHandler"
import { setShowDirections } from "../search/SearchContainer"

import { getConfig } from "../../index"

import MinimizeButton from "../common/minimizeButton/MinimizeButton"
import Message from "../search/message/Message"

import * as mapsdk from "../../common/mapsdk"

import { t } from "../../extModules/i18n"
import Icon from "../common/icon/Icon";

var flightsArray = [];
var departedFlights = [];
var soonToDepartFlights = [];
var filteredDepartedFlights = [];
var filteredSoonToDepartFlights = [];
var flightsList = [];
var airlines = [];
var airports = [];

const ACTION_SET_FLIGHT_STATUS = "flightStatus/setFlightStatus"
const ACTION_SET_FLIGHT_DETAIL_STATUS = "flightStatus/setFlightDetailStatus"
const ACTION_FLIGHT_STATUS_CONTAINER_OPEN = "flightStatus/setIsFlightStatusContainerOpenAction"
const ACTION_SET_FLIGHT_SEARCH_TERM = "flightStatus/setFlightSearchStatus"
const ACTION_FLIGHT_WAKE_UP = "flightStatus/wakeUp"
const ACTION_ARRIVALS_ACTIVE = "flightStatus/setIsArrivalsActive"
const ACTION_SET_FLIGHT = "flightStatus/flightElement"
const ACTION_FLIGHTS_LOADING = "flightStatus/flightsLoading"
const ACTION_FLIGHTS_TRUNCATE = "flightStatus/truncateResults"

export function reducer(state = {}, action)
{
	if(action.type === ACTION_SET_FLIGHT_STATUS)
		return Object.assign({}, state, { isFlightStatusActive: action.isFlightStatusActive })
	if(action.type === ACTION_FLIGHT_WAKE_UP)
		return Object.assign({}, state)
	if(action.type === ACTION_SET_FLIGHT_DETAIL_STATUS)
		return Object.assign({}, state, { isFlightDetailActive: action.isFlightDetailActive })
	if(action.type === ACTION_FLIGHT_STATUS_CONTAINER_OPEN)
		return Object.assign({}, state, {isFlightStatusContainerOpen: action.isFlightStatusContainerOpen})
	if(action.type === ACTION_SET_FLIGHT_SEARCH_TERM)
		return Object.assign({}, state, {term: action.term})
	if(action.type === ACTION_ARRIVALS_ACTIVE)
		return Object.assign({}, state, {isArrivalsActive: action.isArrivalsActive})
	if(action.type === ACTION_SET_FLIGHT)
		return Object.assign({}, state, {flight: action.flight})
	if(action.type === ACTION_FLIGHTS_LOADING)
		return Object.assign({}, state, {isLoading: action.isLoading})
	if(action.type === ACTION_FLIGHTS_TRUNCATE)
		return Object.assign({}, state, {truncate: action.truncate})

	return state
}

export const setFlightSearchTerm = (term) => { return {
	type: ACTION_SET_FLIGHT_SEARCH_TERM,
	term: term
}}

export const setFlightStatus = (isFlightStatusActive) => { return {
	type: ACTION_SET_FLIGHT_STATUS,
	isFlightStatusActive: isFlightStatusActive
}}

export const setFlightDetailStatus = (isFlightDetailActive) => { return {
	type: ACTION_SET_FLIGHT_DETAIL_STATUS,
	isFlightDetailActive: isFlightDetailActive
}}

export const setFlightElement = (flight) => { return {
	type: ACTION_SET_FLIGHT,
	flight: flight
}}

export const setIsFlightStatusContainerOpenAction = (isOpen) => { return {
	type: ACTION_FLIGHT_STATUS_CONTAINER_OPEN,
	isFlightStatusContainerOpen: isOpen
}}

export const setLoadingFlights = (isLoading) => { return {
	type: ACTION_FLIGHTS_LOADING,
	isLoading: isLoading
}}

export const setTruncateResults = (truncate) => { return {
	type: ACTION_FLIGHTS_TRUNCATE,
	truncate
}}

const setIsArrivalsActive = (isArrivalsActive) => { return {
	type: ACTION_ARRIVALS_ACTIVE,
	isArrivalsActive: isArrivalsActive
}}

function getCurrentFlights(typeFlights) {
	const airport = getConfig().venueId.toUpperCase()
	const type = typeFlights //arr for arrivals or dep for departures
	const date = new Date()
	const year = date.getUTCFullYear()
	const month = date.getUTCMonth() + 1
	const day = date.getUTCDate()
	const hour = date.getUTCHours()
	const maxHours = 2
	const maxFlights = 20
	const extendedOptions = "includeDeltas"

	return getFlights(airport, type, year, month, day, hour, maxHours, maxFlights, extendedOptions)
}

function sortByHour(flight1, flight2) {
	return flight1.getSortDate() - flight2.getSortDate()
}

var gates;

export function startFlightStatus()
{
	store.dispatch(setIsFlightStatusContainerOpenAction(true))
	store.dispatch(setLoadingFlights(true))
	store.dispatch(setTruncateResults(true))
	filteredDepartedFlights = []
	filteredSoonToDepartFlights = []
	mapsdk.search("gate", true).then(suggestedGates => {
		gates = suggestedGates
		getAllFlightStatus("dep")
	})
}

function getAllFlightStatus(type)
{
	store.dispatch(setLoadingFlights(true))
	store.dispatch(setTruncateResults(true))
	filteredDepartedFlights = []
	filteredSoonToDepartFlights = []

	if(!store.getState().flightStatus.isFlightStatusContainerOpen)
		store.dispatch(setIsFlightStatusContainerOpenAction(true))
	store.dispatch(setIsArrivalsActive(type === "arr"))

	getCurrentFlights(type).then(flightsData => {
		// window.flightsData = flightsData
		if(flightsData) {
			flightsList = flightsData.flights;
			airlines = flightsData.airlines
			airports = flightsData.airports

			flightsArray = [];
			// window.flightsArray = flightsArray
			for(var i = 0; i < flightsList.length; i++) {
				try {
						flightsArray.push(new Flight(flightsList[i], type.toUpperCase(), airports, airlines, gates));
				} catch(err) {
					console.warn("Error constructing flight", err, flightsList[i])
				}
			}

			departedFlights = type === "arr" ? flightsArray.filter(flight => flight.hasArrived()) : flightsArray.filter(flight => flight.hasDeparted())
			soonToDepartFlights = type === "arr" ? flightsArray.filter(flight => !flight.hasArrived()) : flightsArray.filter(flight => !flight.hasDeparted());

			filteredDepartedFlights = departedFlights;
			filteredSoonToDepartFlights = soonToDepartFlights;

			filteredDepartedFlights.sort(sortByHour)

			filteredSoonToDepartFlights.sort(sortByHour)

			updateFlightSearchTerm(store.getState().flightStatus.term || "")
			store.dispatch(setLoadingFlights(false));
		}
	})
}

export const wakeUp = (delay = 0) => {
	setTimeout(() => store.dispatch({
	type: ACTION_FLIGHT_WAKE_UP
}), delay)}

function updateFlightSearchTerm(searchTerm)
{
	store.dispatch(setFlightSearchTerm(searchTerm))
	searchTerm = searchTerm.toLowerCase().trim()
	store.dispatch(setIsFlightStatusContainerOpenAction(true))

	filteredSoonToDepartFlights = soonToDepartFlights.filter(flight => flight.search.includes(searchTerm))
	filteredDepartedFlights = departedFlights.filter(flight => flight.search.includes(searchTerm))
}

function closeSearch()
{
	updateFlightSearchTerm("")
	if(!store.getState().flightStatus.isFlightStatusContainerOpen)
		store.dispatch(setIsFlightStatusContainerOpenAction(true))
}

const FlightHead = ({ hasFocus, term, isArrivalsActive, isMobileWidth }) => (
	<div className="flightlist-head">
		<div className="flightlist-topline">
			<Icon name="poi-badge-search-flight-status" />
			<div className="title">{ t("flights:Flight Status") }</div>
			<Icon name="global-icon-close" onClick={ () => store.dispatch(setFlightStatus(false)) } />
		</div>
		<ul className="top-links">
			<li className={(isArrivalsActive ? "" : "active")}><div className={ !isMobileWidth ? "button-pointer" : "" } onClick={ () => getAllFlightStatus("dep") }>{ t("flights:departures") }</div></li>
			<li className={(isArrivalsActive ? "active" : "")}><div className={ !isMobileWidth ? "button-pointer" : "" } onClick={ () => getAllFlightStatus("arr") }>{ t("flights:arrivals") }</div></li>
		</ul>
		<FlightStatusEntry
			hasFocus = { hasFocus }
			term = { term }
			setFlightSearchTerm = { updateFlightSearchTerm }
			wakeUp = { wakeUp }
			closeSearch = { closeSearch }
			setShowDirections = { setShowDirections }
			backFn = { () => {
				store.dispatch(setFlightStatus(false))
				} // un-minimize
			}
			isMobileWidth={ isMobileWidth }
		/>
	</div>
)

const ScrollingPortion = ({ isFlightStatusContainerOpen, isArrivalsActive, shouldTruncate, term, isLoading }) => (
	<div className={"scrolling-container bg-primary container-height" + (!isFlightStatusContainerOpen ? " hidden-container" : "")}>
		{
			/* cond(
				filteredSoonToDepartFlights.length !== 0,
				<strong className="category-title">{ isArrivalsActive ? t("flights:flights arriving soon") : t("flights:flights departing soon") }</strong>
			) */
		}
		{
			<div className="suggested-searches">
			{
				// Conditionally show Suggested Searches
				filteredSoonToDepartFlights.length > 0 || filteredDepartedFlights.length > 0
				? <FlightStatusList
							flights={ shouldTruncate ? filteredSoonToDepartFlights.slice(0, 3) : filteredSoonToDepartFlights.slice(0,100) } />
				: term === ""
					? <div className="flightsList flight-box">
							<div className="popup-holder"><div className="flight-item placeholder"></div></div>
							<div className="popup-holder"><div className="flight-item placeholder"></div></div>
							<div className="popup-holder"><div className="flight-item placeholder"></div></div>
							<div className="popup-holder"><div className="flight-item placeholder"></div></div>
							<div className="popup-holder"><div className="flight-item placeholder"></div></div>
						</div>
					: null
			}
			</div> // End of scrolling-container
		}
		{
				filteredDepartedFlights.length > 0
				? <strong className="category-title">{ isArrivalsActive ? t("flights:previous arrivals") : t("flights:previous departures") }</strong>
				: null
		}
		{
			<div className="suggested-locations">
			{
				// Conditionally show Suggested Searches
				filteredDepartedFlights.length > 0 && !shouldTruncate
				? <FlightStatusList
							flights={ filteredDepartedFlights.slice(0,100) } />
				: null
			}
			</div> // End of scrolling-container
		}
		{
			cond(
				!isLoading && filteredDepartedFlights.length === 0 && filteredSoonToDepartFlights.length === 0,
				<div className="bg-primary">
					<Message
						icon="search-icon-nomatches"
						title="Sorry, no matches found"
						advice="Please try again"
					/>
				</div>
			)
		}
	</div>
)

const MinimizeTab = ({ isMobileWidth, isFlightStatusContainerOpen }) => (
	<div className={ !isMobileWidth ? "button-pointer minimize-button" : "minimize-button" }>
		<MinimizeButton isOpen={isFlightStatusContainerOpen} onOpen={() => store.dispatch(setIsFlightStatusContainerOpenAction(!isFlightStatusContainerOpen))}/>
		<span className="tooltip-hover alt-position">
			<span className="tooltip-text">{ !isFlightStatusContainerOpen ? "Show panel" : "Hide panel" }
			</span>
		</span>
	</div>
)

class FlightStatusContainer extends React.Component {
	render() {
		let { hasFocus, term, isFlightStatusContainerOpen, isArrivalsActive, isLoading, isMobileWidth, shouldTruncate } = this.props

		return (
			<div className={"flight-status-container bg-primary -floatingContainer -mainDialog" + (isMobileWidth ? " -mobileFull" : "")}>
				<FlightHead
							hasFocus = { hasFocus }
							term = { term }
							isMobileWidth = { isMobileWidth }
							isArrivalsActive = { isArrivalsActive }
					/>
				<ScrollingPortion
						isFlightStatusContainerOpen = { isFlightStatusContainerOpen }
						isArrivalsActive = { isArrivalsActive }
						shouldTruncate = { shouldTruncate }
						term = { term }
						isLoading = { isLoading }
					/>
				<MinimizeTab
						isMobileWidth = { isMobileWidth }
						isFlightStatusContainerOpen = { isFlightStatusContainerOpen }
					/>
			</div>
		)
	}// end of render()
}

const mapStateToProps = state => {

	if(!state.flightStatus.isLoading)
		setTimeout(() => store.dispatch(setTruncateResults(false)), 100) // once flights have loaded, schedule truncation to end

	return {
		hasFocus: state.flightStatus.isFlightStatusActive,
		term: state.flightStatus.term,
		isFlightStatusContainerOpen: state.flightStatus.isFlightStatusContainerOpen,
		isArrivalsActive: state.flightStatus.isArrivalsActive,
		isLoading: state.flightStatus.isLoading,
		isMobileWidth: state.global.isMobileWidth,
		shouldTruncate: state.flightStatus.truncate
	}}

FlightStatusContainer = connect(mapStateToProps)(FlightStatusContainer)

export default FlightStatusContainer