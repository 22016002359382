/* global locuslabs */

import React from "react"
import { connect } from "react-redux"

import LevelSelectorContainer from "./components/levelSelector/LevelSelectorContainer.js"
import ZoomSelectorContainer from "./components/zoomSelector/ZoomSelectorContainer.js"
import MapContainer from "./components/map/MapContainer"
import NotificationContainer from "./components/notification/NotificationContainer"
import webglDetect from "webgl-detect"
import "./css/App.pcss"
import getStateFromStr from "./common/route"
import BrowserNotSupported from "./components/browserNotSupported/BrowserNotSupported"
import ContextMenu from "./components/contextMenu/ContextMenu"
import { getConfig } from "."
import { scond, parseQuery } from "./common/utilities"
import NavigationDialog from "./components/search/navigation/NavigationDialog"
import SearchContainer, { actionSetSearchTerm } from "./components/search/SearchContainer"
import PoiContainer from "./components/search/poi/PoiContainer"
import OverlayMgr from "./components/overlay/OverlayMgr"
import BlueDotSimulator from "./components/bluedotSim/BlueDotSimulator"
import FlightStatusContainer from "./components/flightStatus/FlightStatusContainer"
import FlightDetailView from "./components/flightStatus/FlightDetailView"
import { isMobileWidth, setIsMobileWidth, actionSetHeading, setPosition } from "./components/globalState"
// import { hoverWatch } from "./extModules/domTools"
import store from "./store"
import OnsiteAdmin from "./components/onsiteAdmin/OnsiteAdmin.js"
// import { rippleNode } from "./extModules/material"
import ContactSheet from "./components/ContactSheet";
import EB from "./components/common/ErrorBoundary.js"
import MapInfoBar from "./components/map/MapInfoBar"
import * as globalState from "./components/globalState"
import { setPoi2 } from "./components/search/navigation/NavigationDialog"
import * as mapsdk from "./common/mapsdk.js"
import { submitHandoffEvent } from "./extModules/events.js"

// Returns the value of a debug property if debug is defined at all and the property exists, else returns undefined
export const debugProp = propName => getConfig().debug ? getConfig().debug[propName] : undefined

const shouldShowControl = (name, controls, isFullpage) => {
	// If controls are not mentioned, default to true for fullpage, false for widget
	if(controls === undefined || controls === "")
		return isFullpage

		return controls === "all" || controls.split(",").indexOf(name) >= 0
	}

const mapCreateOptions = { }
let parsedQueryString

function showSearch(props)
{
	if(props.isOnsiteAdminToolShowing) // do not show search when using onsiteAdmin tool
		return null

	if(props.isNavigationOpen)
		return <EB><NavigationDialog /></EB>

	if(props.isFlightStatusActive)
		return props.isFlightDetailActive ? <EB><FlightDetailView/></EB> : <EB><FlightStatusContainer/></EB>

	if(props.poiId !== undefined)
		return <EB><PoiContainer /></EB>

	return <EB><SearchContainer /></EB>
}

const showCrossHairs = () => (
		<div className="wholeMapOverlay">
			<img className="centered noMouse" src="https://img.locuslabs.com/js/crosshairs.svg" alt="crosshairs"/>
			<MapInfoBar />
		</div> )
class App extends React.Component {

	componentWillMount()
	{
		const config = getConfig()

		parsedQueryString = parseQuery(this.props.queryString)
		let vid = parsedQueryString.vid || getConfig().venueId

		// FIXME: Hacking is a thing that things can thing. // eslint-disable-no-warning-comments
		if(vid === "lhr" && isMobileWidth()) parsedQueryString.vid = "lhrmobile"

		const setValueString = (name, src) => { if(src[name] !== undefined) mapCreateOptions[name] = src[name] }
		const setValueFloat = (name, src) => { if(src[name] !== undefined) mapCreateOptions[name] = parseFloat(src[name]) }
		const setValuePosition = (name, src) => {
				if(src[name] !== undefined)
				{
					let p = src[name]
					// if a string, convert CSV to 2 element array : "4.5, 6.8" -> [ 4.5, 6.8 ]
					if(p.split)
						p = p.split(",").map(parseFloat)
					mapCreateOptions[name] = p // new locuslabs.maps.LatLng(p[0], p[1])
				}
			}

		setValueFloat("heading", config)
		setValueFloat("zoom", config)
		setValueString("levelId", config)
		setValuePosition("position", config)

		let urlState = getStateFromStr(parsedQueryString.s || "")
		if(urlState && urlState.global)
		{
			// hacky hard-coded switch of lhr <--> lhrmobile depending on width of screen (breaks with widgets!!)
			const appNode = this.refs.appNode // actually, this may fix widgets
			if(typeof urlState.global.levelId !== "undefined")
			{
				if(urlState.global.levelId.indexOf("lhr-") !== -1 && isMobileWidth(appNode)) urlState.global.levelId = urlState.global.levelId.replace(/lhr/, "lhrmobile")
				else if(urlState.global.levelId.indexOf("lhrmobile-") !== -1 && !isMobileWidth(appNode)) urlState.global.levelId = urlState.global.levelId.replace(/mobile/, "")
			}

			setValuePosition("position", urlState.global)
			setValueFloat("heading", urlState.global)
			setValueFloat("zoom", urlState.global)
			setValueString("levelId", urlState.global)
		}

		if(mapCreateOptions.position)
		{
			store.dispatch(setPosition(mapCreateOptions.position))
			if(mapCreateOptions.levelId)
				mapCreateOptions.position = new locuslabs.maps.Position({floorId: mapCreateOptions.levelId, latLng: new locuslabs.maps.LatLng(mapCreateOptions.position[0], mapCreateOptions.position[1])})
			else
				mapCreateOptions.position = new locuslabs.maps.LatLng(mapCreateOptions.position[0], mapCreateOptions.position[1]) // This doesn't seem to work :-(
		}

		if(mapCreateOptions.heading)
			store.dispatch(actionSetHeading(mapCreateOptions.heading))

		// if(config.poiId)
		// 	setStateFromPOI(parseInt(config.poiId, 10))
		// if(parsedQueryString.poiId)
		// 	setStateFromPOI(parseInt(parsedQueryString.poiId, 10))
		// if(config.poiId || parsedQueryString.poiId)
		// 	mapCreateOptions.positionOverride = true  // signals the MapContainer to not initialize position
		if(parsedQueryString.poiId)
			config.poiId = parsedQueryString.poiId
		if(parsedQueryString.showNav)
			config.showNav = parsedQueryString.showNav

		if(parsedQueryString.navFrom && parsedQueryString.navTo)
		{
			let poiPosition = parsedQueryString.navFrom.split(',')
			config.navFrom = new locuslabs.maps.Position({
				latLng: new locuslabs.maps.LatLng(poiPosition[0], poiPosition[1]),
				floorId: poiPosition[2]
			})
			const point = [poiPosition[0],poiPosition[1]]
			mapsdk.getOrdinalForFloorId(poiPosition[2])
				.then(ordinal => {
					store.dispatch(setPoi2(parsedQueryString.navTo))
					store.dispatch(globalState.actionSetYouWereHere({position: point, ordinal}))
				})
		}

		if(parsedQueryString.ho) // this link came from MOS qrCodeHandoff
		{
			const [lat, lng, floorId] = (parsedQueryString.navFrom && parsedQueryString.navFrom.split(',')) || [ ]
			const navTo = parsedQueryString.navTo
			const accessible = parsedQueryString.accessible
			submitHandoffEvent(navTo, accessible, lat, lng, floorId)
		}

		if(parsedQueryString.search)
			store.dispatch(actionSetSearchTerm(parsedQueryString.search))
	}

	componentDidMount() {

			const appNode = this.refs.appNode

			// if(appNode)
			// 	hoverWatch(appNode, ".-rippleList > *", function(e) {
			// 			if(!store.getState().global.isMobileWidth)
			// 				rippleNode(this, e)
			// 		})

			const newMobileWidthFlag = isMobileWidth(appNode)
			if(newMobileWidthFlag !== store.getState().global.isMobileWidth)
				setIsMobileWidth(store, newMobileWidthFlag)
			window.addEventListener("resize", () => setIsMobileWidth(store, isMobileWidth(appNode)))

		}

	render() {

		const config = getConfig(),
			controlsFlag = config.controls,
			isFullpage = config.fullpage !== undefined

		if(this.props.showContactSheet)
			return <ContactSheet />

		if(!webglDetect || debugProp("browserNotSupported"))
			return <BrowserNotSupported/>
		else
			return (

				<div
						className={ "LocusLabs text-primary" + scond(this.props.poiId, " -poiSelected") + (this.props.isMobileWidth ? " -mobile" : "") + (" -sc-" + config.shortCode) }
						ref="appNode"
						>
					{ this.props.overlayName ? <OverlayMgr /> : null }
					{ this.props.isOnsiteAdminToolShowing
						? <OnsiteAdmin />
						: null }
					{ debugProp("showCrosshairs") ? showCrossHairs( ) : null }
					<MapContainer { ...this.props } vid={ parsedQueryString.vid } options={ mapCreateOptions } fullpage={ isFullpage }/>
					{ shouldShowControl("search", controlsFlag, isFullpage) ? showSearch(this.props) : null }
					{ shouldShowControl("zoom", controlsFlag, isFullpage) ? <EB><ZoomSelectorContainer /></EB> : null }
					<EB><NotificationContainer /></EB>
					<EB><ContextMenu /></EB>
					{ shouldShowControl("levels", controlsFlag, isFullpage) ? <EB><LevelSelectorContainer /></EB> : null }
                    <EB><BlueDotSimulator /></EB>
				</div>
			)
	}
}

const mapStateToProps = (state, props) => ({
				poiId: state.global.poiId,
				isMobileWidth: state.global.isMobileWidth,
				isNavigationOpen: state.search.isNavigationOpen,
				overlayName: state.global.overlayName,
                isFlightStatusActive: state.flightStatus.isFlightStatusActive,
				isFlightDetailActive: state.flightStatus.isFlightDetailActive,
				isOnsiteAdminToolShowing: Boolean(state.onsite.adminStep),
				showContactSheet: state.global.contactSheet,
				position: state.global.position
})

App = connect(mapStateToProps)(App)

export default App