import React, { Component } from "react"
import { connect } from "react-redux"

import { t } from "../../extModules/i18n"
import Icon from "../common/icon/Icon"
import Input from "../common/input/Input"

const showCloseIcon = resetSearch => (
	<Icon
		className="getDirections"
		name="global-icon-close"
		onClick={ resetSearch }
	/>
)

class FlightStatusEntry extends Component {

    render() {
		let { term, setFlightSearchTerm, wakeUp, closeSearch, backFn, isMobileWidth } = this.props

        return (
            <div className="form-box">
				<Icon name="search-icon-search"/>
                <form action="#" className="search-form">
                    <Input
						value = { term || "" }
						onBlur = { () => wakeUp(500) }
						type = "text"
						onFocus = { wakeUp }
						onClick = { null }
						disabled = { null }
						placeholder = { term ? "" : t("flights:Search for a flight…") } /* shouldn't need to null out placeholder - but it fixes a Safari bug in 10.1 */
						onChange = { (value) => setFlightSearchTerm(value) }
						autoFocus = { isMobileWidth }
                    />
                    <button type="reset" className="btn-reset">
                        {
                            term ? showCloseIcon(() => closeSearch()) : null
                        }
                    </button>
                </form>
            </div>
		)
	}
}

const mapStateToProps = state => {

	return {
	}}

FlightStatusEntry = connect(mapStateToProps)(FlightStatusEntry)

export default FlightStatusEntry
