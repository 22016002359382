import React from "react"
import Icon from "../../../common/icon/Icon"
import "./poi-title.pcss"
import { getIconSuffix } from "../PoiContainer"

const PoiTitle = ({details, closeFn, isMobileWidth}) => {
	if(isMobileWidth)
		return (
			<div className="poi-title">
				<Icon name={ "global-icon-back" } onClick={ closeFn } />
				<div className="title">
					{ details.name }
				</div>
				{ details.additionalAttributes && details.additionalAttributes.isSecurityCheckpoint ? <Icon name={ "poi-badge-security" }/> : <Icon name={ "poi-badge-" + getIconSuffix(details) } /> }
			</div>)
	else
		return (
			<div className="poi-title">
				{ details.additionalAttributes && details.additionalAttributes.isSecurityCheckpoint ? <Icon name={ "poi-badge-security" }/> : <Icon name={ "poi-badge-" + getIconSuffix(details) } /> }
				<div className="title">
					{ details.name }
				</div>
				<Icon name="global-icon-close" onClick={ closeFn }/>
			</div>
		)
	}

export default PoiTitle
