import React from "react"
import "./broswer-not-supported.pcss"
import Icon from "../common/icon/Icon"
import { getConfig } from "./../../index"
import {t} from "../../extModules/i18n"

const renderlegacyBrowserFallbackURL = (template, url, linktext) => (
			<div
				className="legacyLink"
				dangerouslySetInnerHTML={ { __html: template.replace("${link}", '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + linktext + '</a>') } } // eslint-disable-line
				/>
    )

const BrowserNotSupported = () => {

	const legacyBrowserFallbackTemplate = getConfig().legacyBrowserFallbackTemplate,
		legacyBrowserFallbackURL = getConfig().legacyBrowserFallbackURL,
		legacyBrowserFallbackLinkText = getConfig().legacyBrowserFallbackLinkText

	return (
		<div className="LocusLabs browser-not-supported">
			<div className="accepted-browsers" >
				<Icon name= "warning" className ="warning-icon" />
				<div className="title-notSupported">{t("Browser not supported")}</div>
				<div className="textbody">
					{t("Use of this maps application requires a modern web browser with support for WebGL.")}
					<br />
					<br />
					<a href="https://www.google.com/chrome/browser/desktop/">{t("Google Chrome")}</a><br />
					<a href="https://www.mozilla.org/en-US/firefox/new/">{t("Mozilla Firefox")}</a><br />
					<a href="https://www.microsoft.com/en-us/windows/microsoft-edge">{t("Microsoft Edge")}</a><br />
					<a href="https://www.microsoft.com/en-us/download/internet-explorer-11-for-windows-7-details.aspx">{t("Internet Explorer 11")}</a><br />
				</div>
				{ legacyBrowserFallbackURL ? renderlegacyBrowserFallbackURL(legacyBrowserFallbackTemplate, legacyBrowserFallbackURL, legacyBrowserFallbackLinkText) : null }
			</div>
		</div>
	)
}

export default BrowserNotSupported

