
function keyTrack(keyCode, node)
{
	node = node || window

	let isDown = false

	node.addEventListener("keydown", e => {
			if(e.keyCode === keyCode)
				isDown = true
		})

	node.addEventListener("keyup", e => {
			if(e.keyCode === keyCode)
				isDown = false
		})

	window.addEventListener("focus", () => { isDown = false})

	return () => isDown
}

export default keyTrack
