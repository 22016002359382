import { getConfig } from "../..";
import * as mapsdk from "../../common/mapsdk"

// Alpha REST API Base URL - https://lbdf2vgazk.execute-api.us-west-2.amazonaws.com/alpha/
let FLIGHT_STATUS_URL_PREFIX = "https://rest.locuslabs.com/v1",
	BETA_FLIGHT_STATUS_URL_PREFIX = "https://rest-beta.locuslabs.com/v1",
	API_KEY = "4qMdyvflUz8w2OYfJDiQG7qu1X4LWN5C4B1G1aHy"

export function getFlights(airport, type) {

	let flightStatusURL = getServiceURL(
			FLIGHT_STATUS_URL_PREFIX,
			"flightStatusURLBase",
			`/venue/${mapsdk.getVenueId()}/flight-status?type=${type}`,
			{
				beta: BETA_FLIGHT_STATUS_URL_PREFIX // these are shortcuts - just refer to "beta" to get this prefix
			})

	// flightStatusURL = type === "arr" ? "./flight-status-arr.json" : "./flight-status-dep.json"

	return fetchURL(flightStatusURL, getConfig().apiKey)
		.then(({appendix, flightStatuses}) => ({
			airlines: appendix.airlines,
			airports: appendix.airports,
			flights: flightStatuses
		}))
}

let cachedResults = {}, TIME_TO_HOLD__CACHED_DATA = 1000 * 60
function fetchURL(url, apiKey)
{
	if(cachedResults[url] && (Date.now() - cachedResults[url].lastFetchDate) < TIME_TO_HOLD__CACHED_DATA)
		return Promise.resolve(cachedResults[url].data)

	// this is so we don't have to put our super-secret beta api key in a config file..
	if(url.startsWith(BETA_FLIGHT_STATUS_URL_PREFIX) || !apiKey)
		apiKey = API_KEY

	return fetch(url,
		{
			headers: {
				"x-api-key": apiKey
			},
			mode: "cors",
			referrerPolicy: "no-referrer"
		})
		.then(response => response.json())
		.then(response => {
				cachedResults[url] = { data: response, lastFetchDate: Date.now() }
				return response
			})
}

function getServiceURL(defaultBase, configPropName, suffix, shortcuts)
{
	let base = getConfig()[configPropName] || defaultBase

	// allow some shortcuts
	if(shortcuts)
		Object.keys(shortcuts).forEach(sn => {
			if(base === sn)
				base = shortcuts[sn]
		})

	let url = `${base}${suffix}`

	if(base.startsWith("./"))
		url = base   // Allow for easy testing via a local file. Just set config.flightStatusPoiURLBase = "./testflightStatusPoiURL.json" for example...

	return url
}

export function getFlightById(id) {

	let flightDetailURL = getServiceURL(
		BETA_FLIGHT_STATUS_URL_PREFIX,
		"flightStatusURLBase",
		`/venue/${mapsdk.getVenueId()}/flight-status?flightId=${id}`,
		{
			beta: BETA_FLIGHT_STATUS_URL_PREFIX
		})

	// flightDetailURL = "./flight-aa2793.json"

	return fetchURL(flightDetailURL, getConfig().apiKey)
		.then(({appendix, flightStatus}) => ({
			airlines: appendix.airlines,
			airports: appendix.airports,
			flights: flightStatus
		}))
}