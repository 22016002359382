import Zousan from "./zousan-plus"

function $(expr, container)
{
	return typeof expr === "string" ? (container || document).querySelector(expr) : expr || null
}

function $$(expr, container)
{
	return Array.prototype.slice.call((container || document).querySelectorAll(expr))
}

function ad(element, c)
{
	c = c || {}

	let tag = "div"

	if(c.tag)
		tag = c.tag

	let node = document.createElement(tag)

	for(const cc in c)
	{
		if(cc === "klass")
			node.setAttribute("class", c.klass)
		else if(cc === "tag")
			; // already swallowed this
		else if(cc === "styles")
			setStyles(node, c.styles)
		else if(cc === "text")
			node.textContent = c.text
		else if(cc === "html")
			node.innerHTML = c.html
		else
			node.setAttribute(cc, c[cc])
		}

	element.appendChild(node)

	return node
}

// Polyfill remove
if(!("remove" in Element.prototype))
{
	Element.prototype.remove = function() { this.parentNode.removeChild(this) }
}

// Renders a modal dialog showing information organized by categories and provided via infoProviders
// infoProviders is an array of objects which must contain getName() and getInfo() methods. getInfo()
// provides either a dictionary of properties/values or an array of { name, value } objects. In either case
// the value can be a promise which is resolved to its value.
function renderInfoDialog(klass, title, subtitle, infoProviders)
{
	const dialog = showModalDialog(klass)
	const titleNode = dialog.ad({tag: "h1", klass: "bg-secondary text-secondary", text: title})
	ad(titleNode, {styles: {float: "right", fontSize: "12px" }, text: subtitle})
	const content = dialog.ad()

    infoProviders.forEach(infoProvider => {
		try
		{
			let props = infoProvider.getInfo()
			if(props)
			{
				ad(content, {tag: "h2", text: infoProvider.getName()})
				const catNode = ad(content)
				if(!Array.isArray(props))
					props = Object.keys(props).map(k => ({ name: k, value: props[k]})) // if a simple object, convert to array
				const table = ad(catNode, {tag: "table", klass: "bg-faded"})
				props.forEach(prop => {
						const tr = ad(table, {tag: "tr"})
						ad(tr, { tag: "td", text: prop.name })
						let value = prop.value
						if(value.then)
						{
							const valueField = ad(tr, { tag: "td", html: window.locuslabs.getIcon("loading-indicator") })
							value.then(res => { valueField.innerHTML = res.toString()})
						}
						else
							ad(tr, { tag: "td", text: value })
					})
				}
			}
			catch(e)
			{
				ad(content, { text: "Error obtaining " + infoProvider.getName() + " info: " + e.message})
				console.log(e)
			}
		})
	}

// Renders a modal dialog box with close button (a bit quick and dirty, mind you!)
function showModalDialog(klass)
{
	const llContainer = ad(document.body, {klass: "LocusLabs"}) // contain all within a LocusLabs container (for theming)

	const maskNode = ad(llContainer, { klass: "dialog-mask" })
	const dialogNode = ad(llContainer, { klass: "dialog-box text-primary bg-primary " + (klass ? klass : "") })

	const dialogCloseNode = ad(dialogNode, { klass: "dialog-close bg-secondary text-secondary", text: "X" })

	const close = () => {
			maskNode.remove()
			dialogNode.remove()
			dialogCloseNode.remove()
		}

	on(dialogCloseNode, "click", close)

	return {
		ad: c => ad(dialogNode, c),
		close
	}
}

function setStyles(node, styles)
{
	for(var style in styles)
		node.style[style] = styles[style]
}

// Add an event to a node - allows for an optional
// delegateSelect to restrict the event to matching
// elements
function on(node, ev, delegateSelect, fn)
{
	if(typeof delegateSelect === "function")
		{ fn = delegateSelect; delegateSelect = null }

	if(Array.isArray(ev))
		ev.forEach(ev1 => { on(node, ev1, delegateSelect, fn) })

	const handler = e => {
			if(delegateSelect)
			{
				var dNode = searchUp(e.target, delegateSelect)

				if(dNode)
					fn.call(dNode, e)
			}
			else
				fn.call(node, e)
		}

	node.addEventListener(ev, handler, false)
}

function hoverWatch(containerNode, selector, onHoverIn, onHoverOut)
{
	var lastNode = null

	const handlerOver = e => {
				var node = e.target
				var dNode = node.matches(selector) ? node : null
				if(dNode && dNode !== lastNode)
				{
					lastNode = dNode
					onHoverIn.call(dNode, e)
				}
		}

	const handlerOut = e => {
				var node = e.relatedTarget,
					parentMatch = node ? searchUp(node, selector) : null
				if(!parentMatch)
					lastNode = null
		}


	containerNode.addEventListener("mouseover", handlerOver, false)
	containerNode.addEventListener("mouseout", handlerOut, false)
}

// Returns the node that (first) matches the selector
// by searching up the parental tree from the specified node.
// i.e. if a node tree is  n1 -> n2 -> n3 -> y -> x -> n5
// and this is called with:
// searchUp(x,"n")
// we should return n3.
function searchUp(node, selector)
{
	while(node !== document) {
		if(node.matches(selector))
			return node
		node = node.parentNode
	}

	return undefined
}

/**
 * Posts data specified in the data JavaScript object to the url and returns a
 * promise of the response data. This expects the REST service to respond with
 * JSON data.
 * @param {string} url address to post data to (address of REST service)
 * @param {object} data JSON data - no encoding necessary
 */
function postData(url, data)
{
	return fetch(
		url,
		{
			method: "POST",
			body: JSON.stringify(data), // data can be `string` or {object}!
			headers: new Headers({
					"Content-Type": "application/json"
				})
		}).then(res => res.json())
}

function dynaload(url)
{
       const headElement = document.getElementsByTagName("head")[0]
       const newElement = document.createElement("script")
       newElement.setAttribute("src", url)
       headElement.appendChild(newElement)

       const p = new Zousan(),
               itemLoaded = () => p.resolve(newElement)

       newElement.addEventListener("load", itemLoaded)

       return p
}

function dynaloadcss(url)
{
		var headElement = document.getElementsByTagName("head")[0]
		var newElement = document.createElement("link")
		newElement.setAttribute("rel", "stylesheet")
		newElement.setAttribute("type", "text/css")
		newElement.setAttribute("href", url)
		headElement.appendChild(newElement)

		return new Zousan(res => newElement.addEventListener("load", res))
}

/* POLYFILL for Element.matches : https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#Polyfill */
if(!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function(s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length
            while(--i >= 0 && matches.item(i) !== this) { } // eslint-disable-line
            return i > -1
        }
}

const adui = {
	ad,
	dynaload,
	showModalDialog,
	hoverWatch,
	on,
	searchUp,
	setStyles
}

window.adui = adui

export {
	$,
	$$,
	ad,
	dynaload,
	dynaloadcss,
	showModalDialog,
	renderInfoDialog,
	hoverWatch,
	on,
	postData,
	searchUp,
	setStyles
}
