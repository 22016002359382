import { getSessionPromise, getVenueId, getVenueIdSafe } from "../common/mapsdk";
import {DependencyError} from "./errors"

const ERROR_TITLE = "EventModuleDependencyError"
const ERROR_MESSAGE = "Failed to submit analytics event to https://api.locuslabs.com/ : \n"
const MISSING_VENUE_ID_ERROR_MESSAGE = `${ERROR_MESSAGE} Import getVenueId() should return typeof string`
const MISSING_SESSION_FUNCTION_ERROR_MESSAGE = `${ERROR_MESSAGE} Function submitUserAction from locuslabs.session should return typeof function`

export const submitLevelsTappedEvent = () => submitUserEvent("levelsTapped")

export const submitPositionTappedEvent = () => submitUserEvent("positionTapped")

export const submitStopNavTappedEvent = () => submitUserEvent("stopNavTapped")

export const submitNextNavTappedEvent = () => submitUserEvent("nextNavTapped")

export const submitPrevNavTappedEvent = () => submitUserEvent("prevNavTapped")

export const submitStartNavSearchFieldEnteredEvent = () => submitUserEvent("startNavSearchFieldEntered")

export const submitStopNavSearchFieldEnteredEvent = () => submitUserEvent("stopNavSearchFieldEntered")

export const submitStartNavSearchFieldClearedEvent = () => submitUserEvent("startNavSearchFieldCleared")

export const submitStopNavSearchFieldClearedEvent = () => submitUserEvent("stopNavSearchFieldCleared")

export const submitSwitchRoutesTappedEvent = () => submitUserEvent("switchRoutesTapped")

export const submitNavKeywordTappedEvent = query => submitUserEvent("navKeywordTapped", {query})

export const submitNavPOITappedEvent = poiId => submitUserEvent("navPOITapped", {poiId})

export const submitSearchOpenedEvent = () => submitUserEvent("searchOpened")

export const submitSearchCancelTappedEvent = () => submitUserEvent("searchCancelTapped")

export const submitSearchIconTappedEvent = query => submitUserEvent("searchIconTapped", {query})

export const submitSearchCategorySelectedEvent = query => submitUserEvent("searchCategorySelected", {query})

export const submitSearchKeywordSelectedEvent = query => submitUserEvent("searchKeywordSelected", {query})

export const submitSearchPOINearbySelectedEvent = poiId => submitUserEvent("searchPOINearbySelected", {poiId})

export const submitSearchPOIOtherSelectedEvent = poiId => submitUserEvent("searchPOIOtherSelected", {poiId})

export const submitPoiNavigationTappedEvent = poiId => submitUserEvent("poiNavigationTapped", {poiId})

export const submitPoiPhoneTappedEvent = poiId => submitUserEvent("poiPhoneTapped", {poiId})

export const submitPoiTagTappedEvent = poiId => submitUserEvent("poiTagTapped", {poiId})

export const submitPoiWebsiteTappedEvent = poiId => submitUserEvent("poiWebsiteTapped", {poiId})

export const submitPoiMenuTappedEvent = poiId => submitUserEvent("poiMenuTapped", {poiId})

export const submitPoiViewedEvent = poiId => submitEvent({
		"type": "poiViewed",
		"poi": {"venueId": getVenueId(), poiId},
		"viewLevel": "full"
	})

export const submitHandoffEvent = (navTo, accessible, lat, lng, floorId) => {
	getVenueIdSafe()
		.then(venueId => {
			const data = navTo
			? {
				query: `navTo=${navTo}&accessible=${accessible}`,
				position: { lat: parseFloat(lat), lng: parseFloat(lng), floorId, venueId }
			}
			: { }
			submitUserEvent("qrCodeHandoff", data)
		})
}

function submitUserEvent(eventIdentifier, eventData = {}) {
	try {
		return validateEventSubmission(eventIdentifier, eventData)
	} catch(exception) {
		return exception
	}
}

function submitEvent(eventData = {}) {
	return getSessionPromise()
		.then(session => {
			return session.event(eventData)
		})
}

function validateEventSubmission(eventIdentifier, eventData = {})
{
	const venueId = getVenueId()
	return getSessionPromise()
		.then(session => {

			if(typeof venueId !== "string")
				throw new DependencyError(ERROR_TITLE, MISSING_VENUE_ID_ERROR_MESSAGE)
			if(typeof session.submitUserAction !== "function")
				throw new DependencyError(ERROR_TITLE, MISSING_SESSION_FUNCTION_ERROR_MESSAGE)

			eventData.venueId = venueId
			return session.submitUserAction(eventIdentifier, eventData)
		})
}