import React from "react"

import { connect } from "react-redux"
import Icon from "../common/icon/Icon"
import { showContextMenu } from "../globalState"
import store from "../../store"

import "./context-menu.pcss"

// The following is a map of contextMenuIDs to a context menu object, which
// contains an array of menu items and an getAssocNode (HTML Element to display next to)
// each menu item contains a displayName, an iconName, and a handler (fn)
export const contextMenuRepo = { }

const hideContextMenu = () => store.dispatch(showContextMenu(null))

const handleMenuItem = fn => {
	hideContextMenu()
	fn()
}

const renderMenuItem = menuItem => (
			<div className="menuItem list-item" onClick={ () => handleMenuItem(menuItem.handler) } key={ menuItem.displayName }>
				{ menuItem.iconName ?
					<Icon name={ menuItem.iconName } /> : <div style={{ width: 34 }}></div> }
				<div className="displayName">
					{ menuItem.displayName }
				</div>
			</div>
	)

let ContextMenu = ({ dispatch, menu, menuClass }) => (
		menu ? (
				<div>
					<div className="fullMask" onClick={ hideContextMenu } />
					<div className={ "context-menu bg-primary " + (menuClass ? menuClass : "") }>
						{ menu.menuItems.map(renderMenuItem) }
						{ menu.footer ? menu.footer : null }
					</div>
				</div>) :
				null
	)

const mapStateToProps = state => {
		return {
				menu: contextMenuRepo[state.global.activeContextMenuID],  // may be undefined
				menuClass: state.global.contextMenuClass
			}
	}

ContextMenu = connect(mapStateToProps)(ContextMenu)

export default ContextMenu
