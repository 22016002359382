import base64url from "base64url"

/**
 * Sets the application's global redux state based off a based64 encoded string.
 * @param str - A base64 encoded string containing persistable state
 */
export const getStateFromStr = str => encodedStateToOb(str)

/**
 * Takes a base64 encoded string that contains JSON state and returns a filtered object
 * suitable for long-term persistance
 */
function encodedStateToOb(str) {
	if(!str)
		return undefined
	let decodedString = base64url.decode(str)
	let stateOb = JSON.parse(decodedString)
	return filterStateObject(stateOb)
}

export const toBase64 = str => base64url(str)

/**
 * Filters a JavaScript Object, whitelisting root properties
 * TODO: Provide a more robust filtering approach that gives us more control over whitelisting specific state
 *   and consider removing any state that matches its default value
 * @param {object} object - Should be an object which can be modified and dispatched as a Redux state.
 * @returns {object} filtered object
 */
export function filterStateObject(object) {
	try {
		let ob2 = { }
		// define the state domains that we persist
		let acceptableRootKeys = ["global", "levelSelector", "search", "poi", "navigation"/*, "routing", "flightStatus"*/] // don't include flightStatus for now, as it is unprepared to restore state.. :-(
		acceptableRootKeys.forEach((key)=>{
			if(object[key])
				ob2[key] = object[key]
		})

		// remove any overlay triggers...
		if(ob2.global && ob2.global.overlayName)
		{
			ob2.global = Object.assign({}, ob2.global) // shallow copy of global state
			delete ob2.global.overlayName // and delete the overlayName
		}

		return ob2
	} catch(err) {
		return undefined
	}
}

export default getStateFromStr