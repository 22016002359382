import React from "react"
import { connect } from "react-redux"

let ContactSheet = ({ dispatch, isMobileWidth }) => {

	return (
		<div className="LocusLabs">
			<h1>Contact Sheet</h1>
		</div>
	)
}

const mapStateToProps = state => {
    return {
        isMobileWidth: state.global.isMobileWidth
    }
}

ContactSheet = connect(mapStateToProps)(ContactSheet)

export default ContactSheet
