import React from "react"

import "./tag.pcss"

class Tag extends React.Component {
	constructor() {
		super()
		this.state = {hover: false}
	}

	mouseEnter() {
		this.setState({hover: true})
	}

	mouseLeave() {
		this.setState({hover: false})
	}

	render() {
		let tagStyle = this.state.hover ? " bg-accent text-alt" : " bg-primary border-accent text-accent"
		return (
			<div className={"tag" + tagStyle } key={ this.props.keyword } onClick={ this.props.onClick } onMouseEnter={this.mouseEnter.bind(this)} onMouseLeave={this.mouseLeave.bind(this)} data-displaytag={ this.props.keyword }>{ this.props.keyword }</div>
		)
	}
}

export default Tag