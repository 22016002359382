import { notify, notifyPositive, clearNotification } from "../components/globalState"
import { t } from "../extModules/i18n"

const SHOW_LOADING_STATUS = false

// Don't bother with any messages if we wrap it all up in under 50ms
const WAIT_TIME_FOR_MESSAGE_START = 500

const STATE_PRELOADING = "preloading"
const STATE_LOADING = "loading"
const STATE_COMPLETE = "complete"

export function newFloorsLoading(completionPromise)
{
	clearNotification() // clear any remaining notifications

	if(SHOW_LOADING_STATUS)
	{
		let state = STATE_PRELOADING

		clearNotification() // clear any remaining notifications
		setTimeout(() => {
				if(state === STATE_PRELOADING)
				{
					notify(t("banner:Map still loading…"), null, "loading-indicator", true)
					state = STATE_LOADING
				}
			}, WAIT_TIME_FOR_MESSAGE_START)

			completionPromise.then(() => {
					if(state === STATE_LOADING)
						notifyPositive(t("banner:Loading Complete"))
					state = STATE_COMPLETE
				})
	}
}