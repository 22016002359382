/* global locuslabs */

import { log as glog} from "../components/globalState"
import * as mapsdk from "./mapsdk"
import { delay, listEquals } from "./utilities"

const log = glog.sublog("mapUtilities")

const decMarkers = { }

/**
 * This routine offers a declaritive drawMarker method - which means it can be called any number
 * of times but will only be drawn once - subsequent calls will update the position of the marker. This is
 * used in conjunction with hideMarkerDeclaritive.
 * @param {string} name they key name of the marker against which the state will be compared
 * @param {object} icon the icon to render (see mapsdk.drawMarker for details)
 * @param {[lat,lng]} position where to draw the marker
 * @param {integer} ordinal the floor ordinal
 * @param {object} additionalOptions
 */
export function drawMarkerDeclaritive(name, imageURL, point, ordinal, additionalOptions)
{
	let mo = decMarkers[name]
	if(!mo)
	{
		ordinal = (ordinal !== undefined) ? ordinal : mapsdk.getOrdinal()
		mo = { imageURL, point, ordinal }
		const markerOptions = Object.assign({
			icon: {	url: imageURL },
			position: point,
			ordinal
		}, additionalOptions)
		mapsdk.drawMarker(markerOptions)
			.then(m => { mo.marker = m })
			.then(delay(1000))
			.then(mapsdk.nudgeMap())

		decMarkers[name] = mo
	}
	else
	{
		if(mo.marker)
			mo.marker.setVisible(true)
		ordinal = (ordinal !== undefined) ? ordinal : mo.ordinal
		if(!listEquals(mo.point, point) || mo.ordinal !== ordinal) // if location of marker has changed...
		{
			mapsdk.moveMarker(mo.marker, point, ordinal)
			mo.point = point
			mo.ordinal = ordinal
		}
	}
	return mo
}

/**
 * This routine offers a declaritive drawMarker method - which means it can be called any number
 * of times but will only be drawn once - subsequent calls will update the position of the marker. This is
 * used in conjunction with hideMarkerDeclaritive.
 * @param {string} name they key name of the marker against which the state will be compared
 * @param {object} icon the icon to render (see mapsdk.drawMarker for details)
 * @param {[lat,lng]} position where to draw the marker
 * @param {integer} ordinal the floor ordinal
 */
export function drawSuperMarkerDeclaritive(name, icon, point, ordinal)
{
	let mo = decMarkers[name]
	if(!mo)
	{
		log.info("drawSuperMarkerDeclaritive - creating", name, icon, point, ordinal)

		mo = { icon, point, ordinal }
		// mapsdk.drawMarker({
		// 		icon,
		// 		point,
		// 		ordinal: ordinal
		// 		// floorId: "den-terminal-transitcenter"
		// 	}).then(m => { mo.marker = m })
		log.info("Drawing supermarker " + name)
		mapsdk.drawSuperMarker(
				icon.url,
				point,
				ordinal,
				icon.anchor)
			.then(m => { mo.marker = m })
			.then(delay(1000))
			.then(mapsdk.nudgeMap())

		decMarkers[name] = mo
	}
	else
	{
		if(mo.marker)
			mo.marker.setVisible(true)
		if(!listEquals(mo.point, point) || mo.ordinal !== ordinal) // if location of marker has changed...
		{
			log.info("drawSuperMarkerDeclaritive - moving", name, icon, point, ordinal)

			mapsdk.moveSuperMarker(mo.marker, point, ordinal)
			mo.point = point
			mo.ordinal = ordinal
		}
	}
	return mo
}

// works with superMakers or normal markers
export function hideMarkerDeclaritive(name)
{
	const mo = decMarkers[name]
	if(mo && mo.marker)
		mo.marker.setVisible(false)
}

export function hideAllDeclaritiveMarkers()
{
	Object.keys(decMarkers)
		.forEach(hideMarkerDeclaritive)
}

export function showBoundsRect(building)
{
	const br = building.geometry.boundsRect,
		[ulX, ulY] = br[0],
		[lrX, lrY] = br[1]

	const points = [
				[ulX, ulY],
				[lrX, ulY],
				[lrX, lrY],
				[ulX, lrY],
				[ulX, ulY]
			]

	drawPoly(points)
}
window.showBoundsRect = showBoundsRect

export function showBoundsPoly(building)
{
	drawPoly(building.geometry.boundsPolygon)
}
window.showBoundsPoly = showBoundsPoly

// Given a points array - draw the polygon
function drawPoly(points)
{
	points = points.map(point => new locuslabs.maps.LatLng(point[0], point[1]))

	const path = new locuslabs.maps.Path()

	points.forEach(point => path.push(point))

	mapsdk.getMapAndVenue()
		.then(([map, venue]) =>
				new locuslabs.maps.Polyline({ // eslint-disable-line no-new
						strokeWeight: 1,
						strokeColor: 0x00FF00,
						strokeOpacity: 1.0,
						map,
						path,
						floorId: mapsdk.getCurrentFloorId()
					}))
}