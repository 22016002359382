/* global locuslabs */

import { getIconSuffix } from "./poi/PoiContainer"

export const getCatBadgeName = poi => {

		if(poi)
		{
			const iconName = "poi-badge-" + getIconSuffix(poi)
			if(locuslabs.getIcon(iconName))
				return iconName
		}

		return "poi-icon-pin"
	}