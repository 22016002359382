import React from "react"
import { connect } from "react-redux"

import { round } from "../../common/utilities"
import store from "../../store"
import { actionSetHeading, log as glog } from "../globalState"
import * as mapsdk from "../../common/mapsdk"

const log = glog.sublog("MapInfoBar")

// "123.5, 3.2"  =>  [ 123.5, 3.2 ]
const pointStringToPoint = str => str.split(",").map(parseFloat)
const round2 = round(2)
const round6 = round(6)

const VERSION = "0.960"

class MapInfoBar extends React.Component {

	update()
	{
		// log.debug("update", store.getState().global)

		const { zoom, heading, position } = store.getState().global

		const [ lat, lng ] = position.map(round6)

		if(this.nodeOrd)
		{
			this.nodeOrd.value = mapsdk.getOrdinal()
			this.nodeHeading.value = round2(heading)
			this.nodeZoom.value = round2(zoom)
			this.nodePosition.value = lat + ", " + lng
		}
	}

	componentWillMount()
	{
		store.subscribe(this.update.bind(this))
	}

	renderPositionInfo()
	{
		return (
			<div className="positionInfo">
				pos: <input className="-wider" ref={n => { this.nodePosition = n } } onBlur={ e => mapsdk.displayPosition(pointStringToPoint(e.target.value)) }/>
				zoom: <input ref={n => { this.nodeZoom = n } } onBlur={ e => mapsdk.displayZoom(e.target.value) }/>
				heading: <input ref={n => { this.nodeHeading = n } } onBlur={ e => store.dispatch(actionSetHeading(e.target.value)) }/>
				ord: <input ref={n => { this.nodeOrd = n } } onBlur={ e => mapsdk.displayOrdinal(parseInt(e.target.value, 10)) }/>
			</div>
		)
	}

	renderBluedotInfo()
	{
		return (
			<div className="bluedotInfo">
			<div>
				<div>{ round6(this.props.bluedotLocation.point[0]) }</div>
				<div>{ round6(this.props.bluedotLocation.point[1]) }</div>
			</div>
			<div>
				<div>acc: { round2(this.props.bluedotLocation.accuracy) }</div>
				<div>hd: { round2(this.props.bluedotLocation.heading) }</div>
			</div>
			<div>
				<div>fl: { this.props.bluedotLocation.clFloor }</div>
				<div>v{ VERSION }</div>
			</div>
		</div>)
	}

	render()
	{
		const foobar = false

		log.debug("render: ", this.props)

		return (
			<div className="info">
				<React.Fragment>
					{ this.props.bluedotLocation && this.props.bluedotLocation.point
						? this.renderBluedotInfo() : null }
					{ this.renderPositionInfo() }
				</React.Fragment>
			</div>)
	}
}

const mapStateToProps = state => ({
	position: state.global.position,
	zoom: state.global.zoom,
	heading: state.global.heading,
	bluedotLocation: state.bluedot.lastLocation,
	levelId: state.global.levelId // don't need this but want changes to alert us
})

MapInfoBar = connect(mapStateToProps)(MapInfoBar)

export default MapInfoBar