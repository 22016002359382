import React from "react"
import { connect } from "react-redux"
import "./bluedotsim.pcss"

import * as mapsdk from "../../common/mapsdk"
import * as bluedot from "../../common/bluedot"
import store from "../../store"
import { log as glog } from "../globalState"

const log = glog.sublog("BlueDotSimulator")

const ACTION_SET_BDS_ACTIVE = "bluedotSim/isBlueDotSimActive"
const ACTION_SET_SIM_STARTED = "bluedotSim/setSimStarted"

export function reducer(state = {}, action)
{
	if(action.type === ACTION_SET_BDS_ACTIVE)
		return Object.assign({}, state, { isBlueDotSimActive: action.isBlueDotSimActive })
	if(action.type === ACTION_SET_SIM_STARTED)
		return Object.assign({}, state, { simStarted: action.simStarted })
    return state
}

export const actionSetBlueDotSimActive = isActive => {
    return {
    type: ACTION_SET_BDS_ACTIVE,
    isBlueDotSimActive: isActive
}}

const actionSetSimStarted = simStarted => ({
		type: ACTION_SET_SIM_STARTED,
		simStarted
	})

function startSimulation() {
    var points = []

    var ws = new window.locuslabs.maps.WalkSimulator(points, { walkingSpeed: 1, speedup: 2, callback: function(data ) {
        bluedot.simLocation({latitude: data.latLng._data.lat, longitude: data.latLng._data.lng})
    }, finishedCallback: function() {
        store.dispatch(actionSetSimStarted(false))
	}})

	// window.ws = ws

    window.map.addListener('click', function(event) {
		points.push({ floorId: event.floorId, latLng: { lat: event.latLng._data.lat, lng: event.latLng._data.lng } })
		log.info("Number of sim points remaining: " + points.length)
        if(points.length === 2) {
            // store.dispatch(actionUnfollow())
            // store.dispatch(actionLocation())
            ws.start()
        }
    })

    window.map.addListener('tap', function(event) {
        points.push({ floorId: event.floorId, latLng: { lat: event.latLng._data.lat, lng: event.latLng._data.lng }})
        if(points.length === 2) {
            // store.dispatch(actionUnfollow())
            // store.dispatch(actionLocation())
            ws.start()
        }
	})

	store.dispatch(actionSetSimStarted(true))
}

class BlueDotSimulator extends React.Component {

	render() {
		let { isBlueDotSimActive, simStarted } = this.props
        return (
            isBlueDotSimActive ?

            <div className="bluedot-button button" onClick={ () => simStarted ? null : startSimulation() }>
				{ simStarted ? "Tap Your Simulated Path" : "Begin Bluedot Simulator" }
            </div> : null
    )}
}

const mapStateToProps = state => {
    return {
		isBlueDotSimActive: state.bluedotSim.isBlueDotSimActive,
		simStarted: state.bluedotSim.simStarted
    }
}

BlueDotSimulator = connect(mapStateToProps)(BlueDotSimulator)

export default BlueDotSimulator