import React from "react"
import Icon from "../../../common/icon/Icon"
import {t} from "../../../../extModules/i18n"

import "./navigation-segments.pcss"
import { getConfig } from "../../../.."

const nameToIconMap = {
		"directions_start": "dir-pin-directions-start",
		"directions_end": "dir-pin-directions-end",
		"levels": "dir-badge-portal-walkway",
		"security": "dir-badge-portals-security"
	}

const getIconForSegment = name => {

		if(nameToIconMap[name])
			return nameToIconMap[name]

		return "dir-badge-portal-" + name.replace(/_/g, "-")
}

const renderSecondaryText = segment => segment.type === "Security Checkpoint" ?	t("directions:through security to") : segment.secondaryText

const renderSegment = (segment, index, onClick, currentSegIndex, totalSegCount, navigationIsMinimized) => {

	const isThroughSecurity = segment.type === "Security Checkpoint",
		//isLiveSec = details.additionalAttributes && details.additionalAttributes.isSecurityCheckpoint && details.time >= 0 && !getConfig().disableLiveSecurity && details.timeIsReal
		isLiveSec = getConfig().hasDynamicPOIs && !getConfig().disableLiveSecurity


    return (
		((currentSegIndex !== index && navigationIsMinimized) || currentSegIndex === index) ?
			<div className={ "segment list-item" + (currentSegIndex === index ? " -current" : "") } key={ index } data-index={ index } onClick={ onClick }>
				<Icon name={ getIconForSegment(segment.icon) } />
				<div className="textLabel">
					<div className="secondaryText text-muted">{ isThroughSecurity && isLiveSec ? <span className="security-time">{Math.ceil(segment.estimatedTime)} MIN</span> : null }{ renderSecondaryText(segment) }</div>
					<div className="waypoint">{ segment.primaryText } </div>
				</div>
				<div className="count border-accent text-accent">
					{ index + 1 } {t("common:of")} { totalSegCount}
				</div>
			</div> : null
	)
}

const NavigationSegments = ({ segmentList, onClick, segIndex, totalSegCount, navigationIsMinimized }) => (
			<div className="navigation-segments">
				{ segmentList ? segmentList.map((s, i) => renderSegment(s, i, onClick, segIndex, totalSegCount, navigationIsMinimized)) : null }
			</div>
		)

export default NavigationSegments
