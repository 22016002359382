import React from "react"
import { DialogEntryField } from "./DialogEntryField"

export class LoginStep extends React.PureComponent {
	state = {
		enteredPassword: ""
	}

	render() {
		const {onSubmit} = this.props
		return (
			<div className="-floatingContainer -dialog bg-primary">
				<DialogEntryField
					placeholder="Enter your VMS password..."
					value={ this.state.enteredPassword }
					iconName="entry"
					fieldName="Password"
					onSubmit={ () => onSubmit(this.state.enteredPassword) }
					type="password"
					autoFocus={ true }
					onChange= { value => { this.setState({enteredPassword: value})}}
					/>
				<div
					className="button bg-active-button text-active-button"
					onClick={ () => onSubmit(this.state.enteredPassword) }>
					Sign In
				</div>
			</div>)
	}
}
