// import React from "react"
import createReactClass from "create-react-class"
// import ReactTooltip from "react-tooltip"

import "./tooltip.pcss"

const Tooltip = createReactClass({

	render: function() {
		/*return (
			<ReactTooltip globalEventOff={"click"} {...this.props}>
				{this.props.children}
			</ReactTooltip>
		)*/
		return null
	}

})

export default Tooltip