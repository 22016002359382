import React from "react"
import "./level-selectorTrigger.pcss"
import Icon from "../common/icon/Icon"

const renderMainTrigger = (isOpen, openListFn, name, title, subtitle) => (
			<div
			onClick={ openListFn }
			className={"level-selectorTrigger" + (isOpen ? " -open" : "")}
			>
			<div className="primary bg-primary-container text-primary-container">
				<div className="textLabel">
					<div className="-titletext">{ name }</div>
					<div>{ title }</div>
				</div>
				<Icon name="search-icon-arrow" />
			</div>
			{ subtitle ?
				<div className="secondary bg-secondary-container">
					<div className="subtitle text-subdued">{ subtitle }</div>
				</div> :
				null
			}
		</div>
)

const renderMobileTrigger = (openListFn) => (
					<div className="level-selectorTrigger_mobile bg-secondary"  onClick={ openListFn }>
						<Icon name="dir-icon-level" />
					</div>
	)

const LevelSelectorTrigger = ({isOpen, openListFn, name, title, subtitle, isMobileWidth}) =>
		isMobileWidth ? renderMobileTrigger(openListFn) : renderMainTrigger(isOpen, openListFn, name, title, subtitle)

export default LevelSelectorTrigger
