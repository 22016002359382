
// return first item within array that matches filter function, else undefined
const pick = fn => ar => { const filtered = ar.filter(fn); return filtered.length ? filtered[0] : undefined }
const redStates = [ ]

export function defineRedState(domain, name, options)
{
	options = options || { }
	const redState = {
		fullpath: domain + "/" + name,
		reduce: (state, action) => {
				if(redState.fullpath === action.type)
				{
					const stateClone = Object.assign({}, state)
					stateClone[name] = action.value
					return stateClone
				}
				if(redState.reducers)
				{
					for(const r in redState.reducers)
						if(action.type === (redState.fullpath + "/" + r))
							return redState.reducers[r](state, action)
				}
				return state
			},
		set: value => ({ type: redState.fullpath, value: value })
	}

	if(options.actions)
	{
		// delete redState.set
		for(const a in options.actions)
			redState[a] = options.actions[a]
	}

	if(options.reducers)
	{
		redState.reducers = options.reducers // copy to our returning object
		for(const r in options.reducers)
			if(redState[r] === undefined)
				redState[r] = actionVals => Object.assign({ type: redState.fullpath + "/" + r }, actionVals)
	}

	redStates.push(redState)
	return redState
}

export function getRedState(path)
{
	if(!path)
		return undefined
	let first2PathParts = path.match(/([^/]*\/[^/]*)/)
	if(!first2PathParts)
		return undefined
	else
		first2PathParts = first2PathParts[0]
	return pick(redState => redState.fullpath === first2PathParts)(redStates)
}