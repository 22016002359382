import store from "../../../store"
import * as mapsdk from "../../../common/mapsdk"
import devaluate from "../../../extModules/devaluate"

const ACTION_WAKE_UP = "poi/wakeUp"
const ACTION_SET_SHOW_MAP_OVERRIDE = "poi/setShowMapOverride"

export function reducer(state = { poiId: undefined }, action)
{
	if(action.type === ACTION_WAKE_UP)
		return Object.assign({}, state)

	if(action.type === ACTION_SET_SHOW_MAP_OVERRIDE)
		return Object.assign({}, state, { showMapOverride: action.showMapOverride })

	return state
}

export function setShowMapOverride(showMapOverride)
{
	return {
		type: ACTION_SET_SHOW_MAP_OVERRIDE,
		showMapOverride
	}
}

// This self-dispatches!
export const wakeUp = (delay = 0) => {
		setTimeout(() => store.dispatch({
		type: ACTION_WAKE_UP
	}), delay)}

// Returns the poiDetails if/when available. If not available, null is returned
// until they are ready.
const dStatePoiDetails = devaluate(
	(id) => {

		if(id === undefined)
			return null

		return mapsdk.getPOIDetails(id)
	},
	() => [store.getState().global.poiId]
)

export const getDerivedPOIDetails = () => {
		return dStatePoiDetails().onDerived(wakeUp).value
	}
