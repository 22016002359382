import React from "react"
import PropTypes from "prop-types"

import Keyboard from "./Keyboard"

export default class OnScreenKeyboardInput extends React.Component {
	static propTypes = {
		name: PropTypes.any,
		containerClassName: PropTypes.any,
		inputClassName: PropTypes.any,
		keyboardClassName: PropTypes.any,
		placeholder: PropTypes.any,
		value: PropTypes.any.isRequired,
		type: PropTypes.any,
		min: PropTypes.any,
		max: PropTypes.any,
		step: PropTypes.any,
		pattern: PropTypes.any,
		readOnly: PropTypes.any,
		required: PropTypes.bool,
		opacity: PropTypes.any,
		isFirstLetterUppercase: PropTypes.any,
		uppercaseAfterSpace: PropTypes.any,
		dataset: PropTypes.any,
		onChange: PropTypes.func,
		onBlur: PropTypes.func,
		onFocus: PropTypes.func,
		onSubmit: PropTypes.func,
		showNumericRow: PropTypes.bool,
		showShift: PropTypes.bool,
		showSymbols: PropTypes.bool,
		showSpacebar: PropTypes.bool,
		showSubmit: PropTypes.bool
	};

	constructor(props) {
		super(props)
		this.state = {
			showKeyboard: false,
			input: null
		}
	}

	componentDidMount = () => {
		this.input.addEventListener("input", this.handleChange)
	}

	componentWillUnmount = () => {
		this.input.removeEventListener("input", this.handleChange)
	}

	focus = () => {
		this.input.focus()
	}

	handleChange = (event) => {
		this.props.onChange(event.target.value)
	}

	handleSubmit = () => {
		if(this.props.onSubmit) {
			this.props.onSubmit()
		}
		this.hideKeyboard()
	}

	handleOnBlur = (value) => {
		this.props.onBlur(value)
	}

	handleOnFocus = (value) => {
		this.props.onFocus(value)
	}

	handleFocus = () => {
		// Prevent blinking of the keyboard if opaque
		setTimeout(() => {
			if(this.input && typeof (this.props.value) !== "undefined") {
				this.input.focus()
				this.input.select()
				this.input.setSelectionRange(this.props.value.length, this.props.value.length)

				// Only trigger on first focus
				if(this.state.showKeyboard === false && this.props.onFocus) {
					this.props.onFocus(this.props.value)
				}

				this.setState({ ...this.state, showKeyboard: true })
			}
		}, 0)
	}

	handleFocusLost = () => {
		setTimeout(() => {
			if(!document.activeElement.classList.contains("keyboard-button")
			&& !document.activeElement.classList.contains("keyboard")
			&& !document.activeElement.classList.contains("keyboard-row")
			&& !document.activeElement.classList.contains("react-draggable-transparent-selection")) {
				if(this.props.onBlur) {
					this.props.onBlur(this.props.value)
				}
				this.setState({ ...this.state, showKeyboard: false })
			}
		}, 0)
	}

	hideKeyboard = () => {
		if(this.props.onBlur) {
			this.props.onBlur(this.props.value)
		}
		this.setState({ ...this.state, showKeyboard: false })
	}

	render = () => {
		return (
			<div className={this.props.containerClassName}>
			<input
				name={this.props.name}
				className={this.props.inputClassName}
				placeholder={this.props.placeholder}
				required={this.props.required}
				value={this.props.value}
				type={this.props.type}
				onFocus={this.handleFocus}
				onBlur={this.handleFocusLost}
				min={this.props.min}
				max={this.props.max}
				step={this.props.step}
				pattern={this.props.pattern}
				onChange={this.onChange}
				readOnly={this.props.readOnly === true}
				ref={(e) => { this.input = e }}
			/>
			{this.state.showKeyboard && this.props.readOnly !== true &&
			<Keyboard
				handleSubmit={this.handleSubmit}
				hideKeyboard={this.hideKeyboard}
				inputNode={this.input}
				dataset={this.props.dataset}
				opacity={this.props.opacity}
				isFirstLetterUppercase={this.props.isFirstLetterUppercase}
				uppercaseAfterSpace={this.props.uppercaseAfterSpace}
				keyboardClassName={this.props.keyboardClassName}
				showNumericRow={this.props.showNumericRow}
				showShift={this.props.showShift}
				showSymbols={this.props.showSymbols}
				showSpacebar={this.props.showSpacebar}
				showSubmit={this.props.showSubmit}
			/>
			}
			</div>
		)
	}
}
