import React from "react"
import {connect} from "react-redux"
import {cond} from "../../common/utilities"

import Icon from "../common/icon/Icon"
import store from "../../store"
import {
	setFlightDetailStatus,
	setIsFlightStatusContainerOpenAction,
	setFlightElement,
	setLoadingFlights
} from "./FlightStatusContainer"
import {getFlightStatusClass, getStatusPillText} from "./FlightStatusListElement"
import {showNavigationDialogAC} from "../search/SearchContainer"
import {setPoi2, setPoi1} from "../search/navigation/NavigationDialog"

import MinimizeButton from "../common/minimizeButton/MinimizeButton"
import "./flight-status-container.pcss"

import {ripple} from "../../extModules/material"
import * as mapsdk from "../../common/mapsdk"
import {getFlightById} from "./FlightStatusClientHandler"
import Flight from "./Flight"
import {getConfig} from "./../.."
import {t} from "../../extModules/i18n"
import * as moment from "moment"
import { displayDefaultMapView, log as glog } from "../../components/globalState"

const log = glog.sublog("FlightDetailView")

var marker = null

export function clearMarker() {
	if(marker) {
		marker.then(marker => marker.setMap(null))
		marker = null
	}
}

const closeDetails = () => {
	store.dispatch(setFlightDetailStatus(false))
	store.dispatch(setIsFlightStatusContainerOpenAction(true))
	clearMarker()
	displayDefaultMapView()
}

function getDirectionsClicked(e, flight) {
	ripple(e)
	store.dispatch(showNavigationDialogAC(true))
	if(flight.isArrival && !getConfig().kioskMode) {
		store.dispatch(setPoi1(flight.poiId))
	} else {
		store.dispatch(setPoi2(flight.poiId))
	}
}

export function loadFlight(flight) {
	store.dispatch(setLoadingFlights(true))
	mapsdk.search("gate", true).then(suggestedGates => {
		getFlightById(flight.flightId).then(flightData => {
			if(flightData) {
				var flightElement = flightData.flights
				var airlines = flightData.airlines
				var airports = flightData.airports
				store.dispatch(setFlightElement(new Flight(flightElement, flight.isArrival ? "ARR" : "DEP", airports, airlines, suggestedGates)));
				store.dispatch(setLoadingFlights(false))
			}
		})
	})
}

const PanelHead = ({ isMobileWidth }) => (
	<div className="panel-head">
		<div className="left-col">
			{!isMobileWidth ?
				<div className="ico-holder"><Icon name="poi-badge-search-flight-status"/></div> :
				<Icon name="global-icon-back" onClick={closeDetails}/>
			}
			<strong className="panel-title"> {t("flights:Flight Status Details")} </strong>
		</div>
		<a className="popup-close open">
			{!isMobileWidth ?
				<div><Icon className="icon-close" name="flight-icon-close" onClick={closeDetails}/>
					<span className="tooltip-hover">
					<span className="tooltip-text">Close</span>
				</span>
				</div> : <div className="ico-holder"><Icon name="poi-badge-search-flight-status"/></div>
			}
		</a>
	</div>
)

const PanelTopBox = ({ isFlightStatusContainerOpen, flight }) => (
	<div className={"panel-top-box" + (!isFlightStatusContainerOpen ? " hidden-container" : "")}>
		<div className="col">
		<span className="title-row"> <Icon className="icon-location" name="flight-icon-location"/> {flight.airportCityOrigin.toUpperCase()} </span>
			<span className="lg-title">{flight.airportCodeOrigin}</span>
		</div>
		<div className="col center-col">
		<span className="title-row">{ /* flight.getFlightDurationFormatted() */}</span>
			<div className="img-box">
				<Icon className="icon-plane-right" name="flight-icon-airplane"/>
			</div>
		</div>
		<div className="col">
		<span className="title-row">
			<Icon className="icon-location" name="flight-icon-location"/> {flight.airportCityDestination.toUpperCase()}
		</span>
			<span className="lg-title">{flight.airportCodeDestination}</span>
		</div>
	</div>
)

const DetailsTop = ({ flight }) => (
	<div className="details-top">
		<div className="left-col">
			<span
				className="flight-num">{flight.airlineName + " - " + flight.airlineCode + " " + flight.flightNumber}</span>
			<div className="centered-box">
				<div
					className={"statusFlight " + getFlightStatusClass(flight)}>{getStatusPillText(flight)}</div>
				<span
					className="update-info">{t("flights:Updated-when", {when: moment(flight.lastUpdate).fromNow()})}</span>
			</div>
		</div>
		<div className="right-col">
		</div>
	</div>
)

const GateAndTerminal = ({ gate, terminal, isCanceled }) => (
	<div className="left-col">
		{
			gate
				? (
						<div>
							<span className="sm-title"> {t("flights:gate")}</span>
							<span className="info-num">{ gate }</span>
						</div>
					)
				: null
		}
		{
			terminal
				? <div className="terminal">{ terminal }</div>
				: null
		}
	</div>
)

const FlightTime = ({ isCanceled, time, oldTime }) => (
	<div>
		<span className={ "info-num info-time" + ( isCanceled ? " line-through" : "" )}><time>{  time }</time></span>
		{
			oldTime
			? <span className="md-title line-through"><time>{ oldTime }</time></span>
			: null
		}
	</div>
)

const DetailsContent = ({ isFlightStatusContainerOpen, flight }) => (
	<div className={"details-content child-slide container-height" + (!isFlightStatusContainerOpen ? " hidden-container" : "")}>
		<div className="details-inner">
			{
				flight.getDepartureDate()
				?
					<div className="details-item">
						<ul className="flight-meta">
							<li>
								<mark>{t("flights:From")} </mark>
								{flight.airportCityOrigin} ({flight.airportCodeOrigin}) ∙
								{ new Intl.DateTimeFormat('en', { weekday: "short", day: "numeric", month: "long" }).format(flight.getDepartureDate()) }
							</li>
						</ul>
						<div className="two-cols">
							<GateAndTerminal
									gate= { flight.departureGate }
									terminal = { !flight.isArrival ? flight.terminalGate : null }
									isCanceled = { flight.isCanceled() }
								/>
							<div className="right-col">
								<span className="sm-title">{t("flights:departs")}</span>
								<FlightTime
										isCanceled={ flight.isCanceled() }
										time = { flight.getEstimatedDepartureTimeFormatted() }
										oldTime = { !flight.isCanceled() && !flight.isFlightOnTime() ? flight.getScheduledDepartureTimeFormatted() : null }
										/>
							</div>
						</div>
					</div>
				: null
			}
			{
				/* -------------------------------- v ------------------------- */
				flight.getArrivalDate() && flight.getDepartureDate()
					?
						<div className="separator-box">
							<Icon className="icon-arrow-down" name="flight-icon-downarrow"/>
						</div>
					: null
			}
			{
				flight.getArrivalDate()
					?
						<div className="details-item">
							<ul className="flight-meta">
								<li>
									<mark>{t("flights:To")} </mark>
									{flight.airportCityDestination} ({flight.airportCodeDestination}) ∙
									{ new Intl.DateTimeFormat('en', { weekday: "short", day: "numeric", month: "long" }).format(flight.getArrivalDate()) }
								</li>
							</ul>
							<div className="two-cols">
								<GateAndTerminal
										gate= { flight.arrivalGate }
										terminal = { flight.isArrival ? flight.terminalGate : null }
										isCanceled = { flight.isCanceled() }
									/>
								<div className="right-col">
									<span className="sm-title">{t("flights:arrives")}</span>
									<FlightTime
										isCanceled={ flight.isCanceled() }
										time = {
											flight.isArrival
												? flight.getEstimatedArrivalTimeFormatted()
												: flight.getArrivalTimeFormatted()
											}
										oldTime = {
											!flight.isCanceled()
												? flight.isArrival && !flight.isFlightOnTime()
													? flight.getScheduledArrivalTimeFormatted()
													: null
												: null
											}
										/>
								</div>
							</div>
						</div>
					: null
				}
			{
				flight.arrivalBagClaim
				? <div className="bagclaimnum"><Icon name="bagclaim" />
						Collect luggage from Baggage Claim { flight.arrivalBagClaim }</div>
				: null
			}

		</div> { /* End of details-inner */ }
		{
			flight.poiId
			? (
				<div
					className={"button text-active-button -full bg-active-button" + (!isFlightStatusContainerOpen ? " hidden-block" : "")}
					onClick={e => getDirectionsClicked(e, flight)}>
					<Icon name="poi-icon-getdirections"/>
					<div className="text-label">
						{flight.isArrival && !getConfig().kioskMode ? t("flights:get directions from gate") : t("flights:get directions to gate")}
					</div>
				</div>
			)
			: null
		}
	</div>
)

class FlightDetailView extends React.Component {

	render() {

		let {flight, isFlightStatusContainerOpen, isMobileWidth, isLoading} = this.props

		window.flight = flight

		if(flight !== null) {
			if(!marker)
				marker = mapsdk.drawMarker({
					position: flight.gatePositionMap,
					mapIcon: flight.getIconName(),
					floorId: flight.floorId
				})
			if(flight.floorId)
				mapsdk.displayLevel(flight.floorId)
			if(flight.gatePositionMap)
				mapsdk.zoomToLocation([flight.gatePositionMap.latLng._data.lat, flight.gatePositionMap.latLng._data.lng], 15)
		}

		return (
			<div className={"flight-status-container bg-primary -floatingContainer -mainDialog -extraWide -mobileFull" + (flight.isCanceled() ? " -canceled" : "")}>
				<div className={"popup detail-popup" + (isMobileWidth ? " mobile-flight-status" : "")}>
					<div
						className={"panel-intro child-open-close child-active" + (isMobileWidth ? " mobile-flight-status" : "")}>

						<PanelHead
								isMobileWidth = { isMobileWidth }
							/>
						<PanelTopBox
								isFlightStatusContainerOpen = { isFlightStatusContainerOpen }
								flight = { flight }
							/>
						<div className="panel-body">
							<DetailsTop
									flight = { flight}
								/>
							{ !isLoading
								? <DetailsContent
										isFlightStatusContainerOpen = { isFlightStatusContainerOpen }
										flight  = { flight }
									/>
								: <div className="details-content child-slide container-height">
										<div className="details-inner placeholder"></div>
									</div>}

							<div className={!isMobileWidth ? "button-pointer minimize-button" : "minimize-button"}>
								<MinimizeButton isOpen={isFlightStatusContainerOpen} onOpen={() => store.dispatch(setIsFlightStatusContainerOpenAction(!isFlightStatusContainerOpen))}/>
								<span className="tooltip-hover alt-position">
								<span className="tooltip-text">{!isFlightStatusContainerOpen ? "Show panel" : "Hide panel"}</span>
							</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {

	return {
		flight: state.flightStatus.flight,
		isFlightStatusContainerOpen: state.flightStatus.isFlightStatusContainerOpen,
		isMobileWidth: state.global.isMobileWidth,
		isLoading: state.flightStatus.isLoading
	}
}

FlightDetailView = connect(mapStateToProps)(FlightDetailView)

export default FlightDetailView