/* global locuslabs */

import aboutSDK from "../aboutSDK"
import aboutJSW from "../aboutJSW"
import store from "../store"
import { round } from "./utilities"
import * as mapsdk from "./mapsdk"

const R = require('ramda')

const round2 = round(2)
const round6 = round(6)

// This module simply holds a list of infoProviders to be used in the about dialog.

// infoProviders is an array of objects which must contain getName() and getInfo() methods. getInfo()
// provides either a dictionary of properties/values or an array of { name, value } objects. In either case
// the value can be a promise which is resolved to its value.

export const infoProviders = [ ]

infoProviders.push({
	getName: () => "MapsOnline",
	getInfo: () => R.map(v => v && v.type === "date" ? (new Date(parseInt(v.value, 10))).toUTCString() : v, aboutJSW)
	// getInfo: () => R.map(v => v, aboutJSW)
})

infoProviders.push({
	getName: () => "JS Core",
	getInfo: () => ({ version: aboutSDK.version, gitver: aboutSDK.gitver })
})


infoProviders.push({
	getName: () => "Session",
	getInfo: () => {
			const global = store.getState().global,
				venueInfo = mapsdk.getVenueInfo()

			return [
					{ name: "Venue Name", value: venueInfo.then(vi => vi.Name) },
					{ name: "Venue ID", value: venueInfo.then(vi => vi.Id) },
					{ name: "Position", value: global.position.map(round6) },
					{ name: "Zoom", value: round2(global.zoom) },
					{ name: "Ordinal", value: mapsdk.getOrdinal() },
					{ name: "Heading", value: round2(global.heading) }
				]
		}
})

infoProviders.push({
	getName: () => "Kiosk",
	getInfo: () => {
			const onsite = store.getState().onsite
			if(!onsite || !onsite.kioskName)
				return null
			return [
				{ name: "Kiosk Name", value: onsite.kioskName },
				{ name: "Location", value: onsite.kioskLocation.position.map(round6) },
				{ name: "Ordinal", value: onsite.kioskLocation.ordinal },
				{ name: "Zoom", value: round2(onsite.kioskZoom) },
				{ name: "Heading", value: round2(onsite.kioskHeading) }
			]
		}
	})

infoProviders.push({
		getName: () => "Venue Assets",
		getInfo: () => {
				const vid = mapsdk.getVenueId(),
						assets = locuslabs.reduxStore.getState().assets[vid],
						venueData = assets.venueData[vid],
						{id, name, website, address, version, ...partialObject} = venueData
				return { id, name, website, address, version }
			}
	})

infoProviders.push({
	getName: () => "Environment",
	getInfo: () => {
		var w = window,
		d = w.document,
		e = d.documentElement,
		g = d.getElementsByTagName('body')[0],
		x = w.innerWidth || e.clientWidth || g.clientWidth,
		y = w.innerHeight || e.clientHeight || g.clientHeight

		return [
		{ name: "User Agent", value: navigator.userAgent },
		{ name: "Browser vendor", value: navigator.vendor },
		{ name: "Screen Dimensions", value: w.screen.width + " x " + w.screen.height },
		{ name: "Screen orientation", value: w.screen.orientation ? w.screen.orientation.type : "unknown" },
		{ name: "Color Depth", value: w.screen.colorDepth + " bit" },
		{ name: "Window Dimensions", value: x + " x " + y },
		{ name: "URL origin", value: d.location.origin },
		{ name: "URL pathname", value: d.location.pathname },
		{ name: "Cookies Allowed", value: navigator.cookieEnabled},
		{ name: "language", value: navigator.language},
		{ name: "preferred languages", value: navigator.languages},
		{ name: "onLine", value: navigator.onLine}
	]}
})