import React from "react"
import Icon from "../../common/icon/Icon"
import "./landing-list.pcss"
import { t, getCurrentLanguage } from "../../../extModules/i18n"
import { getLangSpecificConfigParm, getConfig } from "../../.."

function renderItem(name, search, title, desc, iconName, onClick)
{
	iconName = iconName || "poi-badge-search-" + name
	return (
			<div onClick={ onClick } key={ name } data-search={ search } className="border-primary">
				<Icon name={ iconName } className="caticon" />
				<div className="title">{title}</div>
			</div>
		)
}

function getCurrentLandingCategories()
{
	const defaultLandingCategories = [
		{ name: "eat", title: t("data:Restaurants"), description: t("data:Find something to eat")},
		{ name: "shop", title: t("data:Shops"), description: t("data:Find all your shopping needs")},
		{ name: "relax", title: t("data:Relax"), description: t("data:Find a place to relax")},
		{ name: "check-in", title: t("data:Airlines"), description: t("data:Find your airline")},
		{ name: "parking", title: t("data:Car Parking"), description: t("data:Find a place to park your car")}
	]

	return getLangSpecificConfigParm("landingCategories", defaultLandingCategories)
}

const LandingList = ({onClick, isMobileWidth}) => {
	if(isMobileWidth)
		return (
			<div className="full-screen">
				<div className="landing-list">
					{getCurrentLandingCategories().map((item) => renderItem(item.name, item.search || item.name, item.title, item.desc, item.iconName, onClick))}
				</div>
			</div>)
	else
		return (<div className="landing-list">
			{getCurrentLandingCategories().map((item) => renderItem(item.name, item.search || item.name, item.title, item.desc, item.iconName, onClick))}
		</div>)
}

export default LandingList
