import React from "react"
import Icon from "../../common/icon/Icon"
// import Lottie from "react-lottie"
// import * as pulseAnim from "../../../lottieAnims/pulse.json"

import {t} from "../../../extModules/i18n"

import "./suggested-locations.pcss"
import { debugProp } from "../../../App"
import { getConfig } from "../../.."
import ParkingStatus from "../poi/poiDetails/ParkingStatus"
import { getIconSuffix } from "../poi/PoiContainer";

const renderLiveSecurityFlourish = poi => (
	poi.details.isClosed
		? <div className={"round-label bg-subdued text-secondary _textOneLine"}>{t("common:Closed")}</div>
		: <div className={"round-label bg-important text-important _textOneLine"}>
				{  /* <Lottie options={{
							loop: true,
							autoplay: true,
							animationData: pulseAnim,
							className: "liveDot"
							}}
						height={16}
						width={16} /> */ }
				<div className="liveDot -roundLabelStarter">
						<Icon name="live-dot" />
				</div>
				{t("poi:live-min-wait", { minutes: poi.details.time})}</div>
)

 function renderSuggestion(poi, onTouchEnd, onClick, hoverStart, hoverEnd)
{
	const iconSuffix = getIconSuffix(poi.details)

	const isLiveSec = poi.details.additionalAttributes && poi.details.additionalAttributes.isSecurityCheckpoint && poi.details.time >= 0 && !getConfig().disableLiveSecurity && poi.details.timeIsReal

	return (
			<div key={poi.poiId} data-id={ poi.poiId } onTouchEnd={ onTouchEnd } onClick={ onClick } onMouseEnter={ hoverStart } onMouseLeave={ hoverEnd } className=" border-primary suggestion bg-primary bg-faded-hover">
				<Icon name={
						isLiveSec
							? "poi-badge-security-red-badge"
							: `poi-badge-${iconSuffix}`
					} />
				<div className="textLabel">
					<div className="title">{ poi.name + (debugProp("showPOIIDInSearchResults") ? ` (${poi.poiId})` : "") }</div>
					<div className="desc text-muted">{ poi.terminal + (poi.gate ? (" / " + poi.gate) : "") }</div>
					{
						isLiveSec
							? renderLiveSecurityFlourish(poi)
							: null
					}
					{
						poi.details.category === "parking" && poi.details.timeIsReal
							? <ParkingStatus details={poi.details} />
							: null
					}
				</div>
			</div>
		)
}

const renderSuggestions = (suggestions, onTouchEnd, onClick, hoverStart, hoverEnd) => {
	if(suggestions === "loading")
		return <div className="waitIndicator bg-primary">{t("search:Searching…")}</div>
	else if(suggestions && suggestions.length > 0)
		return (
			<div className="suggestionsList -rippleList">
				{ suggestions.map(s => renderSuggestion(s, onTouchEnd, onClick, hoverStart, hoverEnd)) }
			</div>
		)
	else
		return (
				<div className="waitIndicator bg-primary">{t("search:No search results…")}</div>
			)
	}

const renderTitle = (title) =>{ return <div className="title bg-secondary text-secondary"> {title} </div>}

const SuggestedLocations = ({suggestedLocations, onTouchEnd, onClick, hoverStart, hoverEnd, title}) =>
	<div className="suggested-locations">
		{ suggestedLocations ? renderTitle(title) : null }
		{ suggestedLocations ? renderSuggestions(suggestedLocations, onTouchEnd, onClick, hoverStart, hoverEnd) : null }
	</div>

export default SuggestedLocations
