import React from "react"
import Icon from "../../common/icon/Icon"
import "./suggested-searches.pcss"
import {t} from "../../../extModules/i18n"

// renders a SINGLE suggestion
function renderSuggestion(suggestion, onClick)
{
		return (
						<div className="suggestion text-subdued border-primary bg-primary bg-faded-hover" onClick={ onClick } key={ suggestion } data-suggestion={ suggestion }>
								<Icon name="global-icon-searchrow" />
								{ suggestion }
						</div>
				)
}

const renderSuggestions = (suggestions, onClick) => {
	const renderTitle = <div className="title text-secondary bg-secondary">{t("search:SUGGESTED SEARCHES")}</div>
	return (
			<div className="suggested-searches">
				{ renderTitle }
				{ suggestions === "loading"
					? <div className="waitIndicator bg-primary">{t("search:Searching…")}</div>
					: suggestions.length > 0
						? ( <div className="suggestionsList -rippleList">
								{ suggestions.filter((s, i) => i < 5).map(s => renderSuggestion(s, onClick)) }
							</div>)
						: <div className="waitIndicator bg-primary">{t("search:No search results…")}</div> }
			</div>
		)
}

const SuggestedSearches = ({suggestedSearches, onClick }) => (
		suggestedSearches ? renderSuggestions(suggestedSearches, onClick) : null
)

export default SuggestedSearches
