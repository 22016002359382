import React from "react"
import { connect } from "react-redux"

import Icon from "../common/icon/Icon"

import "./notification-container.pcss"

let NotificationContainer = ({ dispatch, notification }) => {

		if(notification)
		{
			let backgroundClass
			switch (notification.nClass) {
				case "alert":
					backgroundClass = "bg-danger"
					break
				case "positive":
					backgroundClass = "bg-success"
					break
				default:
					backgroundClass = "bg-secondary"
					break
			}
			const className = `notification-container text-secondary ${backgroundClass}`

			return (
				<div className={ className }>
					{ notification.icon ? <Icon name={ notification.icon } /> : null }
					<div className="textMsg">
						{ notification ? notification.msg : null }
					</div>
				</div>
			)
		}

		return null
	}

const mapStateToProps = state => ({
		notification: state.global.notification
	})

NotificationContainer = connect(mapStateToProps)(NotificationContainer)

export default NotificationContainer
