import React from "react"

import {getConfig} from "./../../../"
import OnScreenKeyboardInput from "./onScreenKeyboard/OnScreenKeyboardInput"

export default class Input extends React.Component {
	render() {
		if(getConfig().kioskMode) {
			return <OnScreenKeyboardInput
				{...this.props}
				type={this.props.type ? this.props.type : "text"}
			/>
		} else {
			const { inputClassName, ...inputProps } = this.props
			return <input {...inputProps} className={this.props.inputClassName} onChange={(event) => this.props.onChange(event.target.value)} />
		}
	}
}
