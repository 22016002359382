import React from "react"
import createReactClass from "create-react-class"
import propTypes from "prop-types"
import Tooltip from "../tooltip/Tooltip"
import { t } from "../../../extModules/i18n"

import Icon from "../icon/Icon"

import "./minimizeButton.pcss"

const MinimizeButton = createReactClass({

	render: function() {
		return (
			<div className="minimizeButton bg-secondary-button text-secondary-button" onClick={()=> this.props.onOpen(!this.props.isOpen)} data-tip data-for="minimize-button-tooltip">
				<Icon name={this.props.isOpen ? "minimize-icon" : "expand-icon"}/>
				<Tooltip place="bottom" type="dark" effect="solid" className="tooltip" id='minimize-button-tooltip'>
					<span>{this.props.isOpen ? t("tooltip:hide-panel") : t("tooltip:show-panel") }</span>
				</Tooltip>
			</div>
		)
	}

})

MinimizeButton.propTypes = {
	onOpen: propTypes.func.isRequired,
	isOpen: propTypes.bool.isRequired
}

export default MinimizeButton