import React from "react"

import "./navigation-entry.pcss"
import Icon from "../../../common/icon/Icon"
import SuggestedSearches from "../../suggestedSearches/SuggestedSearches"
import SuggestedLocations from "../../suggestedLocations/SuggestedLocations"
import Message from "../../message/Message"
import {
    isPoi1Active,
    isPoi2Active,
    setPoi1,
    setPoi1Search,
    setPoi2Search,
    setPoi2,
    setPoi1Focus,
    resetSearchPoi1,
    resetSearchPoi2,
    ACTION_SWAP_POIS,
    renderBusyIndicator,
	setNavigationOnGoing
} from "../NavigationDialog"
import { cond } from "../../../../common/utilities"
import PoiInput from "../poiInput/PoiInput"
import store from "../../../../store"
import { submitSwitchRoutesTappedEvent, submitNavKeywordTappedEvent, submitNavPOITappedEvent, submitStartNavSearchFieldEnteredEvent, submitStopNavSearchFieldEnteredEvent } from "../../../../extModules/events"
import { t } from "../../../../extModules/i18n"

const renderNoRouteFoundMessage = () => null

const isPoi1SearchConfirmed = () => store.getState().navigation.poi1SearchConfirmed
const isPoi2SearchConfirmed = () => store.getState().navigation.poi2SearchConfirmed
const searchFieldActiveButNotConfirmed = () => (isPoi1Active() && !isPoi1SearchConfirmed()) || (isPoi2Active() && !isPoi2SearchConfirmed())
const shouldShowSearches = searches => searches && searches !== "loading" && searches.length > 0 && searchFieldActiveButNotConfirmed()
const shouldShowLocations = locations => locations && locations !== "loading" && locations.length > 0 && (isPoi1Active() || isPoi2Active())

const hasInvalidSearch = () => (store.getState().navigation.poi1SearchText && !store.getState().navigation.poiId1) || (store.getState().navigation.poi2SearchText && !store.getState().navigation.poiId2)

const shouldSuggestDifferentSearch = (searches, locations) => !shouldShowSearches(searches) && !shouldShowLocations(locations) && searches !== "loading" && hasInvalidSearch()

const poi1Change = value =>
{
	if(store.getState().navigation.poiId1) store.dispatch(setPoi1(null))
	store.dispatch(setPoi1Search(value))
}
const poi2Change = value =>{
	if(store.getState().navigation.poiId2) store.dispatch(setPoi2(null))
	store.dispatch(setPoi2Search(value))
}

const swapPois = () => {

	submitSwitchRoutesTappedEvent()
	store.dispatch(setNavigationOnGoing(false))

	const poiId1 = store.getState().navigation.poiId1,
		poi1SearchText = store.getState().navigation.poi1SearchText,
		poiId2 = store.getState().navigation.poiId2,
		poi2SearchText = store.getState().navigation.poi2SearchText

	store.dispatch({ type: ACTION_SWAP_POIS,
		poiId1,
		poiId2,
		poi1SearchText,
		poi2SearchText
	})
}

export const setNaviPoiId = poiId => {
	if(isPoi1Active())
	{
		setPoi1Focus(false)
		store.dispatch(setPoi1(poiId))
	}
	else
		if(isPoi2Active())
		{
			setPoi1Focus(true)
			store.dispatch(setPoi2(poiId))
		}
}

function clickPOI(e)
{
	let id = e.currentTarget.dataset.id
	submitNavPOITappedEvent(id)
	//setTimeout(() => { 	document.getElementById("poi2").blur();	document.getElementById("poi").blur() }, 500)
	setNaviPoiId(id)
}

function suggestionClicked(e)
{
	const term = e.currentTarget.dataset.suggestion
	submitNavKeywordTappedEvent(term)
	if(term)
		if(isPoi1Active())
			store.dispatch(setPoi1Search(term, true))
		else
			if(isPoi2Active())
				store.dispatch(setPoi2Search(term, true))
}

const renderNavInputs = (dispatch, poi1, poi2, poi1SearchText, poi2SearchText, isMobileWidth, close) => {

		// For start/end POIs, if we have one set, use its name-level-terminal , else use the search text
		const poiInputValue1 = poi1 ? poi1.name + "-" + poi1.level.name + " / " + poi1.terminal : poi1SearchText
		const poiInputValue2 = poi2 ? poi2.name + "-" + poi2.level.name + " / " + poi2.terminal : poi2SearchText

		return (
			<div className="navInputs-section bg-emphasized text-for-emphasizedBG">
				<div className="navInputs-top">
					<div className="navInputs-entry">
						<PoiInput
							isActive={ isPoi1Active() }
							isConfirmed={ Boolean(poi1) }
							onChange={ poi1Change }
							onFocus={()=> setPoi1Focus(true)}
							onClick= { submitStartNavSearchFieldEnteredEvent }
							label={t("directions:Starting Point")}
							placeholder={ poiInputValue1 ? "" : t("directions:Search for a starting point") /* shouldn't need to null out placeholder - but it fixes a Safari bug in 10.1 */ }
							reset={ resetSearchPoi1 }
							text={ poiInputValue1 }
							id="poi1"
							isMobileWidth={isMobileWidth}
							iconName="starting-point"
						/>
						<div className={(!isMobileWidth ? " divider-padding-desktop" : "") + " dividerLine"}>
						</div>
						<PoiInput
							isActive={ isPoi2Active() }
							isConfirmed={ Boolean(poi2) }
							onChange={ poi2Change }
							onFocus={()=> setPoi1Focus(false)}
							onClick= { submitStopNavSearchFieldEnteredEvent }
							label={t("directions:Destination")}
							placeholder={ poiInputValue2 ? "" : t("directions:Search for a destination") /* shouldn't need to null out placeholder - but it fixes a Safari bug in 10.1 */ }
							reset={ resetSearchPoi2 }
							text={ poiInputValue2 }
							id="poi2"
							isMobileWidth={isMobileWidth}
							iconName="destination-point"
						/>
					</div>
					<div className={ "navInputs-swap" }>
						{ cond(isMobileWidth, <Icon name="global-icon-close" onClick={ close }/>) }
						<Icon name="dir-icon-swap" onClick={ swapPois }/>
					</div>
				</div>
			</div>
		)
	}

const NavigationEntry = ({ dispatch, close, poi1, poi2, poi1SearchText, poi2SearchText, suggestedSearches, suggestedLocations, isMobileWidth, showNoRouteMessage }) => (
			<div className={"navigation-entry" + (isMobileWidth ? " bg-ghosted" : "")}>
				{ renderNavInputs(dispatch, poi1, poi2, poi1SearchText, poi2SearchText, isMobileWidth, close) }
				<div className="scrolling-container">
					{/*(isMobileWidth ? renderCurrentLocationButton():null)*/}
					{ /*isMobileWidth && !poi1SearchText && !poi2SearchText ? renderChooseLocationButton() : null*/ }
					{ shouldShowSearches(suggestedSearches) ? <SuggestedSearches onClick={ suggestionClicked } suggestedSearches={ suggestedSearches } /> : null }
					{ shouldShowLocations(suggestedLocations) ? <SuggestedLocations onClick={ clickPOI } suggestedLocations={ suggestedLocations } title={t("search:SUGGESTED LOCATIONS")}/> : null }
					{ shouldSuggestDifferentSearch(suggestedSearches, suggestedLocations) ? <Message icon="search-icon-nomatches" title={t("directions:Sorry, no possible routes available")} advice={t("directions:Try searching for a different route")}/> : null}
					{ poi1 && poi2 && !showNoRouteMessage && isMobileWidth ? renderBusyIndicator() : null }
					{ showNoRouteMessage && isMobileWidth ? renderNoRouteFoundMessage(false) : null }
				</div>
			</div>
	)

export default NavigationEntry
