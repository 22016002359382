import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Route } from "react-router-dom"
import { Provider } from "react-redux"

import App from "./App"
import store from "./store"
import ErrorBoundary from "./components/common/ErrorBoundary"

const renderErrorMsg = err => {
	console.log(err)
	return ( <div style={ { color: "red", margin: "2em", backgroundColor: "#FFDDDD", border: "4px solid red", padding: "2em" } }>
				{ "Error: " + err.message }
			</div> )
}

export function renderReactRoot(rootElement, config)
{
	ReactDOM.render(
		<ErrorBoundary displayMessage={ true }>
			<Provider store={store}>
				<BrowserRouter basename={"/"}>
					<Route path="*" render={routeProps => {
								try {
										return <App queryString={ window.location.search } venueId={config.venueId} accountId={config.accountId} assetsBase={config.assetsBase} assetsFormatVersion={config.assetsFormatVersion} {...routeProps} />
								}
								catch(e)
								{
									return renderErrorMsg(e)
								}
							}}/>
				</BrowserRouter>
			</Provider>
		</ErrorBoundary>,
		rootElement)
}

export function renderError(err, rootElement)
{
	ReactDOM.render(renderErrorMsg(err), rootElement)
}