/* global locuslabs */
import anim from "../extModules/anim"
import Zousan from "../extModules/zousan-plus"
import {
    values,
    each,
    throttle,
    debounce,
    onceOnly,
    listEquals,
    rectContains,
    getMobileOperatingSystem,
    isSafari,
    delay
} from "./utilities";
import store from "../store"
import { setLevel, events, log as glog} from "../components/globalState"
import { defineKioskAll } from "../components/onsiteAdmin/OnsiteAdmin"
import { getConfig } from ".."
import { monitorLocation, startFollowing } from "./bluedot"
import { debugProp } from "../App"

const log = glog.sublog("mapsdk")

let venuePromise = new Zousan(),
	mapPromise = new Zousan(),
	sessionPromise = new Zousan(),
	venueId = null

const MIN_RADIUS = 20

const MAP_POSITION_ANIMATION_TIME = 1000
const MAP_ZOOM_ANIMATION_TIME = 1200

const getSessionPromise = () => sessionPromise
const getVenueId = () => venueId

const getVenueIdSafe = async () => venuePromise.then(venue => venue.__mvcobject.id)

const mkLatLng = p => new locuslabs.maps.LatLng(p[0], p[1])
/**
 * Creates a locuslabs.maps.Position object
 */
const createPosition = (vid, floorId, point) =>
		new locuslabs.maps.Position({
			vid, floorId, latLng: mkLatLng(point)})

/*
		We tend to use "point"s rather than positions. Points are simply an array [ lat, lng ]
*/
const getPointFromPosition = pos => [ pos.lat(), pos.lng() ]

/* Lazy loading of venue data (pull the rope ladder up behind us) */
function loadVenueData(initVenueId, assetConfig, options)
{
	venueId = initVenueId
	locuslabs.setup(assetConfig, venueDBReady.bind(null, options))
}

function venueDBReady(options)
{
	options = options || { }

	locuslabs.config.useMapWidgets = false

	/*
		From constants.js
			'ERROR':  ERROR,
			'WARN' :  WARN,
			'INFO' :  INFO,
			'DEBUG':  DEBUG,
			'TRACE':  TRACE,
	*/

	locuslabs.setLogLevel("INFO")
	sessionPromise.resolve(locuslabs.session)

	options.textShouldRotate360 = getConfig().mapTextShouldRotate360
	options.jetwayBubbleTextShouldRotate360 = getConfig().jetwayBubbleTextShouldRotate360

	let callback = venueLoaded
	let params = {venueId, callback, options}

	const airportDB = new locuslabs.maps.AirportDatabase()

	if(options.headless)
	{
		params.callback = venue => venue.loadMap(null, map => venueLoaded(venue, map), false)
		airportDB.loadVenue(params)
	}
	else
		airportDB.loadVenueMapAndMapView(params)
}

function getMapAndVenue()
{
	return Zousan.all([mapPromise, venuePromise])
}

var getRadiusFromZoom = anim.project(0, 100, 750, MIN_RADIUS, "easeOut3") // this gets redefined when the venue loads
const getZoomFromRadius = radius => getRadiusFromZoom.backward(radius)

function venueLoaded(venue, map/*, mapView, zoom*/)
{
	log.info("* venueLoaded *")

	venuePromise.resolve(venue)

	events.fire("mapLoaded")
	addZoomListener(value => events.fire("zoom", { value }))
	addLevelChangeListener(levelId => events.fire("level", { levelId, ordinal: getOrdinal() }))

	// The following was commented out as these events also come through the heading_changed listener
	// addHeadingListener(heading => events.fire("heading", { heading }))

	mapPromise.then(map => {
			map.addListener("heading_changed", throttle(oldHeading => {
			const heading = map.getHeading()

			if(!isNaN(heading))
				events.fire("heading", { heading })
			}))
		})

	addPositionListener(point => events.fire("position", { point }))

	// events.observe((a, b) => { console.log("server.event : ", a, b)})

	getRadiusFromZoom = anim.project(0, 100, Math.max(100, venue.getVenueRadius()), MIN_RADIUS, "easeOut3")

	// As opposed to using a Promise for the default param value of the redux state's global.levelId
	// We dispatch the venue id of type string to the redux state upon handling the callback for VenueMapAndMapview.
	store.dispatch(setLevel(map.getFloorId()))

    //The use of bluedot is limited to iOS and safari
	getBuildingData()
		.then(data => {
				const level = data[data.length - 1]
				if(((getMobileOperatingSystem() === "iOS" && isSafari) || debugProp("allowBluedot"))
						&& level.levels[level.levels.length - 1].clfloor !== null && !getConfig().kioskMode)
				{
					monitorLocation()
					startFollowing()
				}
			})

	mapPromise.resolve(map)
}

function getVenueDefaultOrdinal()
{
	return getBuildingData()
		.then(bd => getLevelObForBuildingsAndLevelId(bd, bd[0].defaultLevel))
		.then(level => level.ordinal)
}

function getPOIAsync(poiId)
{
	return new Zousan((resolve, reject) =>
		venuePromise.then(venue => venue.poiDatabase().getPoi(poiId, details =>
			details ? resolve(details) : reject(Error("POI with ID " + poiId + " Not Found")))))
}

/*
		******************************
		PUBLIC API functions for mapsdk
		******************************
*/

function displayZoom(value, time, screenX, screenY)
{
	if(time === undefined)
		time = MAP_ZOOM_ANIMATION_TIME

	const options = { animationDuration: time }
	if(screenX || screenY)
		options.centerAt = { x: screenX, y: screenY }

	mapPromise
		.then(map => map.setRadius(getRadiusFromZoom(value), options))
}

function displayHeading(heading, time)
{
	time = time === undefined ? 500 : time
	return mapPromise
		.then(map => map.setHeading(heading/*, time */)) // didn't work - research this
}

// this little hack allows us to return zoom synchronously.  think about this more!
let holdMap = null, holdBuildings = null
mapPromise.then(map => { holdMap = map })
getBuildingData().then(bd => {
	holdBuildings = bd
})

function getRadius()
{
	//return mapPromise.then(map => map.getRadius())
	return holdMap ? holdMap.getRadius() : NaN
}

// Returns promise of a zoom level (0 to 100) by querying the map and translating to zoom
function getZoom()
{
	const radius = getRadius()
	return isNaN(radius) ? 0 : getRadiusFromZoom.backward(radius)
}

// What about bad POIs or other errors?
function getPOIDetails(poiId)
{
	// abort if poiId not valid
	if(!poiId)
		return Error("Invalid PoiID: " + poiId)

	return Zousan.evaluate(
			{ name: "buildings", value: getBuildingData },
			{ name: "poi", value: getPOIAsync(poiId) },
			{ name: "poiPlus", deps: ["buildings", "poi"],
				value: (buildings, poi) =>
					Object.assign(poi, { level: getLevelObForBuildingsAndLevelId(buildings, poi.position.floorId) === undefined ? {name: "", details: "", id: "", ordinal: 0} : getLevelObForBuildingsAndLevelId(buildings, poi.position.floorId) })
				},
			{ name: "poiWithImage", deps: [ "poiPlus" ],
				value: poiPlus => Object.assign(poiPlus, poiPlus.image ? { image376URL: locuslabs.maps.Images.getImageURLForWidthAndHeight(poiPlus.image, 376, 376)} : { })}
		)
		.then(eo => eo.poiWithImage)
}

// Pass in an array of POI Ids and this will display icons for them
let currentIdListDisplay = null
function displayPOIMarkers(idList)
{
	if(listEquals(idList, currentIdListDisplay))
		return Zousan.resolve(true)
	log.debug("displayPOIMarkers", idList)
	currentIdListDisplay = idList
	return mapPromise.then(map =>
		Zousan.all(idList.map(getPOIAsync))
			.then(poiObList => {
					map.getView().highlightPois(poiObList, {
							shouldDesaturate: !(getConfig().dontDesaturateSearchResults),
							filterGreyscale: getConfig().desaturationFilterGrayscale || 0.3,
							borderRadius: getConfig().markerHideAtRadius || 280,
							borderRadiusBadge: getConfig().badgeMarkerHideAtRadius || 50
						})
				})
		)
}

async function hidePOIMarkers()
{
	if(!currentIdListDisplay)
		return null

	currentIdListDisplay = null

	return mapPromise.then(map => map.getView().unhighlightPois())
}

/**
 * Primary proximity search entry point providing a list of POI results based on a term ordered by closest to
 * furthest from the map's current position
 * "confirmed".
 * @param  {string} term - The term to search for
 * @param  {boolean} includeDetails If true, a detailed POI lookup is performed and attached to the details property of each poi result
 * @param  {} termConfirmed
 */
function proximitySearch(term, includeDetails, termConfirmed)
{
	log.info("proximitySearch", term, includeDetails, termConfirmed)

	if(termConfirmed)
		return proximityTermsSearch(term, includeDetails)

	return autocomplete(term)
		.then(terms => proximityTermsSearch(terms, includeDetails))
}

/**
 * Performs a proximity search on the term or terms passed.
 * @param  {string|string[]} terms - The term or terms (array) to search for
 * @param  {boolean} includeDetails If true, a detailed POI lookup is performed and attached to the details property of each poi result
 */
function proximityTermsSearch(terms, includeDetails)
{
	if(!terms)
		return Zousan.resolve(null)

	const termArray = Array.isArray(terms) ? terms : [terms]

	const z = new Zousan()

	venuePromise.then(venue =>
		mapPromise.then(map =>{
			let center = map.getCenter()
			venue.search().proximitySearchWithTerms(termArray, map.getFloorId(), center.lat(), center.lng(), res => {
				const results = res.getResults().concat(res.getOtherResults())
				log.debug("proximitySearch returned", results)
				if(includeDetails)
					z.resolve(Zousan.map(results, o =>
						getPOIDetails(o.poiId)
							.then(details => Object.assign({ details }, o))
							.catch(e => { log.error("Error getting POI Details for " + o.name + " (" + o.poiId + "): " + e); return null }) // eslint-disable-line no-console
					).then(res2 => res2.filter(o => o))) // filter out null entries (entries with errors)
				else
					z.resolve(results)
			})
		}))

	return z
}
/**
 * Return a list of poi details for the list of POIs specified.
 *
 * @param  {integer[]} poiIdList list of POI ids to search for
 */
function idListSearch(poiIdList)
{
	return Zousan.map(poiIdList, id => getPOIDetails(id)
			// format the same as search
			.then(details => ({
					poiId: id,
					name: details.name,
					gate: details.gate,
					position: details.position,
					terminal: details.terminal,
					details: details
				}))
			.catch(e => { log.error("Error getting POI Details for POI ID " + id + "): " + e); return null }) // eslint-disable-line no-console
		).then(res => res.filter(o => o)) // filter out null entries (entries with errors)
}

/**
 * Performs a non-proximity search against the given term. If the includeDetails flag is set, the POIs
 * returned will contain a details property with extra details
 * @param  {string} term
 * @param  {boolean} includeDetails
 */
function search(term, includeDetails)
{
	const z = new Zousan()

		venuePromise.then(venue =>
				venue.search().search(term, res => {
					const results = res.getResults()
					if(includeDetails)
						z.resolve(Zousan.map(results, o =>
							getPOIDetails(o.poiId)
								.then(details => Object.assign({ details }, o))
								.catch(e => { log.error("Error getting POI Details for " + o.name + " (" + o.poiId + "): " + e); return null }) // eslint-disable-line no-console
						).then(res2 => res2.filter(o => o))) // filter out null entries (entries with errors)
					else
						z.resolve(results)
				})
			)

		return z
}

function autocomplete(term)
{
    if(!term)
        return Zousan.resolve(null)

    const z = new Zousan()

    venuePromise.then(venue =>
        venue.search().autocomplete(term, (query, res) => {
            z.resolve(res)
        })
    )

    return z
}

// Pass in a CSS offset and this returns the
function cssOffsetToCoord(x, y)
{
	return mapPromise
		.then(map => {
				const coord = map.getView().toLatLng([x, y])._data
				return [ coord.lat, coord.lng ]
			})
}

// Returns a promise which will reject if the level specified is not found
function displayLevel(levelId)
{
	return mapPromise
		.then(map => map.setFloorId(levelId))
}

function displayOrdinal(ordinal)
{
	return mapPromise.then(map => map.getView().showFloorsForOrdinal(ordinal))
}

function getOrdinalForFloorId(floorId)
{
	return getBuildingData()
		.then(buildings =>
			buildings.reduce((retOrd, building) =>
				getOrdinalForFloorIdInBuilding(floorId, building, retOrd), null))
}

function getOrdinalForFloorIdInBuilding(floorId, building, defValue)
{
	return building.levels.reduce((retOrd, level) => level.id === floorId ? level.ordinal : retOrd, defValue)
}

function getBuildingData()
{
	return venuePromise
		.then(venue => values(venue.buildings).map(bo => { return {
				id: bo.get("id"),
				name: bo.get("id") === "sea-southsatellite" ? "South Satellite" : bo.get("name"),
				defaultLevel: bo.get("defaultLevelId"),
				boundsPolygon: bo.get("boundsPolygon"),
				shouldDisplay: bo.get("shouldDisplay") === undefined ? true : bo.get("shouldDisplay"),
				levels: values(bo.get("levels")).map(lo => ({
						name: lo.name,
						details: lo.details,
						id: lo.id,
						ordinal: lo.ordinal,
						clfloor: lo.clfloor
					}))
			}})
		)
}

/**
 * Takes a set of verticies that represent a bounding polygon
 * and returns the following structure:
 * {
 *	boundsPolygon: [ array of vertices ],
 *	boundsRect: [ <upperLeft point>, <lowerRight point> ],
 *	center: <center point>,
 *	radius: < larger of width/2 and height/2 >
 * }
 */
function getGeometry(vs)
{
	const boundsRect = vs.reduce((br, v) => [
					[ Math.min(br[0][0], v[0]), Math.min(br[0][1], v[1])],
					[ Math.max(br[1][0], v[0]), Math.max(br[1][1], v[1])]
				], [ vs[0], vs[0] ]),
		[ ul, lr ] = boundsRect,
		[ ulx, uly ] = ul,
		[ lrx, lry ] = lr,
		width = distance([ ulx, uly ], [ lrx, uly ]), // distance of top edge
		height = distance([ ulx, uly ], [ ulx, lry ]) // distance of left edge

	return {
		boundsPolygon: vs,
		boundsRect,
		center: [ (lrx + ulx) / 2, (lry + uly) / 2 ],
		radius: Math.max(width / 2, height / 2)
	}
}

function distance(p1, p2)
{
	const coord1 = mkLatLng(p1)
	const coord2 = mkLatLng(p2)
	return coord1.distance(coord2)
}

function getTopLevelAreaGroups(areaStruct, point)
{
	return areaStruct.reduce((r, ag) =>
			rectContains(ag.geometry.boundsRect, point)
				? r.push(ag)
				: r
			, [])
}

/**
 *	The goal here is to determine the (likely) focused area given the position, ordinal and radius of a map.
 *	The assumptions are as follows:
 *		1. If the area's bounding rect does not contain the center of the map, it is likely NOT the focus.
 *		2. If the radius of the viewed map is more than the n times the radius of an area, it is likely NOT the focus. (n = 2?)
 *		3. If the ordinal does not match a child, it is not the focus.
 *
 *	If more than 1 area qualifies for the above conditions, Use locuslabs.maps.Utilities.isPointInPolygon to filter out
 *	areas that contain the center within their boundsRect but not their boundsPolygon.
 *
 *	If there are still multiple qualifying areas, choose the one with the smallest radius (most specific)
 *
 * @param {*} areaStruct
 * @param {*} point
 * @param {*} radius
 * @param {*} ordinal
 * @returns {Promise.Area} returns the area determined to be the focus of the current map
 */
function determineAreaInView(areaStruct, point, radius, ordinal)
{
	let results = areaStruct.reduce((r, ag) =>
		ag.areas.reduce((r, a) => {
				if(a.ord === ordinal && rectContains(a.geometry.boundsRect, point) && radius < (3 * a.geometry.radius))
					r.push([ ag, a ])
				return r
			}, r), [])

	// if multiple areas qualify, fine-tune our position detection to consider boundsPolygon
	if(results.length > 1)
		results = results.filter(r =>
				locuslabs.maps.Utilities.isPointInPolygon(point, r[1].geometry.boundsPolygon)
			)

	if(results.length)
	{
		// sort by radius - with smallest first - such that we select the "most specific" qualifying area
		results.sort((r1, r2) => r1[1].geometry.radius - r2[1].geometry.radius)
		return results[0]
	}

	return null
}

/**
 *
 * @param {[lat,lng]} position A point within the venue
 * @param {integer} ordinal an ordinal
 * @return {Promise.integer} The floorId for the building located at the position for the ordinal
 */
function getFloorIdForPositionOrd(point, ordinal)
{
	return getAreaStruct()
		.then(areaStruct => determineAreaInView(areaStruct, point, 0.1, ordinal))
		.then(area => area ? area[1].id : null)
}

const buildingSortComparitor = selectorOrder => (b1, b2) => {
		if(!selectorOrder)
			return b1.name.localeCompare(b2.name)
		let o1 = selectorOrder.indexOf(b1.id)
		let o2 = selectorOrder.indexOf(b2.id)
		return o1 - o2
	}

function getAreaStruct()
{
	return venuePromise
		.then(venue => getBuildingData()
				.then(bd => bd
					.filter(b => b.shouldDisplay) // allow map creators to filter any buildings they wish
					.filter(b => !b.id.match(/global|default/) || bd.length === 1) // filter out the global building and ensure it should display (unless there is only a single building!)
					.map(b => {
						const geometry = getGeometry(b.boundsPolygon)
						return {
							name: b.name,
							id: b.id,
							title: "", // consider using a "/" delimeted list of children names (up to some char limit)
							geometry: geometry,
							defaultOrdinal: getLevelObForBuildingsAndLevelId(bd, b.defaultLevel).ordinal,
							areas: b.levels.map(l => ({
									id: l.id,
									name: l.name,
									title: l.details,
									ord: l.ordinal,
									clfloor: l.clfloor,
									geometry: geometry
								}))
								.sort((a, b) => b.ord - a.ord)
						}})
						.sort(buildingSortComparitor(venue.get("selectorOrder")))
					)
			)
}

function onPOIClick(fn)
{
	mapPromise.then(map => map.addListener("poi_click", e => fn(e.poiId)))
}

function getBuilding(buildings, id)
{
	if(!buildings || !buildings.length) return null

	const filteredBuildings = buildings.filter(b => b.id === id)
	if(filteredBuildings.length)
		return filteredBuildings[0]
	return undefined
}

function displayPositionForBuildingId(buildingId)
{
	return mapPromise
		.then(map => {
				const building = getBuilding(holdBuildings, buildingId)
				if(!building)
					throw Error("Unknown building " + buildingId)
				const geo = getGeometry(building.boundsPolygon)
				// displayPosition(geo.center)
				// displayZoom(getZoomFromRadius(geo.radius))
				map.setCenterAndRadius(mkLatLng(geo.center), geo.radius, MAP_POSITION_ANIMATION_TIME)

				return true
		})
}

// Scans the level IDs in all the buildings and returns the match against levelId.
// It also adds a property "building" with the building in which it matched
function getLevelObForBuildingsAndLevelId(buildings, levelId)
{
	// this uses each() which acts like Array.forEach but lets you *return* a value during the iteration
	const levelOb = each(buildings,
		b => each(b.levels,
			lev => lev.id === levelId ?
					Object.assign({}, lev, { building: b }) :
					undefined
				))
	if(levelOb)
		return levelOb

	throw Error("Unknown level ID: " + levelId)
}

function initMapSDKActual(initVenueId, assetConfig, options)
{
	// Kick this off - it is asynchronous, so we can't depend on it yet…
	loadVenueData(initVenueId, assetConfig, options)

	venuePromise.then(venue => { window.venue = venue; return true })
	mapPromise.then(map => {window.map = map})
	return mapPromise.then(() => true) // return a promise that resolves to true when the map is ready
}

const initMapSDK = onceOnly(initMapSDKActual)

function displayPosition(point, time)
{
	log.debug("displayPosition", point, time)
	if(point)
		return mapPromise
			.then(map => map.setCenter(mkLatLng(point), time !== undefined ? time : MAP_POSITION_ANIMATION_TIME))
	return Zousan.resolve(true)
}

function displayPositionZoomHeading(point, zoom = getZoom(), heading, time)
{
	return mapPromise.then(map => {
			heading = heading === undefined ? map.getHeading() : heading
			const radius = getRadiusFromZoom(zoom)
			log.debug("displayPositionZoomHeading", point, radius, heading)
			// setCenterRadiusAndHeading(map, mkLatLng(point), radius, heading, time)
			setPositionZoomHeading(map, point, zoom, heading, time)
		})
}

function drawCircle(point, fillColor, strokeColor, radius)
{
	return mapPromise
		.then(map => {

					const position = new locuslabs.maps.Position({
						venueId: venueId,
						// buildingId: beacon.buildingId,
						floorId: map.getFloorId(),
						latLng: mkLatLng(point)
					})

					const circle = new locuslabs.maps.Circle({
						position: position,
						radius: radius,
						map: map,
						fillColor: fillColor,
						fillOpacity: 0.2,
						strokeWeight: 5,
						strokeColor: strokeColor,
						strokeOpacity: 0,
						zIndex: 500
					})

					return circle
			})
}

function getCurrentFloorId()
{
	//const floorId = holdMap.getFloorId().includes('global') || holdMap.getFloorId().includes('default') ? holdMap.getView().get('floorIds')[0] : store.getState().global.levelId === undefined ? holdMap.getFloorId() : store.getState().global.levelId
    return holdMap.getFloorId()
}

const getAreaForAreaGroupAndCLFloor = (clfloor, areaGroup) => areaGroup.areas.reduce((match, area) => area.clfloor === clfloor ? area : match, null)

// given a point, return the area group that contains it... (some day this may return a list of area groups...)
const getAreaGroupForPoint = point => getAreaStruct()
	.then(agList => {
		const ag = agList.reduce((match, ag) => rectContains(ag.geometry.boundsRect, point) ? ag : match, null)
		if(ag)
			return ag
		return Zousan.reject(Error("No areagroup found for point " + point))
	})

// given a clfloor and a point, determine the
const getAreaForCLFloor = async (clfloor, point) =>
	getAreaGroupForPoint(point)
		.then(ag => getAreaForAreaGroupAndCLFloor(clfloor, ag))

const getOrdForCLFloor = async (clfloor, point) =>
	getAreaForCLFloor(clfloor, point)
		.then(area => area.ord)

const getFloorIdForCLFloor = async (clfloor, point) =>
	getAreaForCLFloor(clfloor, point)
		.then(area => area.id)

function displayBluedot(point, floorLevel)
{
/*	const errorRadius = 5

	const navPoint = new locuslabs.maps.NavPoint({
			errorRadius: errorRadius,
			position: new locuslabs.maps.Position({
				floorId: getCurrentFloorId(),
				latLng: mkLatLng(point)
			}),
			fillColor: 0x057CFF,
			fillOpacity: 0.3,
			map: holdMap
		})

	return Zousan.resolve({
		updatePos: point => {
			// navPoint.setVisible(true)
			navPoint.setPosition(new locuslabs.maps.Position({
						floorId: getCurrentFloorId(),
						latLng: mkLatLng(point)
					}))},
		hide: () => { }, // navPoint.setVisible(false),
		navPoint
			})*/
	// log.info("displayBluedotOnAllFloors")

	return venuePromise.then(venue =>
		mapPromise.then(map => {

			map.getView().setPositioningEnabled(true)
			venue.set("positioningSensorAlgorithm", locuslabs.maps.PositionManager.positioningSensorAlgorithmExternal)

			return {
					updatePos: function(point, clFloor, errorRadius, heading) {

							Zousan.evaluate(
								{ name: "latLng", value: mkLatLng(point) },
								{ name: "floorId", value: getFloorIdForCLFloor(clFloor, point) }
							).then(o => {
								const floorId = o.floorId

								// log.info("update bluedot", point, clFloor, floorId, heading, errorRadius)
								map.getView().turnFollowMeModeOff() // obnoxious.. but..

								if(floorId === undefined || floorId === null)
									floorId = getCurrentFloorId()

								// log.info("recordPositionSensorReading", o.latLng, errorRadius, floorId, heading)
								venue.recordPositionSensorReading(o.latLng, errorRadius, floorId, heading)

								window.locuslabs.events.submit({
										type: "positionChanged",
										position: {
												lat: point[0],
												lng: point[1],
												venueId: getVenueId(),
												floorId
											}
										})
									})
								.catch(e => console.warn(e)) // probably couldn't find a building at your current point
						},
					hide: () => { } // eslint-disable-line no-empty-function
				}
		})
	)
}

function drawRect(ulPoint, lrPoint)
{
	return mapPromise
		.then(map => {

				var polylinePath = new locuslabs.maps.Path()
				polylinePath.push(new locuslabs.maps.LatLng(ulPoint[0], ulPoint[1]))
				polylinePath.push(new locuslabs.maps.LatLng(lrPoint[0], ulPoint[1]))
				polylinePath.push(new locuslabs.maps.LatLng(lrPoint[0], lrPoint[1]))
				polylinePath.push(new locuslabs.maps.LatLng(ulPoint[0], lrPoint[1]))
				polylinePath.push(map.get("venueCenter"))

				new locuslabs.maps.Polyline({	// eslint-disable-line
						strokeWeight: 2,
						strokeColor: 0xFF0000,
						strokeOpacity: 0.8,
						map: map,
						path: polylinePath,
						floorId: map.getFloorId()
				})
			})
}

// The following little mumbo jumbo is an odd bit of code to force the map to move a tiny amount
// which solves some issues we were having in which it wouldn't render unless it was moved
// at least a little bit. The nudgeDir sillyness is so it alternates the direction it moves so in cases
// where this is called many times it doesn't have a cumulative effect and visibly move the map.
let nudgeDir = 1
function nudgeMap()
{
	nudgeDir *= -1
	return getCurrentPosition()
		.then(p => displayPosition([ p[0] + (0.000002 * nudgeDir), p[1] + (0.000002 * nudgeDir)]))
		.then(() => log.info("Map Nudged"))
}

/**
 * Draw a custom marker on the map. By default, it uses the pin icon and places it at your current
 * position.
 * @param  {object} [options] A bunch of optional parameters you can pass in
 * @param  {object} [options.icon] Specify an icon image (in icon format - { url, etc} )
 * @param  {object} [options.mapIcon] Use an existing defined icon by name
 * @param  {object} [options.dragable] Trigger the marker to emit drag events
 * @param  {object} [options.position] Can be a locuslabs.Position object, or a [ lat, lng ] point
 * @param  {object} [options.ordinal] Specify an ordinal
 */
function drawMarker(options)
{
	return mapPromise
		.then(map => {

				if(options && options.mapIcon)
					options.icon = { url: locuslabs.getIcon(options.mapIcon) }
				if(options.position && Array.isArray(options.position))
					options.position = mkLatLng(options.position)
				if(options.ordinal)
					return getFloorIdForPositionOrd(getPointFromPosition(options.position), options.ordinal)
						.then(floorId => {
							options.floorId = floorId
							return drawMarkerFromOb(options)
						})
				else
						return drawMarkerFromOb(options)
			})
}

function drawMarkerFromOb(markerOb)
{
	return mapPromise
		.then(map => {
				const markerOpts = Object.assign({}, {
						map,
						dragable: false,
						icon: "images/pin_poi.svg",
						floorId: getCurrentFloorId(),
						position: map.getCenter()
					}, markerOb)

				log.debug("drawMarkerFromOb", markerOpts)
				return new locuslabs.maps.Marker(markerOpts)
		})
}

async function drawSuperMarker(url, point, ordinal, anchor)
{
	ordinal = (ordinal !== undefined ? ordinal : getOrdinal()) // use curent ordinal if undefined
	const floorId = await getFloorIdForPositionOrd(point, ordinal)
	return mapPromise
		.then(map => new locuslabs.maps.SuperMarker({
				url,
				anchor,
				position: createPosition(getVenueId(), floorId, point),
				map: map}))
}

async function moveSuperMarker(superMarker, point, ordinal)
{
	const floorId = await getFloorIdForPositionOrd(point, ordinal)
	superMarker.setPosition(createPosition(getVenueId(), floorId, point))
}

async function moveMarker(marker, point, ordinal)
{
	const floorId = await getFloorIdForPositionOrd(point, ordinal)
	marker.setPosition(createPosition(getVenueId(), floorId, point))
}

let currentNavigationController = null

// Pass in two position objects (such as from a poi.position prop) and
// receive a promise of a NavigationController
function displayNavigation(posA, posB, options)
{
	log.info("showing navigation for ", posA, posB, options)

	if(currentNavigationController)
		// throw Error("Already navigating!")
		return currentNavigationController

	const z = new Zousan()

	Zousan.evaluate(
			{ name: "venue", value: venuePromise },
			{ name: "map", value: mapPromise }
		)
		.then(eo => {
			currentNavigationController = new locuslabs.navigation.NavigationController(
				nc => z.resolve(nc),
				z.reject,
				eo.venue,
				eo.map.getView(),
				posA,
				posB)
			if(options)
				locuslabs.navigation.NavigationController.options = options
			return currentNavigationController
		})

	z.then(nc => {
			nc.totalTime = nc.getSegments().reduce((t, c) => c.estimatedTime + t, 0)
			nc.totalDistance = nc.getSegments().reduce((t, c) => c.distance + t, 0)

			const
				// array of level differences, i.e. [0,0,0,1,0,0,1,0,0,-1]
				levChangeList = nc.getSegments().map(seg => seg.levelDifference),
				// determine start (s) and end (e) by walking the change list
				mm = levChangeList.reduce((p, c) => { p.c += c; p.s = Math.min(p.s, p.c); p.e = Math.max(p.e, p.c); return p }, {s: 0, e: 0, c: 0})

			nc.totalLevels = Math.abs(mm.e - mm.s) + 1 // total floors is ending level minus starting level plus 1

			window.nc = nc
		})
	return z
}

function hideNavigation()
{
	if(currentNavigationController)
		currentNavigationController.tearDown()

	currentNavigationController = null // re-reference
}

function displayNavigationSegment(segIndex)
{
	if(!currentNavigationController)
		throw Error("No navigation currently displayed")

	return currentNavigationController.showSegmentAt(segIndex)
}

function getNavigation(pos1, pos2)
{
	return new Zousan((res, rej) => {
			venuePromise.then(venue => venue.getNavigationSegments(pos1, [pos2], res))
		})
}

function addHeadingListener(fn)
{
	mapPromise.then(map => {
			map.addListener("heading_changed", oldHeading => {
				const heading = map.getHeading()
				if(!isNaN(heading))
					fn(heading)
			})
		})
}

function addZoomListener(fn)
{
	mapPromise.then(map => {
			map.addListener("radius_changed", throttle(oldRadius => {
					const radius = map.getRadius()
					if(!isNaN(radius))
						fn(getRadiusFromZoom.backward(radius))
				}, 50))
		})
}

function addPanListener(fn)
{
	mapPromise.then(map => {
		map.addListener("center_changed", throttle(() => {
			fn()
		}, 50))
	})
}

function addMapClickListener(fn)
{
	mapPromise.then(map => map.addListener("click", fn))
}

function getVenueBounds()
{
	return venuePromise
		.then(venue => boundsToRect(venue.get("bounds")))
}

const determineIsPointWithinVenue = point => getVenueBounds().then(bounds => rectContains(bounds, point))

// bounds is an object type used in the SDK. Rect is an array of [ <min x,y> , <max x,y> ]
function boundsToRect(bounds)
{
	const sw = bounds.getSouthWest(),
		ne = bounds.getNorthEast()
	return [
		[ sw.lat(), sw.lng() ],
		[ ne.lat(), ne.lng() ]
	]
}

function zoomToLocation(point, radius)
{
	if(!radius)
		radius = MIN_RADIUS

		determineIsPointWithinVenue(point)
		.then(isInVenue => {
				if(isInVenue)
				{
					// mapPromise.then(map => map.setCenterRadiusAndHeading(map, mkLatLng(point), radius * 2, MAP_POSITION_ANIMATION_TIME))
					displayPosition(point)
					setTimeout(() => displayZoom(getRadiusFromZoom.backward(radius * 2)), 1000)
				}
				else
					log.warn("Location discovered but falls outside venue…") // eslint-disable-line no-console
		})
}

/**
 * Pans and zooms the map to include the positions passed in the array of POIs. If additional positions
 * are specified they are also included.
 * @param {Array.object} poiList A list of poi objects containing the position property
 * @param {[number,number]} [additionalPoint] an additional point to include in the pan/zoom
 */
function panZoomMapToIncludePois(poiList, additionalPoint)
{
	log.debug("panZoomMapToIncludePois", poiList, additionalPoint)

	// filter poi list by current ordinal
	return mapPromise.then(map => {
			const positionList = poiList
				.map(poi => poi.position)

			if(additionalPoint)
				positionList.push(createPosition(getVenueId, getCurrentFloorId(), additionalPoint))

			if(positionList.length)
				map.zoomToExtentOfPositions(positionList)

			return true
		})
}

function addPositionListener(fn)
{
	mapPromise.then(map => {
			map.addListener("center_changed", debounce(oldPosition => {
					const center = map.getCenter()
					const position = [center.lat(), center.lng()] // convert to array point
					// log.debug("center_changed event: ", position)
					fn(position)
				}, 500))
		})
}

function addFloorIdListener(fn)
{
	mapPromise.then(map => {
			map.addListener("floorId_changed", () => fn(map.getFloorId()))
		})
}

function getCurrentPosition()
{
	return mapPromise.then(map => {
		const center = map.getCenter()
		return [center.lat(), center.lng()]
	})
}

function addLevelChangeListener(fn)
{
	mapPromise.then(map => {
			map.addListener("floorId_changed", oldLevelId => {
					const levelId = map.getFloorId()
					fn(levelId)
				})
		})
}

/**
 * This is called from MapsOnsite for the Denver Demo when there is non kiosk
 * defined. Do not call this from anywhere else!
 */
function setKioskForDenverDemo(kioskLocation, kioskName, kioskHeading, kioskZoom)
{
	log.info("setKioskForDenverDemo", kioskLocation, kioskName, kioskHeading, kioskZoom)
	defineKioskAll(kioskLocation, kioskName, kioskHeading, kioskZoom)
}

function getOrdinal()
{
	return holdBuildings ?
		getLevelObForBuildingsAndLevelId(holdBuildings, holdMap.getFloorId()).ordinal :
		0
}

function displayDefaultView(time)
{
	time = time === undefined ? MAP_POSITION_ANIMATION_TIME : time
	return getVenueCenterPoint()
		.then(centerPoint =>
			mapPromise.then(map => {
				// map.setCenterAndRadius(mkLatLng(centerPoint), getRadiusFromZoom(0), MAP_POSITION_ANIMATION_TIME)))
				// setCenterRadiusAndHeading(map, mkLatLng(centerPoint), getRadiusFromZoom(0), 0, time)))

				displayPosition(centerPoint, time)
				displayZoom(0, time)
			}))
}

/** Experimental - uses setCenterRadiusAndHeading */
function displayDefaultView2(time)
{
	time = time === undefined ? MAP_POSITION_ANIMATION_TIME : time
	return getVenueCenterPoint()
		.then(centerPoint =>
			mapPromise.then(map => {
				setCenterRadiusAndHeading(map, mkLatLng(centerPoint), getRadiusFromZoom(0), 0, time)
			}))
}

function setCenterRadiusAndHeading(map, p, r, z, time)
{
	log.debug("calling setCenterRadiusAndHeading(", p, r, z, time)
	map.setCenterRadiusAndHeading(p, r, z, time)
}

function setPositionZoomHeading(map, point, zoom, heading, time)
{
	displayPosition(point, time)
	displayZoom(zoom, time)
	displayHeading(heading, time)
}

function getVenueCenterPoint()
{
	return venuePromise.then(venue => {
			const center = venue.getCenterPosition()
			return [ center.lat(), center.lng() ]
		})
}

function selectPOI(poiId)
{
	getPOIDetails(poiId)
		.then(poi => {
			window.poi = poi // useful for debugging
			mapPromise.then(map => {
					// const zoom = getZoomFromRadius(parseInt(poi.radius, 10) * 5)
					displayOrdinal(poi.level.ordinal)
						// .then(() => displayPosition([poi.position.latitude, poi.position.longitude]))
						// .then(delay(MAP_POSITION_ANIMATION_TIME - MAP_ZOOM_ANIMATION_TIME))
						// .then(() => displayZoom(zoom))
						.then(() => map.setCenterAndRadius(poi.position.latLng, poi.radius * 4 + 2, MAP_POSITION_ANIMATION_TIME))
				})
		})
}

// The following flag is due to discovering that Apple does not support indoor positioning within
// Safari at this time. Better to not display blue dot with such inaccuracies.
const FORCE_INDOOR_POSITIONING_UNSUPPORTED = false
function getIndoorPositionSupported()
{
	return venuePromise.then(venue => Boolean(venue.get("positioningSupported") && venue.get("positioningSupported").length && !FORCE_INDOOR_POSITIONING_UNSUPPORTED))
}

const getVenueName = () => venuePromise.then(venue => venue.getName())
const getVenueInfo = () => venuePromise.then(venue => ({
			Name: venue.get("name"),
			Id: venue.get("id"),
			"Asset Version": venue.get("version"),
			"Indoor Positioning": (Boolean(venue.get("positioningSupported")) && venue.get("positioningSupported").length > 0).toString()
	}))

const getVenuePositionSupported = () => venuePromise.then(venue => ({ "positioning": venue.get("positioningSupported")}))

export {
		autocomplete,
		MAP_POSITION_ANIMATION_TIME,
		MAP_ZOOM_ANIMATION_TIME,
		addFloorIdListener,
		addHeadingListener,
		addLevelChangeListener,
		addMapClickListener,
		addPositionListener,
        addPanListener,
		addZoomListener,
		createPosition,
		cssOffsetToCoord,
		currentNavigationController,
		drawCircle,
		drawMarker,
		drawRect,
		drawSuperMarker,
		displayBluedot,
		displayDefaultView,
		displayDefaultView2,
		displayHeading,
		displayLevel,
		displayNavigation,
		displayNavigationSegment,
		displayOrdinal,
		displayPOIMarkers,
		displayPosition,
		displayPositionForBuildingId,
		displayPositionZoomHeading,
		displayZoom,
		determineAreaInView,
		getAreaStruct,
		getAreaForCLFloor,
		getAreaGroupForPoint,
		getAreaForAreaGroupAndCLFloor,
		getVenueCenterPoint,
		getVenueDefaultOrdinal,
        getVenuePositionSupported,
		getBuilding,
		getBuildingData,
		getCurrentFloorId,
		getCurrentPosition,
		getOrdForCLFloor,
		getFloorIdForCLFloor,
		getFloorIdForPositionOrd,
		getIndoorPositionSupported,
		getMapAndVenue,
		getNavigation,
		getOrdinal,
		getOrdinalForFloorId,
		getPOIDetails,
		getPOIAsync,
		getRadius,
		getSessionPromise,
		getVenueId,
		getVenueIdSafe,
		getVenueName,
		getVenueInfo,
		getZoomFromRadius,
		getZoom,
		hideNavigation,
		hidePOIMarkers,
		idListSearch,
		initMapSDK,
		determineIsPointWithinVenue,
		moveMarker,
		moveSuperMarker,
		nudgeMap,
		onPOIClick,
		panZoomMapToIncludePois,
		proximitySearch,
		search,
		selectPOI,
		setCenterRadiusAndHeading,
		setKioskForDenverDemo,
		zoomToLocation
}