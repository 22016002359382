/* A collection of animation primitives (From Fujisawa anim)*/

/*eslint-disable */

function easeBoth3(x)
{
	if((x *= 2) < 1) return 1/2 * x * x * x
	return 1/2 * ((x-=2) * x * x + 2)
}

function easeBoth4(x)
{
	if((x *= 2) < 1) return 1/2 * x * x * x * x
	return 1/2 * ((x-=2) * x * x * x * -1 + 2)
}

function easeBoth5(x)
{
	if((x *= 2) < 1) return 1/2 * x * x * x * x * x
	return 1/2 * ((x-=2) * x * x * x * x + 2)
}

function easeOutElastic(x)
{
	if(x === 0) return 0
	if(x === 1) return 1
	return Math.pow(2,-10*x) * Math.sin((x-0.075) * 21 ) + 1
}

function easeOutBounce(x)
{
	if(x < 1/2.75)
		return 7.5625 * x * x
	else
		if(x < 2/2.75)
			return 7.5625 * ( x -= 1.5/2.75) * x + 0.75
		else
			if(x < 2.5/2.75)
				return 7.5625 * ( x -= 2.25/2.75) * x + 0.9375
			else
				return 7.5625 * (x -= 2.625/2.75) * x + 0.984375
}

function easeIn(x)
{
	return Math.pow(x,2)
}

function easeIn3(x)
{
	return Math.pow(x,3)
}

function easeOut(x)
{
	return 1 - easeIn(1 - x)
}

function easeOut3(x)
{
	return 1 - easeIn3(1 - x)
}

easeOut3.back = x => 1 - Math.pow(1 - x, 1/3)

// Calculates the factor of the current metric (cm) within the range of the starting metric (sm)
// and the ending metric (em) along the curve of the easing function.
function tween(sm,em,cm,easeFn)
{
	cm = bound(sm, em, cm) // bound the current metric (ensure it is within range)
	return easeFn((cm-sm) / (em-sm))
}

// bound a value between a min and max. If value is outside range, set it to closest valid value
// min/max can also be reversed.
const bound = (min,max,value) => min < max ? Math.min(Math.max(min, value), max) : Math.min(Math.max(max, value), min)

var easeFns = {
		"linear": function(x) { return x },
		"boomerang": function(x) { return Math.sin( Math.PI * x  ) },
		"both": function(x) { return Math.sin( Math.PI * (x - 1 / 2)) / 2 + 1/2 },
		"both3": easeBoth3,
		"both4": easeBoth4,
		"both5": easeBoth5,
		"easeIn": easeIn,
		"easeIn3": easeIn3,
		"easeOut": easeOut,
		"easeOut3": easeOut3,
		"outElastic": easeOutElastic,
		"outBounce": easeOutBounce
	}

// Creates a projection function which will project a metric in relation to an input range
// into a metric in relation to an output range, along an easing curve.
// For example:
// 	var xfn = project(0,10,0,100) // project from a 0-10 range into a 0-100 range
// 	x = xfn(3) // 30
// 	x = xfn(6) // 60
function project(ins,ine,outs,oute,ease)
{
	if(ease && typeof ease === "string")
		ease = easeFns[ease]

	ease = ease || easeFns.linear

	if(typeof ease === "string")
		ease = easeFns[ease]

	const forward = value => {
			var x = tween(ins, ine, value, ease)
			return (oute-outs) * x + outs
		}

	if(ease.back)
		forward.backward = value => {
				var x = tween(outs, oute, value, ease.back)
				return (ine-ins) * x + ins
			}

	return forward
}

/**
 * Animates a value from the start to the end (along an easing curve) over the time specified.
 * This will tap into requestAnimationFrame and call the callback with the value along the
 * start/end path as appropriate. When it completes, it unregisters itself and resolves its originally
 * returned promise.
 * @param {integer} time time in ms for the animation
 * @param {Number} start starting value
 * @param {Number} end ending value
 * @param {String|function} easing Easing function
 * @param {function} cb callback
 * @returns {Promise} completion promise
 */
function animateOverFrames(time, start, end, easing, cb)
{
	easing = easing || "both4"
	const xfn = project(0, time, start, end, easing) // project across the time requested into the heading
	const startTime = Date.now()
	const completionPromise = new Zousan()
	const fn = () => {
			const elapsed = Date.now() - startTime
			if(elapsed >= time)
			{
				cb(xfn(time)) // be sure and send a final event with the precise end value
				return completionPromise.resolve(true)
			}
			cb(xfn(elapsed))
			requestAnimationFrame(fn)
		}
	requestAnimationFrame(fn)
	return completionPromise
}

// Calculate a point along a bézier curve where
// p[0] is start point [x,y]
// p[1] is control/anchor
// p[2] is end point
// t is 0 -> 1
function calcQuadBez(p, t)
{
	const x = (1 - t) * (1 - t) * p[0][0] + 2 * (1 - t) * t * p[1][0] + t * t * p[2][0]
	const y = (1 - t) * (1 - t) * p[0][1] + 2 * (1 - t) * t * p[1][1] + t * t * p[2][1]
	return [ x, y ]
}

function animateBetweenPointsOverFrames(time, p1, p2, easing, cb)
{
	easing = easing || "both4"
	const xfn = project(0, time, p1[0], p2[0], easing)
	const yfn = project(0, time, p1[1], p2[1], easing)
	const startTime = Date.now()
	const completionPromise = new Zousan()
	const fn = () => {
			const elapsed = Date.now() - startTime
			if(elapsed >= time)
			{
				cb([xfn(time), yfn(time)]) // be sure and send a final event with the precise end value
				return completionPromise.resolve(true)
			}
			cb([xfn(elapsed), yfn(elapsed)])
			requestAnimationFrame(fn)
		}
	requestAnimationFrame(fn)
	return completionPromise
}

export default {
		animateBetweenPointsOverFrames,
		animateOverFrames,
		calcQuadBez,
		easeFns,
		project
	}
