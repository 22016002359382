import React from "react"
import createReactClass from "create-react-class"

import "./icon.pcss"
import { log as glog } from "../../globalState";

const log = glog.sublog("Icon")

export const getSVGForName = name => window.locuslabs.getIcon(name)

// see https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
const utf8btoa = str => window.btoa(unescape(encodeURIComponent(str)))

export const svgToDataURI = svg => "data:image/svg+xml;base64," + utf8btoa(svg)

export const getIconAsDataURI = name => svgToDataURI(getSVGForName(name))

const Icon = createReactClass({

	genIconHTML: function(name) {
		const svg = getSVGForName(name)

		if(!svg)
		{
			log.warn("No icon found named *" + name + "*")
			return null
		}

		if(svg.match(/(^.....?:|^\.\/)/)) // actual icon URL
			return { __html: `<img src="${svg}"/>` }

		return { __html: svg }
	},

	render: function() {

		let { onClick, style, name } = this.props

		let className = "icon " + this.props.name + (this.props.className ? " " + this.props.className : "")
		return <div
				className={ className }
				onClick={ onClick }
				style={ style }
				dangerouslySetInnerHTML={ this.genIconHTML(name) }
				/>
	}

})

export default Icon
