/* global locuslabs */

import * as mapsdk from "./mapsdk"
import Zousan from "../extModules/zousan-plus"
import { constrain, actionSetHeading, displayDefaultMapView, log as glog} from "../components/globalState"
import store from "../store"
import { setStateFromPOI, events } from "../components/globalState"
import { loadConfig } from "./.."
import anim from "../extModules/anim"
import { showNavigationDialogAC, getActionSetPoiIdList, setNewConfirmedSearchTerm, resetSearch } from "../components/search/SearchContainer"
import { setPoi1, setPoi2, setSegIndex } from "../components/search/navigation/NavigationDialog"
import { debugProp } from "../App"
import aboutJSW from "../aboutJSW"
import aboutSDK from "../aboutSDK"
import { resetApplication } from "../components/reducers"
import { whenTrue } from "./utilities"
import {
    drawMarkerDeclaritive,
    hideMarkerDeclaritive,
    drawSuperMarkerDeclaritive,
    hideAllDeclaritiveMarkers
} from "./mapUtilities";

const jsonErr = er => ({ type: "ERROR", msg: er.message })
const log = glog.sublog("apiServer")

export function addMessageListener()
{
	if(window.addEventListener)
	{
		const portMessageHandler = function(e) {
				// First, make sure this is intended for us
				if(e.data.LLmsgType === "Locus-client")
				{
					log.debug("Server received message: " + JSON.stringify(e.data))
					var ret = processIncomingCommand(e.data)
					if(ret && ret.then && typeof ret.then === "function") // thenable - so assume its a promise
						ret.then(function(pret) {
									if(pret instanceof Error)
										return sendToClient({response: jsonErr(pret), LLmsgId: e.data.LLmsgId})
									return sendToClient({response: pret, LLmsgId: e.data.LLmsgId})
								})
							.catch(function(er) { sendToClient({response: jsonErr(er), LLmsgId: e.data.LLmsgId}) })
					else
						sendToClient({response: ret, LLmsgId: e.data.LLmsgId})
					}
			}

		window.addEventListener("message", portMessageHandler, false)
		sendToClient({ event: "ready" })

		// Forward local events
		events.observe(function(name, ob) {
				ob = ob || { }
				if(!(ob instanceof Object))
					throw Error("Badly formed event object: " + ob)
				ob.event = name
				sendToClient(ob)
			})
	}
}
/**
 * Low level function for posting a message that will be received by a client. Currently the client side (api/src/api.js)
 * has two object formats it understands. One is a response, containing { response, LLmsgId } and one is an
 * event, containing { event, ...props }
 * @param  {Object} ob - An object that will be sent to client with an acceptable property list
 */
function sendToClient(ob)
{
	ob.LLmsgType = "Locus-server"
	window.postMessage(ob, "*")
}

function isTrue(v)
{
	if(!v) return false
	if(v === "yes" || v === "true" || v === true) return true
	if(v === "no" || v === "false" || v === false) return false
	throw Error("Bad boolean value: " + v)
}

let headlessMode = false
export function processIncomingCommand(cmd)
{
	if(debugProp("sdk"))
		log.debug("Incoming SDK Command: ", cmd)

	switch (cmd.command)
	{
		case "drawMarker":
			return drawMarker(cmd)
		case "getBuildings":
			return mapsdk.getBuildingData()
		case "getEncodedState":
			return store.getEncodedTransferableState()
		case "getNavigation":
			return getNavigation(cmd)
		case "showNavigation":
			return showNavigation(cmd.fromPoiId, cmd.toPoiId, cmd.segIndex)
		case "getPOIDetails":
			return mapsdk.getPOIDetails(cmd.id)
		case "getPosition":
			return getPosition()
		case "setHeading":
			return setHeading(cmd.degrees, cmd.time)
		case "getZoom":
			return store.getState().global.zoom
		case "hideMarker":
			return hideMarker(cmd.name)
		case "hideMarkers":
			return hideAllMarkers()

		case "initHeadless":
			headlessMode = true
			// window.headlessMapsdk = mapsdk
			return initHeadless(cmd)

		case "resetMap":
			return resetMap(cmd.resetState, cmd.time, cmd.mapCenter)

		case "resetSearch":
			return resetSearch()

		case "showSearch":
			return setNewConfirmedSearchTerm(cmd.term, cmd.currentBuildingOnly)

		case "search":
			if(headlessMode)
				return mapsdk.search(cmd.term, isTrue(cmd.details))
			else
				return mapsdk.proximitySearch(cmd.term, isTrue(cmd.details), true) // proximitySearch(term, includeDetails, termConfirmed)

		case "setPosition":
			if(cmd.lat)
			{
				var point = [ parseFloat(cmd.lat), parseFloat(cmd.lng) ]
				return mapsdk.determineIsPointWithinVenue(point)
					.then(whenTrue(
							() => mapsdk.displayPosition(point, cmd.time),	// if the point is within the venue
							() => Error("point " + point + " is not withiin the venue map area") // if the point is NOT within the venue
						))
			}
			//if(cmd.buildingId)
				return mapsdk.displayPositionForBuildingId(cmd.buildingId, cmd.time)

		case "showLevel":
			if(cmd.id)
				return mapsdk.displayLevel(cmd.id)
			//if(cmd.ord)
				return mapsdk.displayOrdinal(parseInt(cmd.ord))

		case "showPOI":
			//store.dispatch(setPoi(cmd.id))
			return setStateFromPOI(cmd.id)
		case "showMarkers":
			return showMarkers(cmd, cmd.dontPanMap)

		case "zoom":
			if(cmd.to !== undefined)
				return mapsdk.displayZoom(cmd.to, cmd.time)
			else
				if(cmd.by)
					return mapsdk.displayZoom(constrain(mapsdk.getZoom() + cmd.by, 0, 100), cmd.time)
			break

		case "version":
			return { "sdk": cmd.sdkVersion, "js-widgets": aboutJSW.version, "js-core": aboutSDK.version }

		default:
			return "unknown command " + cmd.command
	}

	return undefined
}

let autoMarkerIndex = 1

//	{ command: "drawMarker", args: [ {arg: "name", type: "string", optional: true}, { arg: "lat", type: "float", min: -90, max: 90 }, { arg: "lng", type: "float", min: -180, max: 180 }, {arg: "imageURL", type: "string"}, {arg: "ordinal", type: "int", optional: true}, ] },
function drawMarker(cmd)
{
	const name = cmd.name || "marker-" + (autoMarkerIndex++)
	if(cmd.super)
		drawSuperMarkerDeclaritive(name, {url: cmd.imageURL, anchor: cmd.anchor}, [ cmd.lat, cmd.lng ], cmd.ordinal)
	else
		drawMarkerDeclaritive(name, cmd.imageURL, [ cmd.lat, cmd.lng ], cmd.ordinal)
	return name
}

function hideMarker(name)
{
	hideMarkerDeclaritive(name)
	return name
}

function initHeadless(cmd)
{
	// one way to initheadless is with a config.js
	if(cmd.configURL)
		return loadConfig(cmd.configURL)
			.then(config => mapsdk.initMapSDK(
					config.venueId,
					config,
					{ headless: true })
				)

	// the other is with an accountId / venueId
	return mapsdk.initMapSDK(
			cmd.venueId,
			{
				accountId: cmd.accountId,
				assetsBase: "https://assets.locuslabs.com/accounts/",
				assetsFormatVersion: "v4"
			}, { headless: true })
}
/**
 * Render the navigation from poi1 to poi2 on the map
 * @param  {integer} poiId1 the FROM location
 * @param  {integer} poiId2 the TO location
 * @param  {integer} segIndex [optional] The segment to highlight
 */
function showNavigation(poiId1, poiId2, segIndex)
{
	store.dispatch(showNavigationDialogAC(true))
	store.dispatch(setPoi1(poiId1))
	store.dispatch(setPoi2(poiId2))

	if(segIndex !== undefined)
		store.dispatch(setSegIndex(segIndex))

	return true
}

function hideAllMarkers()
{
	return mapsdk.hidePOIMarkers()
		.then(hideAllDeclaritiveMarkers)
		.then(() => true) // return true
}

function getPosition()
{
	return Zousan.evaluate(
		{ name: "areaStruct", value: mapsdk.getAreaStruct },
		{ name: "position", value: mapsdk.getCurrentPosition },
		{ name: "radius", value: mapsdk.getRadius },
		{ name: "ordinal", value: mapsdk.getOrdinal },
		{ name: "venueInfo", value: mapsdk.getVenueInfo },
		{ name: "zoom", value: mapsdk.getZoom },
		{ name: "floorId", value: mapsdk.getCurrentFloorId },
		{ name: "area", deps: [ "areaStruct", "position", "radius", "ordinal" ], value: mapsdk.determineAreaInView}
	).then(eob => Object.assign({
			lat: eob.position[0],
			lng: eob.position[1],
			venueName: eob.venueInfo.Name,
			ordinal: eob.ordinal,
			zoom: eob.zoom,
			floorId: eob.floorId
		},
		eob.area ? {
				buildingName: eob.area[0].name,
				floorName: eob.area[1].name
			} : null
	))
}

function setHeading(destHeading, time)
{
	time = time || 0

	if(time > 0)
	{
		let curHeading = store.getState().global.heading
		if(Math.abs(curHeading - destHeading) > 180) // if this is true, the closest rotation crosses the 0 mark
		{
			if(curHeading >= 0)
				curHeading += 360
			else
				destHeading += 360
		}
		anim.animateOverFrames(time, curHeading, destHeading, "both4", v => {
				store.dispatch(actionSetHeading(v))
			})
	}
	else
		store.dispatch(actionSetHeading(destHeading))

	return true
}

function resetMap(resetStateFlag, time, mapCenterFlag)
{
	if(resetStateFlag)
		store.dispatch(resetApplication())

	displayDefaultMapView(time, mapCenterFlag)
}

// This format is required by locuslabs.js - its a position object with the poiId field as well
const poiToPosition = poi => Object.assign({ poiId: poi.poiId}, poi.position)
const locationToPosition = (point, floorId) => new locuslabs.maps.Position({ latLng: new locuslabs.maps.LatLng(point[0], point[1]), floorId: floorId })

// pass in EITHER a poiId OR a location ( { point, ordinal }) and receive a
const getPositionObject = (poiId, location) =>
		poiId
			? mapsdk.getPOIDetails(poiId).then(poiToPosition)
			: mapsdk.getFloorIdForPositionOrd(location.point, location.ord).then(floorId => locationToPosition(location.point, floorId))

function getNavigation(cmd)
{
	return Zousan.evaluate(
			{ name: "pos1", value: getPositionObject(cmd.fromPoiId, cmd.fromLocation) },
			{ name: "pos2", value: getPositionObject(cmd.toPoiId, cmd.toLocation) }
	).then(ob => {
			return mapsdk.getNavigation(ob.pos1, ob.pos2)
	})
}

function showMarkers(cmd, dontPanMapFlag)
{
	let poiIdList = cmd.poiIdList
	if(cmd.poiList)
		poiIdList = cmd.poiList.map(poi => poi.poiId)

	store.dispatch(getActionSetPoiIdList(poiIdList))
	return mapsdk.idListSearch(poiIdList)
		.then(poiList => !dontPanMapFlag && mapsdk.panZoomMapToIncludePois(poiList))
}