import React from "react"
import { connect } from "react-redux"
import OverlayContainer from "./OverlayContainer"

const overlays = { }

export function registerOverlay(name, overlayOb)
{
	if(overlays[name])
		throw Error("Invalid redefinition of overlay: " + name)

	overlays[name] = overlayOb
}
/**
 * returns the overlay property specified - allowing for direct values or
 * functions which return the values.
 * @param  {string} name The overlay name
 * @param  {string} [prop] The property name within the overlay object to return
 */
function getOverlayOb(name, prop)
{
	let value = overlays[name]

	if(!value)
		throw Error("No overlay found named " + name)

	if(typeof value === "function")
		value = value()

	if(prop)
		value = value[prop]

	return value
}

const getOverlayComponent = name => getOverlayOb(name, "component")
const getOverlayDoneFn = name => getOverlayOb(name, "doneFn")
const getOverlayProps = name => getOverlayOb(name, "props")
const getOverlayTitle = name => getOverlayOb(name, "title")

let currentComponentInstance = null

function renderComponent(overlayName)
{
	return React.createElement(
			getOverlayComponent(overlayName),
			Object.assign({ref: cbref => (currentComponentInstance = cbref)}, getOverlayProps(overlayName)),
			null)
}

function done(overlayName)
{
	const doneFn = getOverlayDoneFn(overlayName)
	if(doneFn)
		return doneFn.call(currentComponentInstance)

	return true
}

class OverlayMgr extends React.Component {

	render() {

		const { overlayName } = this.props

		return (
				<OverlayContainer
						title={ getOverlayTitle(overlayName) }
						done={ () => done(overlayName) }
						noDone = { getOverlayOb(overlayName, "noDone")}
						body={ renderComponent(overlayName) }
						/>
			)
		}

	componentWillUnmount() {
		currentComponentInstance = null // dereference for cleanup
	}
}

const mapStateToProps = state => ({
		overlayName: state.global.overlayName
	})

OverlayMgr = connect(mapStateToProps)(OverlayMgr)

export default OverlayMgr
