/* eslint-disable no-process-env */
import { createStore, applyMiddleware, compose } from "redux"
import { routerMiddleware } from "react-router-redux"
import { createBrowserHistory } from "history"
import getStateFromStr, { toBase64, filterStateObject } from "./common/route";

import promise from "redux-promise"

// This contains a composition of all our components reducers
import reducers from "./components/reducers"
import { parseQuery } from "./common/utilities"

const middleware = [ promise, routerMiddleware(createBrowserHistory()) ]

// This is necessary to support the redux devtools : https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

let s = null
try {
		s = parseQuery(window.location.search).s
	} catch(e) {
		console.warn("Error while parsing query string", e)
	}

let store = createStore(
		reducers,
		getStateFromStr(s || ""),
		composeEnhancers(applyMiddleware(...middleware))
	)

window.store = store

store.getEncodedTransferableState = () => toBase64(JSON.stringify(filterStateObject(store.getState())))

export default store