import React, { Component } from "react"
import "./search-entry.pcss"
import Icon from "../../common/icon/Icon"
import { submitSearchOpenedEvent } from "../../../extModules/events"
import Input from "../../common/input/Input"
import { dStateSuggestedSearches } from "../../globalState"

/*
	Properties:

		hasFocus
		term
		setSearchTerm
*/


export function reducer(state = {}, action)
{
	return state
}

const showCloseIcon = resetSearch => (
	<Icon
		className="getDirections"
		name="global-icon-close"
		onClick={ resetSearch }
	/>
)

class SearchEntry extends Component {
	state = {
		searchTerm: ""
	}

	maybeConfirmSearch(setSearchTerm) {
		const dStateSS = dStateSuggestedSearches()
		window.dStateSS = dStateSS
		if(dStateSS && dStateSS.promise)
			dStateSS.promise
				.then(ss => {
					if(ss.includes(this.state.searchTerm)) // if there is an exact match, go for it
						setSearchTerm(this.state.searchTerm, true)
					// for(var i = 0; i < ss.length; i++)
					// 	if(ss[i].startsWith(this.state.searchTerm))
					// 	{
					// 		setSearchTerm(ss[i], true)
					// 		i = ss.length // abort the loop
					// 	}
					if(ss.length)
						setSearchTerm(ss[0], true)
				})
	}

	render() {
		let { hasFocus, term, setSearchTerm, wakeUp, setShowDirections, resetSearch, searchEntryPlaceHolder, isMobileWidth, closeSearch, areResultsDisplayed, backFn } = this.props

		// base class for this component
		let classes = [ "search-entry"]

		// set isActive flag to indicate user is interacting with this search
		// let isActive = (hasFocus || term)

		// If we are mobile width, we don't bother "subframing" - but for big screens, we "double-frame"

		//classes.push("bg-primary")

		// if active, give it a border and override the background to remove the double-frame
		//if(isActive || isMobileWidth)
			classes = classes.concat(["-active", "border-active"])

		return (
			<div className={ classes.join(" ") }>
				<div className="inner">
					{
						hasFocus || (term && term.length > 0) ?
							<Icon name="global-icon-back" onClick={ backFn }/> :
							<Icon name="search-icon-search"/>
					}
					<Input
						inputClassName={`text-primary searchInputField ${isMobileWidth ? "" : "bg-primary border-muted  -mobile"}`}
						value={ term || "" }
						onBlur={ () => wakeUp(500) }
						type="text"
						onFocus={ wakeUp }
						onKeyDown= { e => e.keyCode === 13 ? this.maybeConfirmSearch(setSearchTerm) : null }
						onClick={ submitSearchOpenedEvent }
						disabled={ areResultsDisplayed && isMobileWidth }
						placeholder={ term ? "" : searchEntryPlaceHolder} /* shouldn't need to null out placeholder - but it fixes a Safari bug in 10.1 */
						onChange={ (value) => { this.setState({searchTerm: value}); setSearchTerm(value) } }
						autoFocus={isMobileWidth}
						onSubmit={ () => setSearchTerm(this.state.searchTerm, true) }
					/>
					{
						term
							? showCloseIcon(() => areResultsDisplayed ? closeSearch() : resetSearch())
							: isMobileWidth
								? <Icon name="global-icon-close" onClick={ () => closeSearch() }/>
								: <Icon className="getDirections text-accent" name="search-icon-directions" onClick={ () => setShowDirections(true) }/>
					}
				</div>
			</div>
		)
	}
}

export default SearchEntry
