import React from "react"
import Icon from "../common/icon/Icon"
import Input from "../common/input/Input"

export const DialogEntryField = ({ placeholder, iconName, fieldName, onSubmit, onChange, value, autoFocus, type }) => (
	<div className="dialog-entry-field">
		{ fieldName ? <label>{ fieldName }</label> : null }
		<div className="input-wrapper bg-ghosted border-faded">
			{ iconName ? <Icon name={ iconName } /> : null }
			<Input
				containerClassName="keyboard-input"
				placeholder={ placeholder }
				value={ value ? value : "" }
				type={ type }
				autoFocus={ autoFocus }
				onSubmit={ onSubmit }
				onChange={ onChange }
				/>
		</div>
	</div>
)