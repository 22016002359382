class DependencyError extends Error {

	constructor(name = "DependencyError", message = "A module dependency returned an unexpected value.", ...dependencies) {
		super()
		this.name = name
		this.message = message
		this.dependencies = dependencies
	}

}

export {DependencyError}