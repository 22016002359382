/* global locuslabs */
import React from "react"
import { connect } from "react-redux"

import Zousan from "zousan"

import devaluate from "../../../extModules/devaluate"
import { submitStopNavTappedEvent, submitNextNavTappedEvent, submitPrevNavTappedEvent, submitStartNavSearchFieldClearedEvent, submitStopNavSearchFieldClearedEvent } from "../../../extModules/events"
import store from "../../../store"

import NavigationSegments from "./navigationSegments/NavigationSegments"

import * as mapsdk from "../../../common/mapsdk"
import Icon from "../../common/icon/Icon"
import MinimizeButton from "../../common/minimizeButton/MinimizeButton"
import {
    setOverlay,
    getCurrentPhysicalLocation,
    actionSetYouWereHere,
    log as glog,
    getPhysicalInfo
} from "../../globalState"

import { showNavigationDialogAC, getAreaDescription } from "../SearchContainer"

import { getCatBadgeName } from "../searchUtilities"

/* Subcomponents */

import "./navigation-dialog.pcss"

import Message from "../message/Message"
import QRCodeDialog from "./QRCodeDialog"
import { actionSetIsOpenBySearch, actionSetIsOpen } from "../../levelSelector/LevelSelectorContainer"
import LoadIndicator from "../../common/loadIndicator/LoadIndicator"
import NavigationEntry from "./navigationEntry/NavigationEntry"
import { cond, arrayFind } from "../../../common/utilities"
import { t } from "../../../extModules/i18n"
import { debugProp } from "../../../App"
import { registerOverlay } from "../../overlay/OverlayMgr"
import { getConfig } from "../../.."

const log = glog.sublog("NavigationDialog")

/* Actions */
const ACTION_WAKE_UP = "navigation/wakeUp"
const ACTION_SET_POI1 = "navigation/setpoi1"
const ACTION_SET_POI2 = "navigation/setpoi2"
const ACTION_SET_POI1_SEARCH = "navigation/setPoi1Search"
const ACTION_SET_POI2_SEARCH = "navigation/setPoi2Search"
export const ACTION_SWAP_POIS = "navigation/swapPois"
const ACTION_SET_SEG_INDEX = "navigation/setSegIndex"
const ACTION_SET_POI1_FOCUS = "navigation/setPoi1Focus"
const ACTION_SET_NAVIGATION_IS_MINIMIZED = "navigation/navigationIsMinimized"
const ACTION_SET_CURRENT_LOCATION = "navigation/setCurrentLocation"
const ACTION_SET_NAVIGATION_ON_GOING = "navigation/navigationOnGoing"
export function reducer(state = { segIndex: 0 }, action)
{
	if(action.type === ACTION_SET_POI1)
		return Object.assign({}, state, { poiId1: action.poiId1, segIndex: 0 })

	if(action.type === ACTION_SET_POI2)
		return Object.assign({}, state, { poiId2: action.poiId2, segIndex: 0 })

	if(action.type === ACTION_SET_POI1_SEARCH)
		return Object.assign({}, state, { poi1SearchText: action.text, poi1SearchConfirmed: action.confirmed })

	if(action.type === ACTION_SET_POI2_SEARCH)
	return Object.assign({}, state, { poi2SearchText: action.text, poi2SearchConfirmed: action.confirmed })

	if(action.type === ACTION_SET_SEG_INDEX)
		return Object.assign({}, state, { segIndex: action.segIndex })

	if(action.type === ACTION_SWAP_POIS)
		return Object.assign({}, state, {
				poiId1: action.poiId2,
				poiId2: action.poiId1,
				poi1SearchText: action.poi2SearchText,
				poi2SearchText: action.poi1SearchText
		})

	if(action.type === ACTION_WAKE_UP)
		return Object.assign({}, state)

	if(action.type === ACTION_SET_POI1_FOCUS)
		return Object.assign({}, state, { poi1Focus: action.poi1Focus })

	if(action.type === ACTION_SET_NAVIGATION_IS_MINIMIZED)
		return Object.assign({}, state, { navigationIsMinimized: action.navigationIsMinimized })

	if(action.type === ACTION_SET_CURRENT_LOCATION)
		return Object.assign({}, state, { currentLocation: action.currentLocation })

	if(action.type === ACTION_SET_NAVIGATION_ON_GOING)
		return Object.assign({}, state, {navigationOnGoing: action.navigationOnGoing})

	return state
}

// Define our actions
/*const setCurrentLocation = currentLocation => ({
		type: ACTION_SET_CURRENT_LOCATION,
		currentLocation: currentLocation
	})*/

export const setPoi1 = poiId1 => ({
		type: ACTION_SET_POI1,
		poiId1: poiId1
	})

export const setPoi2 = poiId2 => ({
		type: ACTION_SET_POI2,
		poiId2: poiId2
	})

export const setSegIndex = segIndex => ({
		type: ACTION_SET_SEG_INDEX,
		segIndex: parseInt(segIndex, 10)
	})

const setNavigationContainerIsMinimized = navigationIsMinimized => ({
	type: ACTION_SET_NAVIGATION_IS_MINIMIZED,
	navigationIsMinimized: navigationIsMinimized
})

export const setNavigationOnGoing = navigationOnGoing => ({
	type: ACTION_SET_NAVIGATION_ON_GOING,
	navigationOnGoing: navigationOnGoing
})

// These are self-dispatching!

export const wakeUp = (delay = 0) => {
		setTimeout(() => store.dispatch({
		type: ACTION_WAKE_UP
	}), delay)}

const isPoiIdValid = poiId => poiId !== undefined && poiId !== null

// An "active" POI position means that position receives clicked POIs. Also search results relate to that position
// Only 1 position (poi1 or poi2) can be "active" at one time.
export const isPoi1Active = () => !store.getState().navigation.poiId1 && store.getState().navigation.poi1Focus
export const isPoi2Active = () => !store.getState().navigation.poiId2 && !store.getState().navigation.poi1Focus

const dStatePoi1 = devaluate(
		(poiId) => isPoiIdValid(poiId) ? mapsdk.getPOIDetails(poiId) : Zousan.resolve(null),
		() => [ store.getState().navigation.poiId1 ]
	)

const dStatePoi2 = devaluate(
		(poiId) => isPoiIdValid(poiId) ? mapsdk.getPOIDetails(poiId) : Zousan.resolve(null),
		() => [ store.getState().navigation.poiId2 ]
	)

const dStateKioskFloorId = devaluate(
	physicalLocation => physicalLocation ? mapsdk.getFloorIdForPositionOrd(physicalLocation.position, physicalLocation.ordinal) : null,
	() => [ getCurrentPhysicalLocation() ]
)

export const dStateNavData = devaluate(
		(dp1, dp2, physicalLocation, physicalFloorId, isBluedot) => {
			log.info("dStateNavData", dp1, dp2, physicalLocation, physicalFloorId)

			if(physicalLocation && physicalLocation.ordinal === undefined)
				physicalLocation = null

			if((dp1 && dp2) || (dp2 && physicalLocation))
			{
				store.dispatch(actionSetIsOpen(false))
				store.dispatch(actionSetIsOpenBySearch(false))
				store.dispatch(setNavigationOnGoing(true))
				// store.dispatch(actionSetYouWereHere(physicalocation))

				let startPos, endingPos, options = { }

				// destination is always from dp2
				endingPos = Object.assign({ poiId: dp2.poiId}, dp2.position)

				// if we are running in kiosk mode, use the kiosk location for start - and set appropriate options
				if(physicalLocation)
				{
					if(!isBluedot)
						options.hideStartMarker = true // hide the starting marker during navigation since kiosk already has a marker
					startPos = new locuslabs.maps.Position({
							latLng: new locuslabs.maps.LatLng(physicalLocation.position[0], physicalLocation.position[1]),
							floorId: physicalFloorId
						})
				}
				else // otherwise, use dp1 for starting location
					startPos = Object.assign({ poiId: dp1.poiId}, dp1.position)

				return mapsdk.displayNavigation(startPos, endingPos, options)
			}
			else {
				store.dispatch(setNavigationOnGoing(false))
				mapsdk.hideNavigation()
				return null
			}
		},
		() => [ dStatePoi1, dStatePoi2, getCurrentPhysicalLocation(), dStateKioskFloorId, Boolean(store.getState().bluedot.bluedotType) ]
	)

const getPoi1 = state => dStatePoi1().onDerived(wakeUp).value
const getPoi2 = state => dStatePoi2().onDerived(wakeUp).value

export const setPoi1Search = (text, confirmed) => ({ type: ACTION_SET_POI1_SEARCH, text: text, confirmed: Boolean(confirmed) })
export const setPoi2Search = (text, confirmed) => ({ type: ACTION_SET_POI2_SEARCH, text: text, confirmed: Boolean(confirmed) })

const setnavigationIsMinimized = navigationIsMinimized => store.dispatch(setNavigationContainerIsMinimized(navigationIsMinimized))

/*const currentLocationSet = () =>
{
	if(store.getState().navigation.poi1Focus) {
		store.dispatch(setPoi1Search("Current Location"))
	} else {
		store.dispatch(setPoi2Search("Current Location"))
	}
	store.dispatch(setCurrentLocation(store.getState().global.position))
}*/

export const setPoi1Focus = focus => store.dispatch({type: ACTION_SET_POI1_FOCUS, poi1Focus: focus})

export function closeNavigation()
{
	submitStopNavTappedEvent()
	store.dispatch(showNavigationDialogAC(false))
	store.dispatch(setPoi1(null))
	store.dispatch(setPoi2(null))
	store.dispatch(setPoi1Search(null))
	store.dispatch(setPoi2Search(null))
	store.dispatch(setNavigationOnGoing(false))
}

function segmentClick(e)
{
	const segIndex = e.currentTarget.dataset.index
	mapsdk.displayNavigationSegment(segIndex)
	store.dispatch(setSegIndex(segIndex))
}

function prevSegClicked(segIndex)
{
	submitPrevNavTappedEvent()
	if(segIndex > 0)
	{
		mapsdk.displayNavigationSegment(segIndex - 1)
		store.dispatch(setSegIndex(segIndex - 1))
	}
}

function nextSegClicked(segIndex, totalSegCount)
{
	submitNextNavTappedEvent()
	if(segIndex < totalSegCount - 1)
	{
		mapsdk.displayNavigationSegment(segIndex + 1)
		store.dispatch(setSegIndex(segIndex + 1))
	}
}

function ensureSegmentVisible()
{
		const currentSegment = document.querySelector(".navigation-segments > .-current")
		if(currentSegment)
			scrollIntoView(currentSegment)
}

function scrollIntoView(target)
{
	target.parentNode.scrollTop = target.offsetTop - target.parentNode.offsetTop
}

const getCurrentlyActiveSearchTerm = () =>
		isPoi1Active() ? store.getState().navigation.poi1SearchText :
			(isPoi2Active() ? store.getState().navigation.poi2SearchText : null)
const hasNavigationSegments = (navData, segmentList) => navData && segmentList && segmentList.length > 0

export const dStateSuggestedSearches = devaluate(
	term => mapsdk.autocomplete(term ? term.trim() : term),
	() => [ getCurrentlyActiveSearchTerm() ]
)

export const dStateSuggestedLocations = devaluate(
	term => mapsdk.proximitySearch(term ? term.trim() : term, true, true),
	() => [ getCurrentlyActiveSearchTerm() ]
)

const getSuggestedSearches = () => {
	// dStateSuggestedSearches().onDerived(wakeUp).value
	const dsss = dStateSuggestedSearches()
	dsss.onDerived(wakeUp)
	if(!dsss.isCurrent)
		return "loading"
	else {
		return dsss.value
	}
}

const getSuggestedLocations = () => {
	const dssl = dStateSuggestedLocations()
	dssl.onDerived(wakeUp)
	if(!dssl.isCurrent)
		return "loading"
	else {
		return dssl.value
	}
}

function renderPOINavigationInput(poi, editFn, kioskMode)
{
	if(!poi)
		return null

	const iconName = getCatBadgeName(poi),
		primaryText = poi.name,
		secondaryText = poi.terminal + " / " + poi.gate

	return renderDirectionEndpoint(poi.poiId, iconName, primaryText, secondaryText, editFn, kioskMode)
}

function renderDirectionEndpoint(id, iconName, primaryText, secondaryText, editFn, kioskMode)
{
	return (
		<div key={ id } data-id={ id } className="poi">
			<Icon name={ iconName } />
			<div className="textLabel">
				<div className="title">{ primaryText }</div>
				<div className="desc text-muted">{ secondaryText }</div>
			</div>
			{
				kioskMode || !editFn
				? null
				: <Icon name="dir-icon-edit" onClick={ editFn } className="_pull-right" />
			}
		</div>
	)
}

/* JSX */

const renderFilledNavInputs = (poi1, poi2, startTextOverride, kioskMode) => {
	return (
	<div className="pois-selected bg-ghosted">
		{ startTextOverride
			? renderDirectionEndpoint("CL", startTextOverride.iconName, startTextOverride.primaryText, startTextOverride.secondaryText, null, kioskMode)
			: renderPOINavigationInput(poi1, resetSearchPoi1, kioskMode)
			}
		<Icon name="dir-icon-stepbysteparrow" className="_pull-left"/>
		{ renderPOINavigationInput(poi2, resetSearchPoi2, kioskMode) }
	</div>
	)
}

const renderNavSummary = (timeDisplay, totalMeters, totalLevels, securitySegment) => {

	let meterCount = Math.round(totalMeters)
	let meterString = t("directions:meterWithCount", {count: meterCount})
	let footCount = Math.round(totalMeters * 3.28084)
	let footString = t("directions:footWithCount", {count: footCount})

	// Note: the following line is insufficient for truly determining isLiveSec. We should be
	// considering the flags on the corresponding security POI - as we do on POIDetails.js
	const isLiveSec = getConfig().hasDynamicPOIs && !getConfig().disableLiveSecurity

	return (
		<div className="summary bg-primary" key="summary">
			<Icon name="dir-icon-routetime"/>
			<div className="textLabel">
				<div className="timeAndFloors">
					<span className="time text-primary"> {timeDisplay} </span>
				</div>
				<div className="distance">
					({ meterString } / { footString })
				</div>
                {
                    securitySegment && isLiveSec ?
                        <div className="security-time">
                            { t("directions:minute-security-wait-time", { count: securitySegment.estimatedTime ? Math.ceil(securitySegment.estimatedTime) : 30}) }
                        </div> : null
                }
			</div>
		</div>
	)
}

const renderNavSummaryMobile = (timeDisplay, totalMeters, totalLevels, securitySegment, poi2) => (
			<div className="summary bg-primary" key="summary">
				<Icon name="global-icon-back" onClick={ () => { resetSearchPoi2(); resetNavigationSearch() } }/>
				<div className="summary-info">
					{ poi2 ? <span className="title">{poi2.name}</span> : null }
					<span className={ (securitySegment ? "subtitle-security-mobile" : "subtitle") }>{ timeDisplay }</span>
                    { securitySegment && !getConfig().disableLiveSecurity ? <span className="security-time">{ t("directions:minute-security-wait-time", { count: securitySegment.estimatedTime ? Math.ceil(securitySegment.estimatedTime) : 30}) }
</span> : null }
				</div>
				{ poi2 ? <Icon name={`poi-badge-${poi2.category}`} /> : null }
			</div>
		)

const renderPrevNext = (segIndex, totalSegCount) => (
		<div className="button prevNext bg-secondary-button text-secondary-button border-secondary" key="prevNext">
			<div className={"previous" + (segIndex <= 0 ? " -ghosted text-muted" : "")} onClick={ () => prevSegClicked(segIndex)}>
				<Icon name="dir-icon-previous" />
				{t("common:Previous")}
			</div>
			<div className={"next" + (segIndex >= totalSegCount - 1 ? " -ghosted text-muted" : "")} onClick={ () => nextSegClicked(segIndex, totalSegCount) }>
				{t("common:Next")}
				<Icon name="dir-icon-next" />
			</div>
		</div>
	)

const renderNavigationSegments = (segmentList, segIndex, totalSegCount, navigationIsMinimized, isMobileWidth) => (
			<NavigationSegments
				segmentList={ segmentList }
				onClick={ segmentClick }
				segIndex={ segIndex }
				totalSegCount={ totalSegCount }
				navigationIsMinimized= {navigationIsMinimized && !isMobileWidth}
				key="segments"
			/>
		)

// const routeSelection = () => (
// 	<div className="routeSelection" key="routeSelection">
// 		<div className="fastestRoute button bg-active-button text-active-button">
// 			<Icon name="dir-icon-fastestpath bg-active" />
// 			<div className="textLabel text-muted">Fastest</div>
// 		</div>
// 		<div className="fastestRoute button bg-disabled-button text-disabled-button">
// 			<Icon name="dir-icon-accessiblepath" />
// 			<div className="textLabel text-muted">Accessible</div>
// 		</div>
// 	</div>
// )

// const prop = (ar, pn) => ar.map(o => o[pn]) // convert an array of objects to an array of the values identified by the property name
// const hasValue = (ar, val) => ar.indexOf(val) >= 0 // returns true if the array passed contains the value passed (note: post IE11 this is part of JS)
// const isThroughSecurity = segmentList => hasValue(prop(segmentList, "type"), "Security Checkpoint")
const getSecuritySegment = segmentList => arrayFind(segmentList, seg => seg.type === "Security Checkpoint" ? seg : undefined)

const showQR = () => store.dispatch(setOverlay("qrCode"))

// When using in "Kiosk" mode (MapsOnsite) and we want to "hand off" the URL to a user, redirect them
// to the configured onsiteHandoffURL page which is NOT in kiosk mode, has no attract loop, etc.
const getMapsOnlineURL = url => getConfig().onsiteHandoffURL + url.substring(url.indexOf("?"))

registerOverlay("qrCode", () => ({ component: QRCodeDialog, props: { url: getMapsOnlineURL(document.location.href) }, title: t("directions:Send To Mobile")}))

const renderTransferButton = () => (
    <div className="transferButton" key="transferButton">
        <div className={ "button text-active-button bg-active-button" } onClick={ showQR }>
            <Icon name="phone-icon-sending" />
            <div className="text-label">
                { t("directions:Send To Mobile") }
            </div>
        </div>
    </div>
)

const renderNavData = (timeDisplay, totalMeters, totalLevels, segmentList, segIndex, totalSegCount, navigationIsMinimized, isMobileWidth) => [
			//routeSelection(), disable until the feature is ready
			renderNavSummary(timeDisplay, totalMeters, totalLevels, getSecuritySegment(segmentList)),
			getConfig().kioskMode ? renderTransferButton() : null,
			renderPrevNext(segIndex, totalSegCount),
			renderNavigationSegments(segmentList, segIndex, totalSegCount, navigationIsMinimized, isMobileWidth)
		]

export function resetNavigationSearch()
{
	submitStopNavTappedEvent()
	resetSearchPoi2()
	resetSearchPoi1()

	// if we have a known start position, close out the navigation inputs box
	if(getPhysicalInfo())
		store.dispatch(showNavigationDialogAC(false))
}

export function resetSearchPoi1()
{
	submitStartNavSearchFieldClearedEvent()
	store.dispatch(showNavigationDialogAC(true))
	store.dispatch(setPoi1(null))
	store.dispatch(setPoi1Search(""))
	setPoi1Focus(true)
}

export function resetSearchPoi2()
{
	if(store.getState().navigation.navigationOnGoing) closeNavigation()
	store.dispatch(showNavigationDialogAC(false))
	submitStopNavSearchFieldClearedEvent()
	store.dispatch(showNavigationDialogAC(true))
	store.dispatch(setPoi2(null))
	store.dispatch(setPoi2Search(""))
	setPoi1Focus(false)
}

// Note: eventually the top Route Not Available message should probably show for all physicallyKnown starting locations - but Matt requested we wait for that
const renderNoRouteFoundMessage = (showResetButton, kioskLocation)=> {
	const poiId1 = store.getState().navigation.poiId1,
		poiId2 = store.getState().navigation.poiId2,
		kioskMode = getConfig().kioskMode
	return (((poiId1 && poiId2) || (kioskLocation && poiId2)) ?
		<div className="bg-primary">
			{ kioskMode
				? <Message icon="search-icon-nomatches" title={t("directions:Route Not Available")} advice={t("directions:not-from-current-location")}/>
				: <Message icon="search-icon-nomatches" title={t("directions:Route Not Available")} advice={t("directions:not-from-this-start-point")}/>
			}
			{showResetButton ? <div className="resetSearch">
									<div className="button bg-active-button text-active-button -full" onClick={ resetNavigationSearch }>
										{t("common:back")}
									</div>
								</div> : null}
	</div> : null)
}

export const renderBusyIndicator = () => (
		<div className="computingNotification bg-primary">
			<LoadIndicator message={t("navigation:Calculating Directions")}/>
		</div>
	)

const renderTitle = () => (
		<div className="navigation-title">
			<Icon name="dir-badge-directions" />
			<div className="title">
				{t("directions:Get Directions")}
			</div>
			<Icon name="global-icon-close" onClick={ closeNavigation }/>
		</div>
	)

let NavigationStepByStep = ({ navData, segmentList, segIndex, totalSegCount, timeDisplay, totalLevels, totalMeters, navigationIsMinimized, isMobileWidth, poi1, poi2, startTextOverride, kioskMode }) => (
	<div className="navigation-stepByStep">
		{ renderFilledNavInputs(poi1, poi2, startTextOverride, kioskMode) }
		{
			hasNavigationSegments(navData, segmentList)
				? renderNavData(timeDisplay, totalMeters, totalLevels, segmentList, segIndex, totalSegCount, navigationIsMinimized, isMobileWidth)
				: navData === "computing"
					? renderBusyIndicator()
					: renderNoRouteFoundMessage(true, getCurrentPhysicalLocation())
		}
	</div>
)

let MobileNavigationStepByStep = ({ navData, segmentList, segIndex, totalSegCount, timeDisplay, totalLevels, totalMeters, navigationIsMinimized, isMobileWidth, poi2, areaDescription }) => {
	const securitySegment = getSecuritySegment(segmentList)
    return (
	<div className="navigation-stepByStepMobile">
		{ renderNavSummaryMobile(timeDisplay, totalMeters, totalLevels, securitySegment, poi2) }
		{
			cond(areaDescription,
					<div className="mobileLevelIndicator bg-secondary text-secondary">{ areaDescription }</div>)
		}
		<div style={{bottom: "0", position: "fixed", width: "100%"}}>
			{ hasNavigationSegments(navData, segmentList) ? renderNavigationSegments(segmentList, segIndex, totalSegCount, navigationIsMinimized, isMobileWidth) : navData === "computing" ? renderBusyIndicator() : renderNoRouteFoundMessage(true, getCurrentPhysicalLocation()) }
			{ renderPrevNext(segIndex, totalSegCount) }
		</div>
	</div>
)}

class NavigationDialog extends React.Component {

	render() {

		let { dispatch, poi1, poi2, poi1Focus, poi1SearchText, poi2SearchText, startTextOverride, navData, segmentList, segIndex, totalSegCount, timeDisplay, totalLevels, totalMeters, suggestedSearches, suggestedLocations, navigationIsMinimized, isMobileWidth, areaDescription, kioskMode } = this.props

		return (
			<div className={ "navigation-dialog" + (isMobileWidth ? " -mobileFull" : " -floatingContainer -mainDialog bg-primary") }>

				{ cond(!isMobileWidth, renderTitle()) }
				{
					!navData ?
						<NavigationEntry
								dispatch={dispatch}
								close={closeNavigation }
								poi1={poi1}
								poi2={poi2}
								poi1SearchText={poi1SearchText}
								poi2SearchText={poi2SearchText}
								suggestedSearches={suggestedSearches}
								suggestedLocations={suggestedLocations}
								isMobileWidth={isMobileWidth}
								poi1Focus={ poi1Focus }
								showNoRouteMessage={!hasNavigationSegments(navData, segmentList)}
							/> :
						!isMobileWidth ?
							<NavigationStepByStep
									poi1={poi1 }
									poi2={poi2}
									startTextOverride={ startTextOverride }
									navData={navData}
									segmentList={segmentList}
									segIndex={segIndex}
									totalSegCount={totalSegCount}
									timeDisplay={timeDisplay}
									totalLevels={totalLevels}
									totalMeters={totalMeters}
									navigationIsMinimized={navigationIsMinimized}
									isMobileWidth={isMobileWidth}
									kioskMode={ kioskMode }
								/> :
							<MobileNavigationStepByStep
									poi2={poi2}
									navData={navData}
									segmentList={segmentList}
									segIndex={segIndex}
									totalSegCount={totalSegCount}
									timeDisplay={timeDisplay}
									totalLevels={totalLevels}
									totalMeters={totalMeters}
									navigationIsMinimized={navigationIsMinimized}
									isMobileWidth={isMobileWidth}
									areaDescription={ areaDescription }
								/>
					}
				{
					cond(hasNavigationSegments(navData, segmentList) && !isMobileWidth,
						() => {
							ensureSegmentVisible()
							return <MinimizeButton isOpen={navigationIsMinimized} onOpen={setnavigationIsMinimized}/>})
				}
				</div>
			)
	} // end render

	shouldComponentUpdate(nextProps, nextState)
	{
		// await our asyncronous devaluations before bothering to update
		// return dStateArea().isCurrent && dStateNavData().isCurrent
		return true
	}

} // end component class

const mapStateToProps = state => {

		const navi = dStateNavData().onDerived(wakeUp).value

		const physiLocation = getCurrentPhysicalLocation()
		let startTextOverride = null,
			hasFixedStartPosition = Boolean(physiLocation) && (physiLocation.ordinal !== undefined)

		if(hasFixedStartPosition)
			startTextOverride = {
					iconName: "poi-icon-pin",
					primaryText: t("directions:current-location"),
					// secondaryText: navi ? t("directions:near-location", { location: navi.getSegments()[0].primaryText }) : ""
					secondaryText: ""
				}

		const propMap = {
				poi1: getPoi1(state),
				poi2: getPoi2(state),
				startTextOverride: startTextOverride,
				poi1SearchText: state.navigation.poi1SearchText,
				poi2SearchText: state.navigation.poi2SearchText,
				segIndex: state.navigation.segIndex,
				suggestedSearches: getSuggestedSearches(),
				suggestedLocations: getSuggestedLocations(),
				venueId: mapsdk.getVenueId(),
				poi1Focus: state.navigation.poi1Focus,
				isMobileWidth: state.global.isMobileWidth,
				areaDescription: getAreaDescription(),
				kioskMode: getConfig().kioskMode
			}

		if(navi)
		{
			const segmentList = navi.getSegments()

			const totalTime = Math.round(navi.totalTime)
			let timeDisplay = t("navigation:timeEstimateWithCount_interval", {postProcess: "interval", count: totalTime})

			const securitySegment = getSecuritySegment(segmentList)

			Object.assign(propMap, {
				navData: navi,
				segmentList,
				totalSegCount: segmentList.length,
				totalTime: totalTime,
				timeDisplay: timeDisplay,
				totalMeters: Math.round(navi.totalDistance),
				totalLevels: navi.totalLevels,
				navigationIsMinimized: typeof state.navigation.navigationIsMinimized === "undefined" ? true : state.navigation.navigationIsMinimized
			})
		}
		// if we have both endpoints defined, but still no navi object, it must be computing...
		else //if((dp1 && dp2) || (dp2 && kioskLocation))
			if(state.navigation.poiId2 && (state.navigation.poiId1 || hasFixedStartPosition))
				propMap.navData = "computing"	// flag to indicate navigation is computing

		return propMap
	}

NavigationDialog = connect(mapStateToProps)(NavigationDialog)

export default NavigationDialog
