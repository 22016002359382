import React from "react"

import Icon from "../common/icon/Icon"
import store from "../../store"
import {setLanguage, getCurrentLanguage} from "../../extModules/i18n"
import {resetApplication} from "../reducers"
import { getConfig } from "./../.."

import "./language-selector.pcss"
import { displayDefaultMapView } from "../globalState"

// Expand the following once we need to
const langRefs = {
		"de": "Deutsche", // German
		"en": "English",
		"en-US": "English (US)",
		"es": "Español", // Spanish
		"es-MX": "Español Mexicano", // Mexican Spanish
		"fr": "Français", // French
		"fr-CA": "Français Canadien", // Canadian French
		"ru": "Pусский", // russian
		"it": "italiana", // Italian
		"zh-Hans": "中文（简体）", // Chinese (simplified)
		"zh-Hant": "中文（繁體）" // Chinese (Traditional)
	}

// will be called in the context of the component instance
export function whenDone()
{
	// setLanguage(this.state.language)
	// store.dispatch(resetApplication())
	// displayDefaultMapView()
	window.location = `${window.location.href.substring(0, window.location.href.indexOf("?") + 1)}lang=${this.state.lang}&vid=${this.state.venueId}`
}

class LanguageSelector extends React.Component {

	constructor() {
		super()
		const myLang = getCurrentLanguage()
		this.state = { lang: getCurrentLanguage(), venueId: window.locuslabs.JSW_config.venueId }
	}

	render() {

		const availableLanguages = getConfig().availableLanguages || ["en_US"]

		const languageList = availableLanguages.map(languageOb => {
			const { lang, venueId } = languageOb
			let languageString = langRefs[lang]
			let iconState = lang === this.state.lang ? "control-icon-toggleselected" : "control-icon-toggle"
			return <li className="list-item menu-item" data-language={lang} key={lang} onClick={
				() => this.setState(state => ({ ...state, lang, venueId })) } >{languageString}<Icon name={iconState}/></li>
		})

		return (
				<ul className="language-selector">
					{languageList}
				</ul>
		)
	}
}

export default LanguageSelector