// Here I will add all the helper functions for creating the fun (and profitable) material
// animation flourishes!

import { ad } from "./domTools"

/*
	In conjunction with CSS, this creates an animated ripple effect on material buttons
*/
export function ripple(e)
{
	const node = e.currentTarget
	rippleNode(node, e)
}

export function rippleNode(node, e)
{
	const bc = node.getBoundingClientRect(),
		x = e.pageX - bc.left - 5,
		y = e.pageY - bc.top - 5,
		rip = ad(node, { klass: "-ripple bg-muted", styles: { left: x + "px", top: y + "px" }})

	setTimeout(() => { rip.remove() }, 2000) // Remove this div after 2 seconds..
}

window.ripple = ripple
window.rippleNode = rippleNode
