import React from "react"
import Icon from '../common/icon/Icon'
import "./mobileSearch-header.pcss"
import {t} from "../../extModules/i18n"

/*
	Properties:

		setShowSubmenu : handler for submenu icon
		setShowDirections : handler for directions icon
		setShowSearch : handler for magnifying glass icon
		logoClicked : handler for clicking logo
		areaDescription : String representing the area (building/level/etc) - or null if N/A
*/

const MobileSearchHeader = ({ setShowSubmenu, setShowDirections, logoClicked, setShowSearch, areaDescription, suppressLogoFlag }) => {

			return (
				<div className="mobileSearch-Header bg-mobile-header text-alt">
					<div className="topHeader">
						{ !suppressLogoFlag ? <Icon name="global-icon-logo" onClick={ logoClicked } /> : null }
						<Icon name="search-icon-search" onClick={ () => setShowSearch(false)} />
						<Icon className="getDirections" name="search-icon-directions" onClick={ () => setShowDirections(true) }/>
						<Icon name="search-icon-submenu" onClick={ () => setShowSubmenu(true)} />
					</div>
					<div className="mobileLevelIndicator bg-secondary text-secondary"> { areaDescription ? areaDescription : t("tooltip:Zoom to explore the map…") } </div>
				</div>
			)
}

export default MobileSearchHeader