import React from "react"
import Icon from "../../common/icon/Icon"
import "./search-categories.pcss"
import {t} from "../../../extModules/i18n"
import { getLangSpecificConfigParm } from "../../.."

function renderSearchCategory(suggestion, onClick)
{
	return (
		<div className="suggestion text-subdued border-primary bg-primary bg-faded-hover" onClick={ onClick } key={ suggestion } data-suggestion={ suggestion }>
			<Icon name="global-icon-searchrow" />
			{ suggestion }
		</div>
	)
}

const renderSuggestions = (suggestions, onClick) => {
		return (
			<div className="suggestionsList -rippleList">
				{ suggestions.map(s => renderSearchCategory(s, onClick)) }
			</div>
		)
}

const trim = s => s.trim()

const SearchCategories = ({ onClick }) => {

	const defaultSuggestedSearches = t("data:default-search-suggestions") // restroom, hamburger, ATM, phone charger, currency exchange, lounge

	let suggestedSearches = getLangSpecificConfigParm("suggestedSearches", defaultSuggestedSearches)

	if(suggestedSearches) // only null if explicitly nulled within config - if undefined, will get defaults
	{

		const renderTitle = <div className="title text-secondary bg-secondary">{t("search:SUGGESTED SEARCHES")}</div>

		suggestedSearches = suggestedSearches.split(",").map(trim)

		return (
			<div className="search-categories">
				{ suggestedSearches && suggestedSearches.length ? renderTitle : "" }
				{ suggestedSearches && suggestedSearches.length ? renderSuggestions(suggestedSearches, onClick) : "" }
			</div>
		)
	}

	return null
}

export default SearchCategories
