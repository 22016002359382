import React from "react"
import "./round-label.pcss"
import {t} from "../../../extModules/i18n"

const RoundLabel = ({count}) => {
	return (<div className={"round-label bg-accent2 text-secondary _textOneLine"}>{t("search:resultWithCount", {count})}</div>)
}

export default RoundLabel

