import React from "react"
import { setFlightDetailStatus, setFlightElement } from "./FlightStatusContainer"
import store from "../../store"

import { ripple } from "../../extModules/material"
import { loadFlight } from "./FlightDetailView"

import { t } from "../../extModules/i18n"
import Icon from "../common/icon/Icon"

function renderFlightDetails(e, flight) {
	ripple(e)
	store.dispatch(setFlightDetailStatus(true))
	loadFlight(flight)
	store.dispatch(setFlightElement(flight))
}

const statusFlightClasses = (f) => {
	return (f.isUnknown() || f.hasArrived() || (f.hasDeparted() && !f.isArrival)) ? " grayBadge" : (f.isCanceled() || (f.isArrival && f.isArrivalDelayed()) || (!f.isArrival && f.isDepartureDelayed())) ? " redBadge" : "" }

export const getFlightStatusClass = f => {
		if(f.isUnknown())
			return "-unknown"
		if(f.isCanceled())
			return "-cancelled"
		if(f.isArrival)
		{
			if(f.hasArrived())
				return "-arrived"
			if(f.isArrivalDelayed())
				return "-delayed"
			if(f.isArrivalEarly())
				return "-early"
			}
		else
		{
			if(f.hasDeparted())
				return "-departed"
			if(f.isDepartureDelayed())
				return "-delayed"
			if(f.isDepartureEarly())
				return "-early"
		}

		return "-ontime"
	}

export const getStatusPillText = (f) => {

	if(f.isUnknown())
		return t("flights:unknown")
	if(f.isCanceled())
		return t("flights:cancelled")
	if(f.hasDeparted() && !f.isArrival)
		return t("flights:departed")
	if(f.hasArrived())
		return t("flights:arrived")

	// if(f.isArrival && f.isArrivalEarly())
	// 	return t("flights:EARLY-mins", { mins: f.getEarlyArrivalMinutes() })
	// else if(!f.isArrival && f.isDepartureEarly())
	// 	return t("flights:EARLY-mins", { mins: f.getEarlyDepartureMinutes() })
	if((f.isArrival && f.isArrivalEarly()) || (!f.isArrival && f.isDepartureEarly()))
		return t("flights:Early")

	// if(f.isArrival && f.isArrivalDelayed())
	// 	return t("flights:DELAYED-mins", { mins: f.getDelayedArrivalMinutes() })
	// else if( !f.isArrival && f.isDepartureDelayed())
	// 	return t("flights:DELAYED-mins", { mins: f.getDelayedDepartureMinutes() })
	if((f.isArrival && f.isArrivalDelayed()) || ( !f.isArrival && f.isDepartureDelayed()))
		return t("flights:Delayed")

	return t("flights:on-time")
}

const FlightStatusListElement = ({flight}) => (

	<div className="popup-holder" onClick={e => renderFlightDetails(e, flight)}>
		<a className="flight-item open">
			<div className="row">
				<div className="left-col">
					<strong className="flight-title"><mark>{flight.isArrival ? t("flights:From") : t("flights:To") }</mark>
						{ flight.isArrival ? flight.airportCityOrigin + " (" + flight.airportCodeOrigin + ")" :
						flight.airportCityDestination + " (" + flight.airportCodeDestination + ") " }</strong>
					<span className="flight-num">{ flight.airlineName } - { flight.airlineCode } {flight.flightNumber}</span>
					<div className={ "statusFlight " + getFlightStatusClass(flight) }>{ getStatusPillText(flight) }</div>
					{
						flight.terminalInformation ?
						<div className="statusFlight _textOneLine" style={{float:'right', backgroundColor:'#555'}}>Terminal {flight.terminalInformation}</div> :
						null
					}
				</div>
			</div>
			<div className="row">
				<div className="left-col">
					<div className="col-title">{ flight.isArrival ? t("flights:arrives") : t("flights:departs") }</div>
					{
						flight.isArrival && flight.isFlightOnTime()
							? <span className="hourDeparture"><time>{ " " + flight.getEstimatedArrivalTimeFormatted() }</time></span>
							: null
					}
					{
						flight.isArrival && !flight.isFlightOnTime()
						?	<div>
								<span className="line-through"><time>{ flight.getScheduledArrivalTimeFormatted() } </time></span>
								&nbsp;
								<span className="hourDeparture"><time>{ " " + flight.getEstimatedArrivalTimeFormatted() }</time></span>
							</div>
						: null
					}
					{
						!flight.isArrival && flight.isFlightOnTime()
						? <span className="hourDeparture"><time>{ flight.getEstimatedDepartureTimeFormatted() }</time></span>
						: null
					}
					{
						!flight.isArrival && !flight.isFlightOnTime()
						?
							<div><span className="line-through"><time>{ flight.getScheduledDepartureTimeFormatted() }</time></span>
								&nbsp;
								<span className="hourDeparture"><time>{ " " + flight.getEstimatedDepartureTimeFormatted() }</time></span>
							</div>
						: null
					}
					</div>
					{
						!flight.isArrival && flight.departureGate
						?
							<div className="right-col">
								<span className="col-title">{ t("flights:gate") }</span>
								<span className="gate-num">{ flight.isArrival ? flight.arrivalGate : flight.departureGate }</span>
							</div>
						: null
					}
				</div>
				{
					flight.arrivalBagClaim
					?
						<div className="bagclaimnum"><Icon name="bagclaim" />
							Collect luggage from Baggage Claim { flight.arrivalBagClaim }
						</div>
					: null
				}
		</a>
	</div>
)

export default FlightStatusListElement