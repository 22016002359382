import React from "react"
import Icon from "../common/icon/Icon"

const ZoomSelector = ({zoomIn, zoomOut, zoomInputChanged, value}) =>
		<div className="zoom-selector text-primary bg-primary">
			<Icon onClick={zoomOut} className="zoomOut" name="control-icon-zoomout" />
			<input
					type="range"
					onChange={ e => zoomInputChanged(parseInt(e.target.value, 10)) }
					value={value}
				/>
			<Icon onClick={zoomIn} name="control-icon-zoomin" />
		</div>

export default ZoomSelector
