import React from "react"

import "./overlayContainer.pcss"
import store from "../../store"
import {setClearOverlay} from "../globalState"

export const clearOverlay = () => store.dispatch(setClearOverlay())

function handleClick(event)
{
	if(event.target.classList.contains("overlay-container"))
		clearOverlay()
}

function done()
{
	if(this.props.done)
	{
		const ret = this.props.done()
		if(ret === false) // if the done function returns false, the overlay is not cleared
			return
	}

	clearOverlay()
}

class OverlayContainer extends React.Component {

	render() {

		const { title, noDone } = this.props

		return (
				<div className="overlay-container" onClick={ handleClick }>
					<div className="overlay-content text-primary bg-primary">
						{ title ? <div className="overlay-title bg-ghosted">{ title }</div> : null }
						{
							this.props.body
						}
						{ !noDone ? <div className="button bg-active-button text-active-button" onClick={ done.bind(this) }>done</div> : null }
					</div>
				</div>
			)
		}
}

export default OverlayContainer
