import React from "react"
import Icon from "../../../common/icon/Icon"
import PoiImages from "./poiImages/PoiImages"

import { submitPoiPhoneTappedEvent, submitPoiWebsiteTappedEvent, submitPoiMenuTappedEvent } from "../../../../extModules/events"
import { t } from "../../../../extModules/i18n"

import { cond } from "../../../../common/utilities"
import Tag from "../../../common/tag/Tag"
import * as mapsdk from "../../../../common/mapsdk"
import { debugProp } from "../../../../App"

import "./poi-details.pcss"
import { getConfig } from "../../../.."
import ParkingStatus from "./ParkingStatus"

var marker = null

export function cleanPOIMarker() {
	if(marker) {
		marker.then(marker => marker.setMap(null))
		marker = null
	}
}

const makeDisplayReady = url => url.match(/^(.*:\/\/)*(.*?)(\/)*$/)[2] /* regexp to strip protocol and ending slash */

const openMenu = url => window.open(url, "menu")

const showTimeToTerminal = timeToTerminal => (
		timeToTerminal && (timeToTerminal.includes("walk") || timeToTerminal.includes("shuttle"))
		? (
			<div className="iconInfo border-primary">
				<Icon name={ timeToTerminal.includes("walk") ? "poi-icon-walking" : "poi-icon-bus" }/>
				<div className="info">{ timeToTerminal }</div>
			</div>
			)
		: null
)

const renderParkingHeader = details => (
	<div className="poi-header-layout">

		<ParkingStatus details={ details } />

		<div className="iconInfo border-primary">
			<Icon name="poi-icon-coins" />
			<div className="info">
				{ details.rateDay} / { details.rateHour }
			</div>
		</div>

		{ showTimeToTerminal(details.timeToTerminal1) }
		{ showTimeToTerminal(details.timeToTerminal2) }

	</div>
)

const renderNormalHeader = details => (
	<div className="primaryInfo">
		<div className="iconInfoGroup">
			<div className="iconInfo border-primary">
				<Icon name="poi-icon-level" />
				<div className="info"> { details.level.name} - { details.level.details } </div>
			</div>
			<div className="iconInfo border-primary">
				<Icon name="poi-icon-pin" />
				<div className="info"> { details.terminal} / { details.gate} </div>
			</div>
			{
				details.additionalAttributes && details.additionalAttributes.isSecurityCheckpoint
					? null
					: (
						<div className="iconInfo border-primary">
							<Icon name="poi-icon-security" />
						<div className="info"> { details.position.isAfterSecurity ? t("poi:After Security") : t("poi:Before Security") } </div>
						</div>
					)
			}
		</div>
	</div>
)

const renderSecurityStatus = details => (

			<div className="poi-header-layout">
			{
				details.isClosed
					? <div className="round-label bg-important text-important -bmp5em">t("common:Closed")</div>
					: <div className="round-label bg-important text-important -bmp5em">
							<div className="liveDot -inline">
									<Icon name="live-dot" />
							</div>
							{ t("poi:live-min-wait", { minutes: details.time}) }
						</div>
			}
			</div>
)

const PoiDetails = ({ details, imgLoaded, getDirectionsClicked, displayTagClicked, operatingHoursClicked }) => {

	const isLiveSec = details.additionalAttributes && details.additionalAttributes.isSecurityCheckpoint && details.time >= 0 && !getConfig().disableLiveSecurity && details.timeIsReal

		if(isLiveSec)
		{
			if(!marker)
				marker = mapsdk.drawMarker({ position: details.position, icon: details.isClosed ? "poi-badge-security-red-badge" : "poi-badge-security"})
		}

		// Commenting this out to see if anyone notices..
		// if(details.category === 'parking' && debugProp("parking-demo"))
		// {
		// 	if(!marker)
		// 		marker = mapsdk.drawMarker({ position: details.position, icon: "poi_badge_parking"})
		// }

		return (

			<div className="poi-details scrolling-container">

				{
					isLiveSec
						? renderSecurityStatus(details)
						: null
				}

				{
						details.category === "parking" && details.timeIsReal
							? renderParkingHeader(details)
							: renderNormalHeader(details)
				}

				{ cond(details.image, <PoiImages details={ details } imgLoaded={ imgLoaded }/>) }

				{
					cond(
						isLiveSec,
						<div className="image-container">
						</div>
					)
				}
				<div className="primaryInfo">
					{ // Get Directions (show navigation)
						details.noDirections  // if there the noDirections flag is set, skip this section
							? null
							: (
								<div className="button bg-active-button text-active-button -full" onClick={ getDirectionsClicked }>
									<Icon name="poi-icon-getdirections" />
									<div className="text-label">
										{t("poi:Get Directions")}
									</div>
								</div>
							)
					}

					{  // Menu Link
						cond(
							details.additionalAttributes && details.additionalAttributes.menu && !getConfig().kioskMode,
							() => (
								<div className="button border-primary -full -secondary" onClick={ () => { submitPoiMenuTappedEvent.bind(null, details.poiId); openMenu(details.additionalAttributes.menu) }}>
									<Icon name="poi-icon-menu"/>
									<div className="text-label">
										{ t("poi:View Menu") }
									</div>
								</div>))
					}
					{ // Description
						cond(details.description, <div className="description">{ details.description }</div>)
					}
					{ // Display Tags
						cond(
							details.displayTags && details.displayTags.length,
							() => <div>
								{
									details.displayTags.map(tag =>
											<Tag key={ tag } keyword={ tag } onClick={ displayTagClicked }/>
										)
								}
							</div>)
					}

				</div> { /* end of "primaryInfo" div */ }

				{
					/* Now, if there is any extra details that go into the "Information" section, then render the title and relevant details */
					cond(
						details.rawHours || details.phone || details.url || (details.additionalAttributes && details.additionalAttributes.menu),

						<div className="information">

							{ !(details.additionalAttributes && details.additionalAttributes.isSecurityCheckpoint) ? <div className="informationTitle bg-secondary text-secondary">{t("common:Information")}</div> : null }

							{ // Website URL
								details.url
									? <div className="iconInfo border-primary">
										<Icon name="poi-icon-website" />
										<div className="info">
											{ !getConfig().kioskMode
													? (<a className="text-subdued" onClick={ submitPoiWebsiteTappedEvent.bind(null, details.poiId) } href={details.url} target="_blank" rel="noopener noreferrer">
														{
															details.urlDisplay ? details.urlDisplay : t("common:Website")
														}
													</a>)
													: makeDisplayReady(details.url)
											}
										</div>
									</div>
								: null
							}
							{  // Operating Hours
								cond(
									details.rawHours,
									() => <div className="iconInfo border-primary">
										<div className="info" onClick={ operatingHoursClicked }>
											<div className="operating-hours-header">
												<Icon name="poi-icon-openinghours" />
												{ <span>{ details.operatingHours[new Date().getDay()][0].hours}</span> }
												<div/>
											</div>
											<div className="operating-hours-list-view">
												<Icon name="poi-icon-openinghours" />
												<ul>
													{ details.operatingHours.map((hours, index)=> <li key={ hours[0].day } className={index === new Date().getDay() ? "-current" : ""}><span key={ hours[0].day }>{ hours[0].day}</span><span key={ hours[0].hours}>{ hours[0].hours}</span></li>) }
												</ul>
											</div>
										</div>
									</div>)
							}
							{ // Phone
								cond(
									details.phone,
									() => <div className="iconInfo border-primary">
										<Icon name="poi-icon-phone" />
										<div className="info">
											{ getConfig().kioskMode
												? details.phone // in kiosk mode, phone is displayed but not clickable
												: <a onClick= {submitPoiPhoneTappedEvent.bind(null, details.poiId)} href={ `tel:${details.phone.replace(/\s+/g, "")}` }>{ details.phone }</a>
											}
										</div>
									</div>)
							}
						</div>) /* End of "information" div */
				}
			</div>
		)}

export default PoiDetails
